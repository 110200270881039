import styled from 'styled-components';

export const CXIChallengeStartupFinancial = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    @media (min-width: 992px) and (max-width: 1199px) {
        gap: 3.2rem;
        .cxi-layout:not(.is-open) & {
            grid-template:
                'a b' auto
                'c b' auto / 1fr 42rem;
        }
    }
    @media (min-width: 1200px) {
        gap: 3.2rem;
        grid-template:
            'a b' auto
            'c b' auto / 1fr 42rem;
    }

    .finances .area-body {
        @media (min-width: 992px) {
            .cxi-layout:not(.is-open) & {
                flex-direction: row;
                gap: 3.2rem;
            }
        }
        @media (min-width: 1200px) {
            flex-direction: row;
            gap: 3.2rem;
        }
    }
    .invested .area-body {
        color: ${({ theme }) => theme.colors.neutralDark};
        font-size: 1.6rem;
        font-weight: 600;
        line-height: 1.2;
        gap: 0.8rem;
    }
`;

export const CXIChallengeStartupFinancialFinances = styled.dl`
    break-inside: avoid-column;

    dt {
        color: ${({ theme }) => theme.colors.energyBlue};
        font-size: 1.4rem;
        font-weight: 700;
        margin: 0;
    }
    dd {
        color: ${({ theme }) => theme.colors.neutralDark};
        font-size: 1.6rem;
        font-weight: 600;
        margin: 0.2rem 0 0;

        & + dt {
            margin-top: 1.4rem;
        }
    }
`;
