import React from 'react';
import Select from 'react-select';

const fakeEvent = {
    persist: () => null,
    preventDefault: () => null,
    target: {}
};

export const SelectMultiple = ({ options, input, children }) => {
    const handleSelectChange = e => {
        const ev = fakeEvent;
        ev.target.name = input.name;
        ev.target.value = e;
        return input.onChange(ev);
    };

    return (
        <div style={{ zIndex: 999999 }}>
            {children}
            <Select
                name={input.name}
                isMulti
                required={input?.required || false}
                options={options.map(o => ({ label: o.name, value: o.value }))}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        borderRadius: 0,
                        borderColor: '#ddd'
                    }),
                    menu: (baseStyles, state) => ({
                        ...baseStyles,
                        zIndex: 10
                    })
                }}
                value={input.value}
                onChange={handleSelectChange}
            />
        </div>
    );
};
export const multipleSelect = SelectMultiple;
export default SelectMultiple;
