import { IonApp, setupIonicReact } from '@ionic/react';
import React from 'react';

// Core CSS required for Ionic components to work properly
import '@ionic/react/css/core.css';

// Import font-face for use
import '@fontsource/montserrat/latin.css';

// Import the SASS styles from the assets
// It is where create import the IONIC theme variables
import 'assets/sass/styles.scss';

// Get the contexts to build the app
import * as Contexts from 'contexts';
import { Routes } from 'routes';

import { ThemeProvider } from 'styled-components';
import { theme } from 'styles/themes';
import { AppAsync } from './AppAsync';
import { AppBuild } from './AppBuild';
import { AppLogin } from './AppLogin';
import { AppNotifications } from './AppNotifications';

import * as Components from './components';

setupIonicReact({
    mode: 'ios'
});

const AppBuiltSuccess = props => (
    <Contexts.CGP>
        {/* NotificationsContext */}
        <Contexts.CNP>
            {/* Add all the app routes */}
            <Routes />
            {/* Initialize notifications support */}
            <AppNotifications />
        </Contexts.CNP>
        {/* Components to handle errors/alerts */}
        <Components.AppAlert />
        <Components.AppToast />
        {/* Async functions to run on app initialization  */}
        <AppAsync />
    </Contexts.CGP>
);

export const App = () => (
    <AppBuild then={IonApp}>
        <ThemeProvider theme={theme}>
            {/* Run the initial build functions */}
            <Contexts.CAP>
                {/* Handle Autologin before app loading */}
                <AppLogin then={AppBuiltSuccess} />
            </Contexts.CAP>
            {/* Hidden message! if you found this, smile! */}
        </ThemeProvider>
    </AppBuild>
);
export default App;
