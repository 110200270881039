import styled from 'styled-components';

export const AssociatedDomain = styled.div`
    padding: 10px;
    border: 1px solid #ddd;
    margin-top: 20px;

    & > div {
        display: flex;
        flex-direction: row;
    }

    span {
        font-size: 12px;
        font-style: italic;
    }

    p {
        margin-left: 15px;
        margin-top: 15px;
        font-weight: bolder;
    }
`;

export const LogoUploadContainer = styled.div`
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;

    span {
        margin-left: 20px;
    }
`;
