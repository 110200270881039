import React from 'react';
import axios from 'axios';
import semver from 'semver';

import { Device } from '@capacitor/device';
import { SplashScreen } from '@capacitor/splash-screen';

import { CONFIG } from 'config';
import { useTranslation } from 'react-i18next';
import * as Templates from './templates';

const initial = {
    device: '',
    loaded: false,
    showed: false,
    vrsion: false
};
export function AppBuild(props) {
    // Manage the state for app loading control
    const [state, setState] = React.useState(initial);
    const { i18n } = useTranslation();

    // add current lang attribute on html
    // eslint-disable-next-line no-return-assign
    i18n.on('languageChanged', lng => (document.documentElement.lang = lng));

    // Check the app version on the api
    async function checkAppVersion() {
        // Get the device information
        const device = await Device.getInfo();
        return axios
            .get(`${CONFIG.apiUrl}/versions`)
            .then(res => res.data)
            .then(res => {
                // console.log('AppBuild', 'checkAppVersion', res);
                const data = { device, vrsion: res['100os.app'] };
                setState({ ...data, loaded: true });
            })
            .catch(err => {
                // console.log('AppBuild', 'checkAppVersion', err);
                setState({ device, loaded: true, vrsion: null });
            });
        // Hope that everything runs fine! Fingers-crossed!
    }

    // Show the loading screen until version returns
    if (!state.loaded) {
        checkAppVersion();
        return null;
    }

    // Once everything loads check for errors
    if (state.vrsion === null) {
        // console.error('AppBuild', 'Error getting the vesion from the api');
        return <Templates.AppTemplateError />;
    }

    // Remove SplashScreen for capacitor apps
    SplashScreen.hide();

    // Get the version informations
    // And build the component to be shown
    const { latest, minimum } = state.vrsion;

    const appPlatform = state.device.platform || CONFIG.appPlatform;
    const appVersion = state.device.appVersion || CONFIG.appVersion;

    // Check if the version is compatible with the minimum versions
    console.log('AppBuild', 'Check the minimum version...', `${appVersion} > ${minimum}`);
    if (minimum && semver.gt(minimum, appVersion)) {
        // console.error('AppBuild', 'The current version is not supported');
        return <Templates.AppTemplateMustUpdate platform={appPlatform} />;
    }

    // Check if the version is old and needs an update
    // Only do this for the native version of the app
    console.log('App', 'Check the latest version...', `${appVersion} > ${latest}`);
    if (appPlatform !== 'web' && latest && semver.gt(latest, appVersion)) {
        // console.error('AppBuild', 'The current version needs an update');
        return <Templates.AppTemplateNeedUpdate platform={appPlatform} version={appVersion} />;
    }

    console.log('AppBuild', 'The build ran smoothlly');
    // Get the component that will return after load
    const Component = props.then;
    return <Component>{props.children}</Component>;
}
export default AppBuild;
