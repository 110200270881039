import styled from 'styled-components';

export const LanguageText = styled.span`
    margin-top: 1rem;
    color: #0094f9;
    cursor: pointer;
`;

export const ProgramLabel = styled.label`
    align-items: center;
    color: inherit;
    display: flex;
    font-size: 1.4rem;
    font-weight: 400;
    margin: 1rem 0 0.5rem;
    width: 100%;
`;
