import React from 'react';
import { useEffectOnce } from 'react-use';

import * as Alert from 'components/alert';
import * as List from 'components/list';
import * as Modal from 'components/modal';
import * as Page from 'components/page';
import * as Tabs from 'components/tabs';
import { RHP } from 'providers';

import { CorpEcosystemRelationship, CorpEcosystemRelationshipCard } from '../components';

// Changed from step 3 to step4

export function EcosystemStep4({ useRankingEcosystem, t }) {
    const { vars, updateSteps } = useRankingEcosystem;
    const { steps } = vars.application;

    const [modalData, setModalData] = React.useState(false);

    const relationships = vars.corpEcosystemRelationships.map(i => {
        const item = { ...i };
        item.onClick = () => setModalData(i);
        return item;
    });

    // Update the step has completed if relationships exist
    useEffectOnce(() => {
        if (
            vars.corpEcosystemRelationships &&
            vars.corpEcosystemRelationships.length > 0 &&
            steps.step1 &&
            steps.step2 &&
            steps.step3 &&
            !steps.step4
        ) {
            updateSteps({ step4: true });
        }
    });

    // Check for previous step conclusion
    if (!steps || !steps.step3) {
        RHP.replace('/ranking/latam/ecosystem');
        return null;
    }

    const propsAlert = {
        title: t('EcosystemStep3.noRelationship'),
        descr: t('EcosystemStep3.noRelationshipDescr'),
        icons: 'far fa-exclamation-circle',
        buttn: {
            href: '/ranking/latam/ecosystem/step4/add',
            text: t('EcosystemStep3.addRelationship')
        },
        type: 'warning'
    };

    const pending = relationships
        .filter(i => i.status === 'pending')
        .sort((a, b) => {
            return new Date(b.regDate) - new Date(a.regDate);
        });

    const rejected = relationships
        .filter(i => i.status === 'rejected')
        .sort((a, b) => {
            return new Date(b.regDate) - new Date(a.regDate);
        });

    const confirmed = relationships
        .filter(i => i.status === 'confirmed')
        .sort((a, b) => {
            return new Date(b.regDate) - new Date(a.regDate);
        });

    return (
        <Page.Page
            name="EcosystemStep4"
            back="/ranking/latam/ecosystem"
            customBackText={t('glossary:backToRanking')}
        >
            <Page.Title>{t('EcosystemStep3.title')}</Page.Title>
            <Page.Descr>{t('EcosystemStep3.descr')}</Page.Descr>

            {relationships
                .filter(i => i.ecosystem.status === 'pending' && i.status === 'pending')
                .slice(0, 1)
                .map(i => {
                    const item = { ...i };
                    item.onClick = undefined;
                    return (
                        <CorpEcosystemRelationship
                            key={`pending-${i._id}`}
                            data={item}
                            useRankingEcosystem={useRankingEcosystem}
                        />
                    );
                })}

            {!vars.corpEcosystemRelationships.length && <Alert.Alert {...propsAlert} />}

            <Page.Buttn to="/ranking/latam/ecosystem/step4/add" type="linkd btn-block">
                <span className="icon far fa-plus-circle" aria-hidden="true" />
                <span className="text">{t('EcosystemStep3.addRelationship')}</span>
                <span className="icon far fa-long-arrow-right" aria-hidden="true" />
            </Page.Buttn>

            <Tabs.TabsContainer>
                <Tabs.TabsButtons>
                    <Tabs.Tab name="ES3C">
                        {t('glossary:confirmed')}{' '}
                        {confirmed.length > 0 && <span>({confirmed.length})</span>}
                    </Tabs.Tab>
                    <Tabs.Tab name="ES3P">
                        {t('glossary:pending')}{' '}
                        {pending.length > 0 && <span>({pending.length})</span>}
                    </Tabs.Tab>
                    <Tabs.Tab name="ES3R">
                        {t('glossary:rejected')}{' '}
                        {rejected.length > 0 && <span>({rejected.length})</span>}
                    </Tabs.Tab>
                </Tabs.TabsButtons>

                <List.List name="ES3C">
                    <List.Vertical
                        items={confirmed}
                        itemKey="_id"
                        itemComponent={CorpEcosystemRelationshipCard}
                    />
                </List.List>
                <List.List name="ES3P">
                    <List.Vertical
                        items={pending}
                        itemKey="_id"
                        itemComponent={CorpEcosystemRelationshipCard}
                    />
                </List.List>
                <List.List name="ES3R">
                    <List.Vertical
                        items={rejected}
                        itemKey="_id"
                        itemComponent={CorpEcosystemRelationshipCard}
                    />
                </List.List>
            </Tabs.TabsContainer>

            <Page.Buttn to="/ranking/latam/ecosystem">
                <span className="text">{t('glossary:continue')}</span>
                <span className="icon far fa-long-arrow-right" aria-hidden="true" />
            </Page.Buttn>

            <Modal.Modal
                close={() => setModalData(false)}
                title={t('EcosystemStep3.relationship')}
                isOpen={!!modalData}
            >
                {modalData && (
                    <CorpEcosystemRelationship
                        closeModal={() => setModalData(false)}
                        data={modalData}
                        style={{ marginTop: '1rem' }}
                        useRankingEcosystem={useRankingEcosystem}
                    />
                )}
            </Modal.Modal>
        </Page.Page>
    );
}
export default EcosystemStep4;
