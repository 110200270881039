import React from 'react';

import { useForm } from 'components/form';

import { RHP } from 'providers';
import { Step4Form } from './Step4Form';

export function Step4(props) {
    const { useCXI } = props;
    const { addVars, vars } = useCXI;

    const sectors = vars?.challengerForm?.eventStartupFilter?.sector;

    const parsedValues = {
        challenges: sectors?.challenges?.map(e => `challenge-${e}`),
        industries: sectors?.industries?.map(e => `industry-${e}`),
        trends: sectors?.trends?.map(e => `trend-${e}`),
        communities: sectors?.communities?.map(e => `community-${e}`)
    };

    const form = useForm({ ...parsedValues });

    const onSubmit = values => {
        const parsedValues = {
            challenges: values?.challenges?.map(e => e.split('-')[1]),
            industries: values?.industries?.map(e => e.split('-')[1]),
            trends: values?.trends?.map(e => e.split('-')[1]),
            communities: values?.communities?.map(e => e.split('-')[1])
        };
        const payload = {
            ...vars.challengerForm,
            eventStartupFilter: {
                ...vars.challengerForm.eventStartupFilter,
                sector: { ...parsedValues }
            }
        };

        addVars({ challengerForm: payload });
        RHP.push('/100-10-1/form/5');
    };

    const propsForm = { ...props, form, onSubmit };

    return <Step4Form {...propsForm} />;
}
