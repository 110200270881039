import React from 'react';
import { useTranslation } from 'react-i18next';

import { Device } from '@capacitor/device';

import { ContextGlobal as CG } from 'contexts';
import { LocalStorageProvider as LSP } from 'providers';

export function useLangDetection(props) {
    const { i18n } = useTranslation();
    const { dispatch } = React.useContext(CG);

    // Check the app version on the api
    async function checkDeviceLanguage() {
        // Get the device information
        const code = await Device.getLanguageCode();
        const lang = code.value.split('-')[0];

        const language = i18n.store.data[lang] !== undefined ? lang : 'en';

        i18n.changeLanguage(language);

        dispatch({ type: 'LANGUAGE', language });
        return language;
    }

    function detect() {
        return LSP.get('100os-language') || checkDeviceLanguage();
    }

    return { detect };
}
export default useLangDetection;
