import { UserAvatar } from 'core/components/user';
import { useUser } from 'hooks';
import { useCXI } from 'modules/cxi/useCXI';

import bannerDesktop from 'assets/images/banner-3-meses.png';
import { Button } from 'modules/cxi/components/button';
import { RHP } from 'providers';
import React from 'react';
import * as Styled from './StyledAbout';

const challengeUrl = 'https://100os.net/cxi_challenge';

export const About = () => {
    const user = useUser();
    const { t } = useCXI();
    const { vars } = useCXI();
    const { company, cxi } = vars;

    const { hasChallenges } = company ?? {};

    const openChallengerPage = () => {
        window.open(challengeUrl, '_blank');
    };

    return (
        <Styled.About>
            <Styled.Wrapper className="user">
                <Styled.AboutHead>
                    <h2>{t('aboutTitle')}</h2>
                </Styled.AboutHead>

                <Styled.AboutBody className="user">
                    <div className="user">
                        <UserAvatar />
                        <div className="infos">
                            <span className="name">{user.fullName}</span>
                            <span className="comp">{user.company?.name}</span>
                            <span className="post">{user.position}</span>
                        </div>
                    </div>
                    <Button
                        className="about-button"
                        variant="blue"
                        size="medium"
                        onClick={() => RHP.push('/100-10-1/profile')}
                    >
                        <span className="text">{t('edit')}</span>
                    </Button>
                </Styled.AboutBody>
            </Styled.Wrapper>

            {!!(hasChallenges && cxi.isCxiAdmin) && (
                <Styled.Wrapper className="challenges">
                    <Styled.AboutHead>
                        <h2>{t('challenge.challenge')}</h2>
                    </Styled.AboutHead>
                    <Styled.AboutBody className="challenges">
                        <div className="challenges">
                            <span className="name">{t('challenge.aboutBody')}</span>
                        </div>
                        <Button
                            className="about-button"
                            variant="blue"
                            size="medium"
                            onClick={() => openChallengerPage()}
                        >
                            <span className="icon far fa-fw fa-rocket" aria-hidden="true" />
                            <span className="text"> {t('menu.challenges')} </span>
                        </Button>
                    </Styled.AboutBody>
                </Styled.Wrapper>
            )}

            {!(hasChallenges && cxi.isCxiAdmin) && (
                <Styled.Wrapper className="banner">
                    <Styled.Banner>
                        <a
                            href="https://100os.net/energia_colaboracao_100-10-1"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img src={bannerDesktop} alt="" />
                        </a>
                    </Styled.Banner>
                </Styled.Wrapper>
            )}
        </Styled.About>
    );
};
