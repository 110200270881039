import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const Contract = styled.div.attrs({
    className: 'contract'
})`
    background: #f2f2f2;
    margin-top: 2rem;
    padding: 1.5rem;

    .contract-card {
        margin-top: 0;
    }

    .component-table {
        margin-top: 1rem;
    }

    .badge {
        margin: 1rem 1rem 0 0;
    }

    & + .btn-linkd {
        margin-top: 3rem;
    }

    .btn.btn-link {
        color: #999;
        min-height: auto;
        justify-content: center;
        padding: 0;
        text-align: center;
        text-decoration: underline;
        text-transform: none;
    }
`;
export const ContractButtons = styled.div.attrs({
    className: 'contract-buttons'
})`
    display: flex;
    flex-wrap: wrap;
    margin: -1rem 0 0 -1rem;
    padding-top: 1rem;
    justify-content: center;

    .btn {
        flex: calc(50% - 1rem);
        margin: 1rem 0 0 1rem;
        min-height: auto;
        justify-content: center;
    }
`;

export const ContractCard = styled.button.attrs({
    className: 'contract-card'
})`
    align-items: center;
    background: #fff;
    border: 0;
    color: inherit;
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 1rem;
    -webkit-appearance: none;
`;

// export const CardImage = styled.div.attrs({
//     className: 'contract-card-image',
// })`
//     background: #f2f2f2 no-repeat 50% 50%;
//     background-image: url(${props => props.img});
//     background-size: contain;
//     background-origin: content-box;
//     border: 1px solid #ddd;
//     border-radius: 50%;
//     flex: 0 0 6.5rem;
//     height: 6.5rem;
//     padding: 0.1rem;
// `;

export const CardContact = styled.div.attrs({
    className: 'relationship-card-contact'
})`
    margin-top: 0.4rem;
    font-size: 1rem;
    .contact {
        ${truncate(1, 1.4)}
    }
    span {
        display: inline-block;
    }
`;

export const CardInfos = styled.div.attrs({
    className: 'relationship-card-infos'
})`
    flex: 1 1 calc(100% - 6.5rem);
    font-size: 1.4rem;
    margin-left: 1.5rem;
    text-align: left;

    .name {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1.4;
        ${truncate(1)}
    }

    .bold {
        display: block;
        font-weight: 600;
        margin-top: 0;
        ${truncate(1)}
    }

    .text {
        display: block;
        margin-top: 0.1rem;
        ${truncate(1)}
    }
    .badge {
        margin-right: 4px;
    }
`;

export const ResendInfo = styled.span.attrs({
    className: 'p-0 m-0 mt-2 mr-2'
})`
    font-size: 1.2rem;
    min-height: auto;
`;

export const ContractIdContainer = styled.div`
    display: flex;
    padding: 10px 0;
    align-items: center;
    justify-content: space-between;

    border-bottom: 1px solid #ddd;
    span {
        font-weight: 500;
        align-items: center;
    }
    p {
        color: #999;
        margin: 0;
    }
`;

export const CopyButton = styled.button`
    justify-content: center;
    flex: 1;
    border: none;
    min-height: auto;
    align-items: center;
`;

export const MaxContractPointsBadge = styled.div`
    display: flex;
    width: fit-content;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: #45aaf2;
    color: #fff;
    padding: 6.5px 6px;
    margin-top: 5px;
    font-weight: bold;
    font-size: 1.2rem;

    img {
        width: 14px;
        height: 14px;
    }
    p {
        display: inline-block;
        white-space: nowrap;
        margin: 0;
        width: fit-content;
    }
`;
