import React from 'react';
import { Route, Switch } from 'react-router-dom';

// eslint-disable-next-line import/no-cycle
import * as Matches from './index';

export const routes = [
    {
        exact: true,
        path: '/matches',
        component: Matches.MatchesIndex,
    },

    // Matches submodules
    {
        exact: false,
        name: '100-10-1',
        path: '/matches/100-10-1',
        component: Matches.MatchesCXI,
    },
];

export const MatchesRoutes = props => (
    <Switch>
        {routes.map(r => {
            const Component = r.component || React.createComponent(r.toRender);
            const routeProp = { ...r, props, component: undefined };
            return <Route key={r.path} {...routeProp} render={() => <Component {...props} />} />;
        })}
    </Switch>
);
export default MatchesRoutes;
