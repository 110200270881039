import styled from 'styled-components';

export const CXIFormChallenges = styled.div`
    min-height: 100%;
    position: relative;
    overflow-y: hidden;
    min-height: 100%;
    padding: 1.6rem;
    padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
`;

export const Title = styled.h1`
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.2;
    margin: 0;
    display: flex;
    justify-content: space-between;
    overflow-y: hidden;
`;
export const Title1 = styled.h1`
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.2;
    margin: 0;
    display: flex;
    justify-content: space-between;
    color: black;
`;

export const Subtitle = styled.h1`
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.2;
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    gap: 1rem;
    align-items: baseline;

    p {
        color: #0094f9;
        font-weight: 600;
        font-size: 1.6rem;
    }

    @media (max-width: 768px) {
        font-size: 1.6rem;
    }
`;
