import { IonActionSheet } from '@ionic/react';
import { FileCopy } from '@mui/icons-material';

import { Button, Descr, LWTXT } from 'components/page';
import * as Table from 'components/table';
import { ContextGlobal as CG } from 'contexts';
import { iframeLink } from 'core/iframe';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';
import { RelationshipCard } from './RelationshipCard';
import * as Styled from './StyledRelationship';

export function Relationship(props) {
    const { emitAlert } = React.useContext(CG);
    const { t } = useTranslation('ranking');

    const [copy, setCopy] = useCopyToClipboard();
    const [showAS, setShowAS] = React.useState(false);

    const {
        deleteCorpEcosystemRelationship,
        postCorpEcosystemRelationshipValidation,
        postCorpEcosystemRelationshipValidationEmail
    } = props.useRankingCorporate;

    const data = props.data || props.relationship || undefined;

    const relationship = {
        ecosystemName: data?.ecosystem?.data?.name,
        ecosystemDeclaredName: data?.ecosystem?.name,
        programName: data?.program?.name || data?.programName
    };

    if (data.ecosystem && data.ecosystem.contact && data.ecosystem.contact.name) {
        relationship.fullName = data.ecosystem.contact.name;
    }

    if (data.ecosystem && data.ecosystem.contact && data.ecosystem.contact.email) {
        relationship.email = data.ecosystem.contact.email;
    }

    if (data?.year) {
        relationship.year = `${data.year}`;
    }

    // Properties for the table
    const propsTable = {
        href: `/ranking/latam/corporate/step3/edit/${data?._id}`,
        subtitle: {
            link: t('glossary:edit'),
            icon: 'far fa-pencil'
        },
        object: {
            data: { ...relationship },
            name: 'Relationship',
            dataConv: {}
        }
    };

    if (data?.ecosystem.status === 'rejected') {
        propsTable.href = undefined;
        propsTable.subtitle = {
            link: '',
            icon: ''
        };
    }

    const closeModal = () => {
        if (props.closeModal) props.closeModal();
        return true;
    };

    function handleConfirm() {
        const payload = {
            _id: data._id,
            status: 'confirmed',
            lastUpdated: data.lastUpdated,
            entity: 'company'
        };
        return postCorpEcosystemRelationshipValidation(payload).then(closeModal);
    }

    function handleDelete() {
        const payload = { _id: data._id, entity: 'company' };

        return deleteCorpEcosystemRelationship(payload).then(res => {
            closeModal();
            emitAlert(t('relationshipDeleted'));
            return res;
        });
    }

    function handleReject() {
        const payload = {
            _id: data?._id,
            status: 'rejected',
            lastUpdated: data?.lastUpdated,
            entity: 'company'
        };
        return postCorpEcosystemRelationshipValidation(payload).then(closeModal);
    }

    function handleResend() {
        const payload = { _id: data?._id, entity: 'company' };
        return postCorpEcosystemRelationshipValidationEmail(payload).then(res => {
            emitAlert(t('relationshipResent'));
            return res;
        });
    }

    function handleReaudit() {
        emitAlert(t('relationshipReauditSent'));
    }

    const buttons = [];

    if (data?.company && data?.company.status === 'pending' && data?.status === 'pending') {
        const s = { type: 'success', onClick: handleConfirm };
        const d = { type: 'danger', onClick: handleReject };
        buttons.push(() => (
            <Styled.RelationshipButtons>
                <Button {...s}>{t('glossary:confirm')}</Button>
                <Button {...d}>{t('glossary:reject')}</Button>
            </Styled.RelationshipButtons>
        ));
    }

    if (data?.ecosystem && data?.status === 'pending' && data?.ecosystem.status === 'pending') {
        buttons.push(() => (
            <Button type="dark" onClick={handleResend}>
                <span className="text">{t('glossary:resend')}</span>
                <span className="icon far fa-long-arrow-right" aria-hidden="true" />
            </Button>
        ));
    }

    if (data?.audited === 'rejected' && data.displayAudit) {
        buttons.push(() => (
            <Button type="dark" onClick={handleReaudit}>
                <span className="text">{t('glossary:reaudit')}</span>
                <span className="icon far fa-long-arrow-right" aria-hidden="true" />
            </Button>
        ));
    }

    if (data?.status === 'pending' && data?.ecosystem.status === 'pending') {
        const l = { type: 'link', onClick: () => setShowAS(true) };
        buttons.push(() => <Button {...l}>{t('relationshipDelete')}</Button>);
    }

    return (
        <Styled.Relationship className={props.className} style={props.style}>
            <RelationshipCard data={data} />

            <Table.Table {...propsTable} />

            <Styled.ContractIdContainer>
                <span>
                    {data?._id}
                    <Styled.CopyButton
                        type="dark"
                        variant="neutral"
                        onClick={() => setCopy(data?._id)}
                    >
                        <FileCopy />
                        <span className="text" />
                    </Styled.CopyButton>
                </span>
                <p>ID</p>
            </Styled.ContractIdContainer>

            {data.description && <Descr>{data.description}</Descr>}

            {data.linkUrl && (
                <LWTXT to={iframeLink(data.linkUrl)}>
                    <span className="link">{data.linkUrl}</span>
                </LWTXT>
            )}

            {buttons.map((Btn, k) => (
                // eslint-disable-next-line react/no-array-index-key
                <Btn key={`button-${k}`} />
            ))}

            <IonActionSheet
                isOpen={showAS}
                onDidDismiss={() => setShowAS(false)}
                buttons={[
                    {
                        text: t('relationshipDelete'),
                        role: 'destructive',
                        handler: handleDelete
                    },
                    {
                        text: t('glossary:cancel'),
                        role: 'cancel'
                    }
                ]}
            />
        </Styled.Relationship>
    );
}
export default Relationship;
