import React from 'react';

import { useConversionTable } from 'hooks';
import { useCXI } from 'modules/cxi/useCXI';

import { Option } from '../components/option/Option';

import * as Form from '../components/form';

export const Languages = () => {
    const { t, vars, addVars } = useCXI();
    const conv = useConversionTable(['language']);
    const data = vars.profile.profile.languages;

    const handleChange = value => {
        let newData = [...data];
        if (data.includes(value)) {
            newData = data.filter(v => v !== value);
        } else {
            newData.push(value);
        }

        const profile = {
            ...vars.profile,
            profile: {
                ...vars.profile.profile,
                languages: [...newData],
            },
        };
        addVars({ profile });
    };

    return (
        <Form.Form name="Languages">
            <Form.Title>{t('setup.Titles.languages')}</Form.Title>
            <Form.Descr>{t('setup.Descrs.languages')}</Form.Descr>

            <Form.OptionsContainer>
                {Object.keys(conv.language).map(value => (
                    <Option
                        label={conv.language[String(value)]}
                        value={value}
                        key={value}
                        checked={!!data.includes(value)}
                        onChange={() => handleChange(value)}
                    />
                ))}
            </Form.OptionsContainer>
        </Form.Form>
    );
};
