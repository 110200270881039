import React from 'react';
import * as Styled from './StyledFormInputCheckbox';

const fakeEvent = {
    persist: () => null,
    preventDefault: () => null,
    target: {},
};

export function FormInputCheckbox(props) {
    const { label, input, controls } = props;

    // Create a new handler for the checked event
    function handleChange(ev) {
        const { name, checked } = ev.target;
        const event = { ...fakeEvent, target: { name, value: !!checked } };
        return input.onChange(event);
    }

    // Recreate the properties and add to the input
    const propsCheck = { ...input, checked: !!input.value, value: true, onChange: handleChange };

    return (
        <Styled.FormInputCheckbox htmlFor={input.id}>
            <input className="form-check" {...propsCheck} />
            <label className="form-check-label" htmlFor={input.id}>
                <span className="text">{label || controls.label.text}</span>
            </label>
        </Styled.FormInputCheckbox>
    );
}
export const checkbox = FormInputCheckbox;
export default FormInputCheckbox;
