import * as Page from 'components/page';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCXI } from '../useCXI';
import { CXIChallengesFormRoutes } from './CXIChallengesFormRoutes';
import * as Styled from './StyledCXIFormChallenges';

export function CXIFormChallenges() {
    const hookd = useCXI();
    const { t } = useTranslation('cxi');

    return (
        <Page.Page
            style={{ overflow: 'hidden' }}
            back="/100-10-1/challenges"
            customBackText="Voltar para Desafios"
        >
            <Styled.Title>{t('form.title')}</Styled.Title>
            <Styled.Subtitle>{t('form.descr')}</Styled.Subtitle>

            <CXIChallengesFormRoutes t={t} useCXI={hookd} />
        </Page.Page>
    );
}
