import styled from 'styled-components';

export const ComponentForm = styled.form.attrs(props => ({
    className: `component-form form-${props.name}`,
}))``;

export const ComponentFormField = styled.div.attrs(props => {
    const options = `cff-${props.type} cfs-${props.size} cfh-${props.hiddenIf ? 'hidden' : ''}`;
    return { className: `component-form-field ${options}` };
})`
    flex: 0 0 ${props => (props.size ? `calc(100%/12 * ${props.size})` : '100%')};
    margin: 2rem 0 0;
    position: relative;

    &.cff-EditAvatar {
        margin-top: 0;
    }

    &.cfh-hidden {
        display: none;
    }
`;
