import React from 'react';

import * as Styled from './StyledOption';

export const Option = ({ label, value, checked, onChange }) => {
    const handleClick = () => onChange();
    return (
        <Styled.Option className={checked ? 'checked' : ''} onClick={handleClick}>
            <span className="icon far fa-circle" aria-label={checked ? 'Selecionado' : ''} />
            <span className="text">{label}</span>
        </Styled.Option>
    );
};
