import React from 'react';

import { useForm } from 'components/form';
import { useConversionOptions } from 'hooks';
import { RHP } from 'providers';
import * as Page from 'components/page';

import { useParams } from 'react-router';
import { StartupStep6EditForm } from './edit-form';

export function StartupStep6Edit(props) {
    const { id } = useParams();
    const conv = useConversionOptions(['investmentType']);
    const { vars, putStartupInvestment } = props.useRankingStartup;
    const { ranking } = vars;

    const investment = vars.investments.filter(i => i._id === id)[0];
    if (!investment) RHP.goBack();

    // Initialize a new form handler
    const form = useForm({
        ...investment,
        companyName:
            investment.investor && investment.investor.companyName
                ? investment.investor.companyName
                : '',
        isConfirmed: investment.status === 'confirmed'
    });

    // Check for previous step conclusion
    if (!vars.application.steps || vars.application.steps.step5 !== true) {
        RHP.replace('/ranking/latam/startup/');
        return null;
    }

    // Handle form submitions
    const onSubmit = values => {
        const payload = {
            _id: values._id,
            date: values.date,
            type: values.type,
            round: values.round,
            value: values.value,
            ranking,
            lastUpdated: values.lastUpdated
        };
        // CompanyName is present?
        if (values.companyName) {
            payload.investor = {};
            payload.investor.companyName = values.companyName;
        }
        if (values.value && !values.value.amount) {
            payload.value = undefined;
        }
        return putStartupInvestment(payload)
            .then(() => {
                RHP.push('/ranking/latam/startup/step6');
            })
            .catch(() => form.submited());
    };

    const propsForm = { ...props, conv, form, onSubmit };
    return (
        <Page.Page name="StartupStep6Edit">
            <Page.Title>{props.t('StartupStep6Edit.title')}</Page.Title>
            <Page.Descr>{props.t('StartupStep6Edit.descr')}</Page.Descr>

            <StartupStep6EditForm {...propsForm} investment={investment} />
        </Page.Page>
    );
}
