import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'modules/cxi/components/button';
import { RouterHistoryProvider as RHP } from 'providers';
import { PocsCard } from '../pocs/PocsCard';
import * as Styled from './styledButton';

export const Pocs = props => {
    const { t } = useTranslation('glossary');
    const { useRankingCorporate } = props;
    const { vars } = useRankingCorporate;
    const { companyPocs } = vars;

    const showPocs = companyPocs && companyPocs.pocs && companyPocs.pocs.length > 0;

    return (
        <Styled.About>
            {showPocs && (
                <Styled.Wrapper className="user">
                    <br />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Styled.UserProfile>{t('pocs')}</Styled.UserProfile>
                        <div style={{ flexGrow: 1 }} />
                        <div>
                            <Button
                                className="about-button"
                                variant="blue"
                                size="small"
                                onClick={() => RHP.push('/ranking/latam/corporate/editPocs')}
                            >
                                <span className="text">{t('glossary:edit')}</span>
                            </Button>
                        </div>
                    </div>
                    <Styled.AboutBody className="user">
                        <div className="pocs-container">
                            {companyPocs.pocs
                                .filter(e => e.pocs.includes('operational'))
                                .map((poc, index) => (
                                    <div key={poc.email} className="pocs-item">
                                        <PocsCard
                                            data={poc}
                                            operationalOnly
                                            useRankingCorporate={useRankingCorporate}
                                        />
                                    </div>
                                ))}
                        </div>
                    </Styled.AboutBody>
                </Styled.Wrapper>
            )}
        </Styled.About>
    );
};

export default Pocs;
