import * as Alert from 'components/alert';
import * as List from 'components/list';
import * as Modal from 'components/modal';
import * as Page from 'components/page';
import * as Tabs from 'components/tabs';
import { CA } from 'contexts';
import { Button } from 'modules/cxi/components/button';
import { RHP } from 'providers';
import React, { useContext, useEffect, useState } from 'react';
import { Contract, ContractCard } from '../components';
import { CompanyPosition } from '../components/contract/companyContractsPosition/CompanyPosition';
import * as Styled from './styles';

export function CorporateStep4(props) {
    const { state } = useContext(CA);
    const { useRankingCorporate, t } = props;

    const { vars, updateSteps, getCorpRankingPosition } = useRankingCorporate;

    const [loading, setLoading] = useState(true);
    const [modalData, setModalData] = useState(false);
    const [companyEstimatedPosition, setCompanyEstimatedPosition] = useState(null);

    const { ranking, application, contracts } = vars;

    const { steps } = application;

    const pocs = state?.tokn?.pocs;

    const addContractHrefPage = '/ranking/latam/corporate/step4/add';

    const isPoc =
        pocs?.includes('operational') ||
        pocs?.includes('communication') ||
        pocs?.includes('senior');

    const isAdmin = state?.tokn?.admin;

    const displayCalculator = isAdmin || isPoc;

    const hasDisplayedProperties =
        companyEstimatedPosition &&
        ['audited', 'confirmed', 'declared'].some(prop => prop in companyEstimatedPosition);

    const contractsWithOnClick = contracts.map(i => {
        const item = { ...i };
        item.onClick = () => setModalData(i);
        return item;
    });

    useEffect(() => {
        async function fetchData() {
            const estimation = await getCorpRankingPosition();
            setCompanyEstimatedPosition(estimation);
            setLoading(false);
        }

        if (displayCalculator) {
            fetchData();
        }
    }, []);

    // Update the step has completed if contracts exist
    useEffect(() => {
        if (contracts && !!contracts.length && steps.step2 && steps.step3 && !steps.step4) {
            updateSteps({ step4: true });
        }
    }, []);

    useEffect(() => {
        if (modalData) {
            const filteredContracts = contracts.filter(i => i._id === modalData._id);
            setModalData(filteredContracts[0]);
        }
    }, [contracts, modalData]);

    // Check for previous step conclusion
    if (!steps || !steps.step3) {
        RHP.replace('/ranking/latam/corporate/');
        return null;
    }

    const invalidContracts = contractsWithOnClick.filter(
        i => i.status !== 'deleted' && !i?.eligibility?.[ranking.year]?.eligible
    );

    const validContracts = contractsWithOnClick.filter(
        i => i.status !== 'deleted' && i?.eligibility?.[ranking.year]?.eligible
    );

    const pending = validContracts
        .filter(i => i.status === 'pending')
        .sort((a, b) => {
            return new Date(b.regDate) - new Date(a.regDate);
        });

    const rejected = validContracts
        .filter(i => i.status === 'rejected')
        .sort((a, b) => {
            return new Date(b.regDate) - new Date(a.regDate);
        });

    const confirmed = validContracts
        .filter(i => i.status === 'confirmed')
        .sort((a, b) => {
            return new Date(b.regDate) - new Date(a.regDate);
        });

    const invalid = invalidContracts.sort((a, b) => {
        return new Date(b.regDate) - new Date(a.regDate);
    });

    const propsAlert = {
        title: t('CorporateStep4.noRelationship'),
        descr: t('CorporateStep4.noRelationshipDescr'),
        icons: 'far fa-exclamation-circle',
        buttn: {
            href: addContractHrefPage,
            text: t('CorporateStep4.addContract')
        },
        type: 'warning'
    };

    return (
        <Page.Page
            name="CorporateStep4"
            back="/ranking/latam/corporate"
            customBackText={t('glossary:backToRanking')}
        >
            <Page.Title>{t('CorporateStep4.title')}</Page.Title>
            <Page.Descr>{t('CorporateStep4.descr')}</Page.Descr>

            <Styled.ButtonWrapper>
                <Button onClick={() => RHP.push(addContractHrefPage)} type="linkd btn-block">
                    <span className="text">{t('StartupStep8.addContract')}</span>
                </Button>
            </Styled.ButtonWrapper>

            <Page.Descr>{t('CorporateStep4.descr2')}</Page.Descr>

            {validContracts
                .filter(i => i.status === 'pending' && i.companyStatus === 'pending')
                .slice(0, 1)
                .map(i => {
                    const item = { ...i };
                    item.onClick = undefined;
                    return (
                        <Contract
                            key={`pending-${i._id}`}
                            data={item}
                            useRankingCorporate={useRankingCorporate}
                        />
                    );
                })}

            {!contracts.length && <Alert.Alert {...propsAlert} />}

            {!loading &&
                displayCalculator &&
                companyEstimatedPosition &&
                hasDisplayedProperties && (
                    <CompanyPosition data={companyEstimatedPosition} {...props} />
                )}

            <Page.Buttn to={addContractHrefPage} type="linkd btn-block">
                <span className="icon far fa-plus-circle" aria-hidden="true" />
                <span className="text">{t('CorporateStep4.addContract')}</span>
                <span className="icon far fa-long-arrow-right" aria-hidden="true" />
            </Page.Buttn>

            <Tabs.TabsContainer>
                <Tabs.TabsButtons>
                    <Tabs.Tab name="TAB_CONFIRMED">
                        {t('glossary:confirmed')}{' '}
                        {confirmed.length > 0 && <span>({confirmed.length})</span>}
                    </Tabs.Tab>

                    <Tabs.Tab name="TAB_PENDING">
                        {t('glossary:pending')}{' '}
                        {pending.length > 0 && <span>({pending.length})</span>}
                    </Tabs.Tab>

                    <Tabs.Tab name="TAB_REJECTED">
                        {t('glossary:rejected')}{' '}
                        {rejected.length > 0 && <span>({rejected.length})</span>}
                    </Tabs.Tab>

                    <Tabs.Tab name="TAB_INVALID">
                        {t('glossary:invalidForRanking')}{' '}
                        {invalid.length > 0 && <span>({invalid.length})</span>}
                    </Tabs.Tab>
                </Tabs.TabsButtons>

                <List.List name="TAB_CONFIRMED">
                    <List.Vertical items={confirmed} itemKey="_id" itemComponent={ContractCard} />
                </List.List>

                <List.List name="TAB_PENDING">
                    <List.Vertical items={pending} itemKey="_id" itemComponent={ContractCard} />
                </List.List>

                <List.List name="TAB_REJECTED">
                    <List.Vertical items={rejected} itemKey="_id" itemComponent={ContractCard} />
                </List.List>

                <List.List name="TAB_INVALID">
                    <List.Vertical items={invalid} itemKey="_id" itemComponent={ContractCard} />
                </List.List>
            </Tabs.TabsContainer>

            <div style={{ display: 'flex', margin: '10px 0' }}>
                <Page.Buttn
                    style={{ width: '25%', display: 'flex' }}
                    to="/ranking/latam/corporate/step3"
                >
                    <span className="icon far fa-long-arrow-left" aria-hidden="true" />
                    <span className="text" style={{ margin: '0 0 0 auto' }}>
                        {t('glossary:back')}
                    </span>
                </Page.Buttn>

                <Page.Buttn
                    style={{ width: '80%', marginLeft: '10px' }}
                    to="/ranking/latam/corporate"
                >
                    <span className="text">{t('glossary:continue')}</span>
                    <span className="icon far fa-long-arrow-right" aria-hidden="true" />
                </Page.Buttn>
            </div>

            <Modal.Modal
                close={() => setModalData(false)}
                title={t('CorporateStep4.relationship')}
                isOpen={!!modalData}
            >
                {!!modalData && (
                    <Contract
                        closeModal={() => setModalData(false)}
                        data={modalData}
                        style={{ marginTop: '1rem' }}
                        useRankingCorporate={useRankingCorporate}
                    />
                )}
            </Modal.Modal>
        </Page.Page>
    );
}
