import React, { useEffect, useState } from 'react';

import * as Form from 'components/form';
import * as Page from 'components/page';
import CreatableSelect from 'react-select/creatable';
import { RelationshipCard } from '../components';
import * as Styled from './styles';

export function CorporateStep3EditForm({ form, onSubmit, relationship, useRankingCorporate, t }) {
    const { getProgramsByCompanyId } = useRankingCorporate;

    const [companyPrograms, setCompanyPrograms] = useState([]);

    useEffect(() => {
        if (relationship?.ecosystemId) {
            const getProgramsOptions = async () => {
                const allCompanyPrograms = await getProgramsByCompanyId(
                    relationship?.ecosystemId || relationship?.ecosystem?.data?._id
                );

                setCompanyPrograms(allCompanyPrograms);
            };

            getProgramsOptions();
        }
    }, []);

    // Create form fields object
    const fields = {
        companyName: {
            disabled: true,
            error: form.errors.companyName,
            label: t('ecossystemCompanyName'),
            name: 'companyName',
            type: 'text',
            value: form.values.companyName,
            onChange: form.handleChange
        },
        programName: {
            error: form.errors.programName,
            label: t('glossary:programName'),
            name: 'programName',
            type: 'generic',
            options: companyPrograms?.map(item => {
                return { label: item.name, value: item._id };
            }),
            value: form.values.programName,
            onChange: ev => form.setValues(prev => ({ ...prev, programName: ev }))
        },
        description: {
            error: form.errors.description,
            label: t('glossary:description'),
            name: 'description',
            required: false,
            type: 'textarea',
            value: form.values.description,
            onChange: form.handleChange
        },
        year: {
            disabled: form.values.isConfirmed,
            displayFormat: 'YYYY',
            error: form.errors.year,
            label: t('glossary:year'),
            maxLength: 4,
            name: 'year',
            type: 'text',
            value: form.values.year,
            onChange: form.handleChange
        },
        linkUrl: {
            error: form.errors.linkUrl,
            label: t('glossary:linkUrl'),
            name: 'linkUrl',
            type: 'text',
            required: false,
            value: form.values.linkUrl,
            onChange: form.handleChange
        }
    };

    // Handle form submition
    const evSubmit = ev => form.handleSubmit(ev, onSubmit);

    return (
        <Form.Form name="CorporateStep3Edit" onSubmit={evSubmit}>
            <Page.Boxed>
                <RelationshipCard {...form.values} relationship={relationship} />
                {/* <Page.Discl>{t('CorporateStep7Edit.noChangeToCorporate')}</Page.Discl> */}
            </Page.Boxed>

            <Page.Ruler />

            <Form.Field {...fields.companyName} />

            <Styled.ProgramLabel>Nome do programa</Styled.ProgramLabel>

            <CreatableSelect
                placeholder="Selecione ou digite um nome para criar"
                options={fields.programName.options}
                styles={{
                    control: baseStyles => ({
                        ...baseStyles,
                        borderRadius: 0,
                        borderColor: '#ddd'
                    }),
                    menu: baseStyles => ({
                        ...baseStyles,
                        zIndex: 91
                    })
                }}
                onChange={fields.programName.onChange}
                value={fields.programName.value}
            />

            {/* <Form.Field {...fields.programName} /> */}

            <Form.Row>
                <Form.Field size="4" {...fields.year} />
                <Form.Field size="8" {...fields.linkUrl} />
            </Form.Row>

            <Form.Field {...fields.description} />

            <Form.Submt {...form} text={t('glossary:continue')} />
        </Form.Form>
    );
}
