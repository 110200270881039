import React from 'react';

import { useConversionTable } from 'hooks';
import { useCXI } from 'modules/cxi/useCXI';

import { Option } from '../components/option/Option';

import * as Form from '../components/form';

export const Industries = () => {
    const { t, vars, addVars } = useCXI();
    const conv = useConversionTable(['industry']);
    const data = vars.profile.profile.sector.industries;

    const handleChange = value => {
        let newData = [...data];
        if (data.includes(value)) {
            newData = data.filter(v => v !== value);
        } else {
            newData.push(value);
        }

        const profile = {
            ...vars.profile,
            profile: {
                ...vars.profile.profile,
                sector: { ...vars.profile.profile.sector, industries: [...newData] }
            }
        };
        addVars({ profile });
    };

    return (
        <Form.Form name="Industries">
            <Form.Title>{t('setup.Titles.industries')}</Form.Title>
            <Form.Descr>{t('setup.Descrs.industries')}</Form.Descr>

            <Form.OptionsContainer>
                {Object.keys(conv.industry)
                    .map(value => parseInt(value, 10))
                    .map(value => (
                        <Option
                            label={conv.industry[String(value)]}
                            value={value}
                            key={value}
                            checked={!!data.includes(value)}
                            onChange={() => handleChange(value)}
                        />
                    ))}
            </Form.OptionsContainer>
        </Form.Form>
    );
};
