import React, { useContext } from 'react';

import { useForm } from 'components/form';
import * as Page from 'components/page';
import { RHP } from 'providers';

import { useParams } from 'react-router';

import { CG } from 'contexts';
import { EcosystemStep4EditForm } from './EcosystemStep4EditForm';

export function EcosystemStep4Edit(props) {
    const { id } = useParams();
    const { emitAlert } = useContext(CG);

    const {
        vars,
        putCorpEcosystemRelationship,
        getProgramsByCompanyId,
        postProgramByCompanyId
    } = props.useRankingEcosystem;
    const { ranking } = vars;

    const [changed, setChanged] = React.useState({});

    const relationship = vars.corpEcosystemRelationships.find(i => i._id === id);

    if (!relationship) {
        RHP.goBack();
    }

    // Initialize a new form handler
    const form = useForm({
        ...relationship,
        companyName: relationship.company.name,
        programName: {
            label: relationship.program.name || relationship.programName,
            value: relationship.program._id || relationship.programName
        },
        isConfirmed: !!(relationship.company && relationship.company.status === 'confirmed')
    });

    // Handle form changes
    const onBlur = {
        companyName: () => changed.companyName && setChanged({ companyName: false }),
        year: () => changed.year && setChanged({ year: false })
    };

    // Handle form changes
    const onChange = {
        companyName: () => setChanged({ companyName: true }),
        year: () => setChanged({ year: true })
    };

    // Handle form submissions
    const onSubmit = async values => {
        try {
            let companyPrograms = [];
            let program = null;

            if (relationship?.companyId) {
                companyPrograms = await getProgramsByCompanyId(relationship.ecosystemId);

                program = companyPrograms?.find(e => e?._id === values.programName.value);

                if (values.programName.__isNew__ || !program) {
                    program = await postProgramByCompanyId(
                        values.programName.label,
                        relationship.ecosystemId
                    );
                }
            }

            delete program?.regDate;
            delete program?.companies;

            const payload = {
                _id: values._id,
                year: values.year,
                programName: values.programName.label,
                description: values.description,
                linkUrl: values.linkUrl,
                entity: 'ecosystem',
                ranking
            };

            if (program) {
                payload.program = program;
            }

            await putCorpEcosystemRelationship(payload);

            RHP.push('/ranking/latam/ecosystem/step4');
        } catch (err) {
            emitAlert({
                message: `Ocorreu um erro inesperado, tente novamente mais tarde. Erro: ${err.message}`,
                header: 'Ops...'
            });
        } finally {
            form.submited();
        }
    };

    const propsForm = { ...props, form, onBlur, onChange, onSubmit, relationship };

    return (
        <Page.Page name="EcosystemStep4Edit">
            <Page.Title>{props.t('EcosystemStep3Edit.title')}</Page.Title>
            <Page.Descr>{props.t('EcosystemStep3Edit.descr')}</Page.Descr>

            <EcosystemStep4EditForm {...propsForm} />
        </Page.Page>
    );
}

export default EcosystemStep4Edit;
