import React from 'react';

import * as Form from 'components/form';

export function RegisterIndexForm(props) {
    const { conv, form, onBlur, onChange, onSubmit } = props;

    // Create form fields object
    const fields = {
        personal: [
            {
                autoComplete: 'given-name',
                error: form.errors.firstName,
                label: props.t('glossary:firstName'),
                name: 'firstName',
                type: 'text',
                value: form.values.firstName,
                onChange: form.handleChange
            },
            {
                autoComplete: 'family-name',
                error: form.errors.lastName,
                label: props.t('glossary:lastName'),
                name: 'lastName',
                type: 'text',
                value: form.values.lastName,
                onChange: form.handleChange
            }
        ],
        account: [
            {
                autoComplete: 'email',
                error: form.errors.email,
                label: props.t('glossary:workEmail'),
                name: 'email',
                type: 'email',
                required: true,
                value: form.values.email,
                warning: form.warnings.email,
                onBlur: ev => form.handleBlur(ev, onBlur.email),
                onChange: ev => form.handleChange(ev, onChange.email)
            },
            {
                autoComplete: 'off',
                error: form.errors.emailCheck,
                label: props.t('glossary:workEmailCheck'),
                name: 'emailCheck',
                type: 'email',
                required: true,
                value: form.values.emailCheck,
                onChange: ev => form.handleChange(ev, onChange.emailCheck)
            },
            {
                autoComplete: 'new-password',
                error: form.errors.password,
                helper: props.t('glossary:passwordHelper'),
                label: props.t('glossary:password'),
                name: 'password',
                type: 'password',
                value: form.values.password,
                onChange: form.handleChange
            }
        ],
        ecosystem: [
            {
                error: form.errors.role,
                helper: props.t('glossary:ecosystemRoleHelper'),
                label: props.t('glossary:ecosystemRole'),
                name: 'role',
                options: [
                    { name: props.t('glossary:ecosystemRoleSelect'), value: '' },
                    ...conv.ecosystemRole
                ],
                type: 'select',
                value: form.values.role,
                onChange: form.handleChange
            }
        ],
        phone: [
            {
                error: form.errors.phone,
                label: props.t('glossary:phone'),
                name: 'phone',
                type: 'tel',
                value: form.values.phone,
                onChange: form.handleChange
            },
            {
                error: form.errors.personalEmail,
                label: props.t('glossary:personalEmail'),
                name: 'personalEmail',
                type: 'email',
                required: false,
                value: form.values.personalEmail,
                warning: form.warnings.personalEmail,
                helper: props.t('glossary:personalEmailHelper'),
                onChange: form.handleChange
            }
        ],
        options: [
            {
                error: form.errors.acceptMarketingCampaigns,
                label: props.t('glossary:acceptMarketingCampaigns'),
                name: 'acceptMarketingCampaigns',
                required: false,
                type: 'checkbox',
                value: form.values.acceptMarketingCampaigns,
                onChange: form.handleChange
            },
            {
                error: form.errors.acceptPrivacyTerms,
                label: props.t('glossary:acceptPrivacyTerms'),
                name: 'acceptPrivacyTerms',
                required: true,
                type: 'checkbox',
                value: form.values.acceptPrivacyTerms,
                onChange: form.handleChange
            }
        ]
    };

    // Handle form submition
    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    return (
        <Form.Form name="RegisterIndexForm" onSubmit={evSubmit}>
            <Form.Fieldset border={false}>
                {fields.personal.map(f => (
                    <Form.Field key={f.name} {...f} />
                ))}
            </Form.Fieldset>

            <Form.Fieldset>
                {fields.account.map(f => (
                    <Form.Field key={f.name} {...f} />
                ))}
            </Form.Fieldset>

            <Form.Fieldset>
                {fields.phone.map(f => (
                    <Form.Field key={f.name} {...f} />
                ))}
            </Form.Fieldset>

            <Form.Fieldset>
                {fields.ecosystem.map(f => (
                    <Form.Field key={f.name} {...f} />
                ))}
            </Form.Fieldset>

            <Form.Fieldset>
                <a href="https://100os.net/terms" target="_blank" rel="noopener noreferrer">
                    {props.t('glossary:readPrivacyTerms')}
                </a>
                {fields.options.map(f => (
                    <Form.Field key={f.name} {...f} />
                ))}
            </Form.Fieldset>

            <Form.Submit {...form} text={props.t('RegisterIndex.submit')} />
        </Form.Form>
    );
}
export default RegisterIndexForm;
