import React from 'react';
import { Redirect, Route as ReactRoute, Switch } from 'react-router-dom';
import { useLocation } from 'react-use';

import { ContextAuth as CA } from 'contexts';
// eslint-disable-next-line import/no-cycle
import * as Ranking from './index';
import { Ranking as RankingLatam } from './ranking-latam/Ranking';

export const routes = [
    {
        exact: true,
        path: '/ranking',
        component: Ranking.RankingIndex,
    },

    // Ranking Module
    {
        path: '/ranking/latam',
        verified: true,
        component: RankingLatam,
    },
    {
        path: '/ranking/br',
        verified: true,
        component: () => <Redirect to="/ranking/latam/" />,
    },
    {
        path: '/ranking/co',
        verified: true,
        component: () => <Redirect to="/ranking/latam/" />,
    },

    // Redirect from old modules
    {
        exact: false,
        icon: 'far fa-user',
        name: 'Startup',
        path: '/ranking/startup',
        verified: false,
        component: () => <Redirect to="/ranking/latam/startup" />,
    },
    {
        exact: false,
        icon: 'far fa-user-tie',
        name: 'Corporate',
        path: '/ranking/corporate',
        verified: true,
        component: () => <Redirect to="/ranking/latam/corporate" />,
    },
    {
        exact: false,
        icon: 'far fa-user-chart',
        name: 'Investor',
        path: '/ranking/investor',
        verified: true,
        component: () => <Redirect to="/ranking/latam/investor" />,
    },
    {
        icon: 'far fa-users',
        name: 'Ecosystem',
        path: '/ranking/ecosystem',
        verified: true,
        component: () => <Redirect to="/ranking/latam/ecosystem" />,
    },

    // Ranking pages outside the default modules
    // This page applies to all the modules
    {
        exact: true,
        path: '/ranking/valid',
        component: Ranking.RankingValid,
    },
    {
        exact: true,
        path: '/ranking/validrecently',
        component: Ranking.RankingValidRecently,
    },
    {
        exact: true,
        path: '/ranking/validate',
        component: Ranking.RankingValidate,
    },
    {
        exact: false,
        icon: 'far fa-users',
        path: '*',
        component: () => <Redirect to="/ranking" />,
    },
];

const RouteVerifiedEmail = props => {
    const { state } = React.useContext(CA);
    const { pathname } = useLocation();
    const { setReturnTo } = props.props.useRanking;

    if (!state.user.hasVerifiedEmail) {
        setReturnTo(props.path);
        const propsRedir = { pathname: '/ranking/valid', search: `?returnUrl=${props.path}` };
        return <ReactRoute component={() => <Redirect to={propsRedir} />} />;
    }

    if (pathname.includes('/corporate')) {
        return <RouteVerifiedEmailRecently {...props} path={pathname} />;
    }

    return <ReactRoute {...props} />;
};

const RouteVerifiedEmailRecently = props => {
    const { state } = React.useContext(CA);
    const { setReturnTo } = props.props.useRanking;

    if (!state.user.hasVerifiedEmailRecently) {
        setReturnTo(props.path);
        const propsRedir = {
            pathname: '/ranking/validrecently',
            search: `?returnUrl=${props.path}`,
        };
        return <ReactRoute component={() => <Redirect to={propsRedir} />} />;
    }
    return <ReactRoute {...props} />;
};

export const RankingRoutes = props => (
    <Switch>
        {routes.map(r => {
            const Component = r.component || React.createComponent(r.toRender);
            const Route = r.verified ? RouteVerifiedEmail : ReactRoute;
            const routeProp = { ...r, props, component: undefined };
            return <Route key={r.path} {...routeProp} render={() => <Component {...props} />} />;
        })}
    </Switch>
);
export default RankingRoutes;
