import React, { useState, useEffect } from 'react';

import { useApiLocation, useConversionOptions } from 'hooks';

import * as Form from 'components/form';
import * as Page from 'components/page';
import * as Alert from 'components/alert';
import { ContextGlobal as CG } from 'contexts';
import { cnpj } from 'cpf-cnpj-validator';

import { isValidEmail } from 'utils/validation';
import * as Styled from './styles';
import { CompanyCard } from '../components';

export function StartupStep8AddForm({ form, onBlur, onSubmit, openModal, useRankingStartup }) {
    const { emitAlert, state } = React.useContext(CG);
    const { language } = state;

    const {
        vars,
        addVars,
        getCompanyByEmail,
        calculateContractPoints,
        getCompanyDetails,
        t
    } = useRankingStartup;
    const { ranking, contractPoints, searchedCompany } = vars;

    const apiLocation = useApiLocation();

    const convType = useConversionOptions(['contractType']);
    const convValue = useConversionOptions(['contractValue'], { namespace: 'currency' });

    const [dateError, setDateError] = React.useState(false);
    const [validDomain, setValidDomain] = React.useState(true);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [renderEmailConfirmationWarning, setRenderEmailConfirmationWarning] = useState(false);
    const [renderValueRangeWarning, setRenderValueRangeWarning] = useState(false);
    const [lessThan100Usd, setLessThan100Usd] = useState(false);
    const [smallCompany, setSmallCompany] = useState(false);
    const [contractType, setContractType] = useState();
    const [countries, setCountries] = useState([]);
    const [showEmailLanguageField, setShowEmailLanguageField] = useState(false);
    const convEmailLanguage = useConversionOptions(['language']);

    React.useEffect(() => {
        const { startDate, endDate, formalized, valueRange, contractType } = form.values;
        calculateContractPoints({
            startDate,
            endDate,
            formalized,
            valueRange,
            type: contractType
        });
    }, [form.values, calculateContractPoints]);

    const onChange = event => {
        const { name, value } = event.target;
        if (name === 'endDate')
            setDateError(form.values?.startDate?.substring(0, 7) > value.substring(0, 7));
        if (name === 'startDate')
            setDateError(form.values?.endDate?.substring(0, 7) < value.substring(0, 7));
    };

    const currentDate = new Date();
    const currentYear = currentDate.getUTCFullYear();
    const currentMonth =
        String(currentDate.getUTCMonth() + 1).length === 1
            ? `0${currentDate.getUTCMonth() + 1}`
            : String(currentDate.getUTCMonth() + 1);
    const currentDay =
        String(currentDate.getUTCDate()).length === 1
            ? `0${currentDate.getUTCDate()}`
            : String(currentDate.getUTCDate());

    const contractTypeOptions = [
        { name: t('glossary:selectOption'), value: '' },
        ...convType.contractType.filter(type => type.value !== 'none')
    ];

    const emailLanguageOptions = [
        ...convEmailLanguage.language.filter(type => type.value !== 'none')
    ];

    function checkIfSmallCompany(res) {
        setSmallCompany(false);

        if (res.company.id === undefined) {
            return;
        }
        if (res.company.type === 3) {
            setSmallCompany(true);
            const errorMessage = t('StartupStep8Add.smallCompanyError');
            emitAlert(errorMessage);
        }
    }

    // Create form fields object
    const fields = {
        contactName: {
            error: form.errors.contactName,
            label: t('glossary:contactNameContract'),
            name: 'contactName',
            type: 'text',
            value: form.values.contactName,
            onChange: form.handleChange
        },
        contactEmail: {
            error: form.errors.contactEmail,
            label: t('glossary:contactEmailContract'),
            name: 'contactEmail',
            type: 'email',
            value: form.values.contactEmail,
            onBlur: ev => {
                const companyEmail = ev.target.value || '';
                form.values.executiveResponsibleEmail = companyEmail;
                if (!companyEmail || !isValidEmail(ev.target.value)) {
                    addVars({ searchedCompany: {} });
                    return;
                }

                getCompanyByEmail(companyEmail).then(res => {
                    checkIfSmallCompany(res);
                    if (res.validdomain) {
                        setValidDomain(true);
                        setContractType(res.company.type);
                        return;
                    }
                    if (!res.validdomain) {
                        const errorMessage =
                            'O endereço de email do contato não pode ter domínio público.';
                        emitAlert(errorMessage);
                    }

                    setValidDomain(false);
                });
            },
            onChange: form.handleChange
        },
        contactConfirmationEmail: {
            error: form.errors.contactConfirmationEmail,
            label: t('glossary:contactConfirmationEmailContract'),
            name: 'contactConfirmationEmail',
            type: 'email',
            value: form.values.contactConfirmationEmail,
            onChange: form.handleChange
        },
        executiveResponsibleEmail: {
            error: form.errors.contactConfirmationEmail,
            label: t('glossary:executiveResponsibleEmail'),
            name: 'executiveResponsibleEmail',
            type: 'email',
            defaultValue: form.values.contactEmail,
            value: form.values.executiveResponsibleEmail,
            onChange: form.handleChange,
            required: true
        },
        corporateContractCountry: {
            error: form.errors.corporateContractCountry,
            label: t('glossary:corporateContractCountry'),
            name: 'corporateContractCountry',
            type: 'select',
            options: [{ name: t('glossary:select'), value: countries.id }, ...countries],
            value: form.values.corporateContractCountry,
            required: true,
            onChange: ev => {
                form.handleChange(ev);
                form.clearMessages({ name: 'taxId' });
            }
        },
        company: {
            error: form.errors.company,
            label: t('glossary:company'),
            name: 'company',
            type: 'text',
            value: form.values.company,
            onChange: form.handleChange,
            onlyIf: !searchedCompany.validcompany
        },
        taxId: {
            error: form.errors.taxId,
            label: t('glossary:cnpj'),
            onBlur: ev => form.handleBlur(ev, onBlur.isValidCNPJ),
            name: 'taxId',
            type: 'text',
            value: form.values.taxId,
            onChange: form.handleChange,
            required: true
        },
        contractExecutionCountries: {
            error: form.errors.contractExecutionCountries,
            label: t('glossary:contractExecutionCountries'),
            name: 'contractExecutionCountries',
            type: 'multipleSelect',
            // options: countries,
            options: [{ name: t('glossary:select'), value: 'select' }, ...countries],
            value: form.values.contractExecutionCountries,
            required: true,
            onChange: form.handleChange
        },
        emailLanguage: {
            error: form.errors.emailLanguage,
            label: t('glossary:emailLanguage'),
            name: 'emailLanguage',
            type: 'select',
            options: emailLanguageOptions,
            required: showEmailLanguageField,
            value: form.values.emailLanguage,
            onChange: form.handleChange
        },
        contractType: {
            error: form.errors.contractType,
            label: t('glossary:contractType'),
            name: 'contractType',
            type: 'select',
            options: contractTypeOptions,
            value: form.values.contractType,
            onChange: form.handleChange,
            icon: (
                <button className="ico-btn" type="button" onClick={openModal}>
                    <span
                        className="icon fa-fw fas fa-info-circle"
                        aria-hidden="true"
                        style={{ color: 'var(--primary)', cursor: 'pointer' }}
                    />
                </button>
            )
        },
        contractDescription: {
            error: form.errors.contractDescription,
            label: t('glossary:contractDescription'),
            maxLength: 500,
            name: 'contractDescription',
            type: 'textarea',
            value: form.values.contractDescription,
            onChange: form.handleChange
        },
        startDate: {
            displayFormat: 'MM-YYYY',
            presentation: 'month-year',
            error: form.errors.startDate,
            label: t('glossary:startDate'),
            maxLength: 4,
            name: 'startDate',
            min: `${String(currentYear - 10)}-${currentMonth}-${currentDay}`,
            max: form.values.endDate || `${String(currentYear)}-${currentMonth}-${currentDay}`,
            required: true,
            type: 'date',
            value: form.values.startDate,
            onChange: ev => form.handleChange(ev, onChange)
        },
        endDate: {
            displayFormat: 'MM-YYYY',
            presentation: 'month-year',
            error: form.errors.endDate,
            label: t('glossary:endDate'),
            maxLength: 4,
            name: 'endDate',
            min:
                form.values.startDate || `${String(currentYear - 2)}-${currentMonth}-${currentDay}`,
            max: `${String(currentYear + 10)}-${currentMonth}-${currentDay}`,
            required: true,
            type: 'date',
            disabled: !form.values.startDate,
            value: form.values.endDate,
            onChange: ev => form.handleChange(ev, onChange)
        },
        formalized: {
            error: form.errors.formalized,
            label: t('ranking:StartupStep8.isContractSigned'),
            name: 'formalized',
            type: 'YesNo',
            required: true,
            value: form.values.formalized,
            onChange: form.handleChange
        },
        valueRange: {
            error: form.errors.valueRange,
            label: t('glossary:contractValueRange'),
            name: 'valueRange',
            type: 'SelectRangeArray',
            options: convValue.contractValue,
            required: true,
            value: form.values.valueRange,
            onChange: form.handleChange,
            onlyIf: form.values.formalized === 'true'
        },
        isUpToDate: {
            error: form.errors.isUpToDate,
            label: t('glossary:isContractInfoTrue'),
            name: 'isUpToDate',
            type: 'checkbox',
            required: true,
            value: form.values.isUpToDate,
            onChange: form.handleChange
        },
        isSecret: {
            error: form.errors.isSecret,
            label: t('glossary:isContractConfidential'),
            name: 'isSecret',
            type: 'checkbox',
            required: false,
            value: form.values.isSecret,
            onChange: form.handleChange
        }
    };

    if (form.values.corporateContractCountry === 'BR') {
        fields.taxId.displayFormat = '00.000.000/0000-00';
        fields.taxId.maxLength = 18;
        fields.taxId.onChange = e => {
            e.target.value = cnpj.format(e.target.value);
            form.handleChange(e);
        };
    }

    // validate if email is equal, If not, renders the Alert
    const contactEmailValue = fields.contactEmail.value;
    const contactConfirmationEmailValue = fields.contactConfirmationEmail.value;

    useEffect(() => {
        setIsEmailValid(contactConfirmationEmailValue === contactEmailValue);
    }, [isEmailValid, contactEmailValue, contactConfirmationEmailValue]);

    useEffect(() => {
        apiLocation.geo('').then(res => {
            const names = res.map(country => {
                return { name: country.name, value: country.id };
            });
            setCountries(names);
        });
    }, []);

    // validate contract value
    const highValue = 200000; //
    const handleValue = fieldValue => {
        if (fieldValue !== undefined) {
            return fieldValue[0] < highValue;
        }
    };

    useEffect(() => {
        setContractType(fields.contractType.value);
        setLessThan100Usd(handleValue(fields.valueRange.value));
        if (
            (contractType === 'investment200k' || contractType === 'acquisition200k') &&
            lessThan100Usd
        ) {
            setRenderValueRangeWarning(true);
        } else {
            setRenderValueRangeWarning(false);
        }
    }, [fields]);

    // ALERT PROPS
    const propsAlert = {
        descr: t('glossary:emailConfirmationError'),
        icons: 'far fa-exclamation-circle',
        type: 'warning',
        color: 'red'
    };
    const propsValueRangeError = {
        descr: t('StartupStep8Add.valueRangeError'),
        icons: 'far fa-exclamation-circle',
        type: 'warning',
        color: 'red'
    };

    // Handle form submition
    const evSubmit = ev => {
        ev.persist();
        ev.preventDefault();

        form.values.emailLanguage = language;
        if (showEmailLanguageField) {
            form.values.emailLanguage = fields.emailLanguage.value;
        }

        if (!isEmailValid) {
            setRenderEmailConfirmationWarning(true);
            return false;
        }
        return form.handleSubmit(ev, onSubmit);
    };

    return (
        <Form.Form name="StartupStep8Add" onSubmit={evSubmit}>
            <Form.Field {...fields.contactName} />
            <Form.Field {...fields.contactEmail} />
            {!validDomain && (
                <Styled.FieldError className="domain-error">
                    {t('StartupStep8Add.domainError')}
                </Styled.FieldError>
            )}
            {smallCompany && (
                <Styled.FieldError className="domain-error">
                    {t('StartupStep8Add.smallCompanyError')}
                </Styled.FieldError>
            )}

            <Form.Field {...fields.contactConfirmationEmail} />
            {renderEmailConfirmationWarning && <Alert.AlertRow {...propsAlert} />}

            <Form.Field {...fields.company} />

            {searchedCompany.company && (
                <Page.Boxed style={{ marginTop: '2rem' }}>
                    {!!Object.keys(searchedCompany.company).length && (
                        <CompanyCard {...form.values} useRankingStartup={useRankingStartup} />
                    )}
                    <Styled.LanguageText onClick={() => setShowEmailLanguageField(true)}>
                        {!showEmailLanguageField &&
                            t('CorporateStep4Add.emailLanguageInfo', {
                                startupLanguage: t(`conversionTable:language.${language}`)
                            })}
                    </Styled.LanguageText>

                    {showEmailLanguageField && <Form.Field {...fields.emailLanguage} />}
                </Page.Boxed>
            )}

            <Form.Field {...fields.corporateContractCountry} />
            <Form.Field {...fields.taxId} />

            <Page.Ruler />

            <Styled.StyledFields>
                <Form.Field {...fields.contractType} />
                <Form.Field {...fields.contractExecutionCountries} />

                <Form.Field {...fields.contractDescription} />
            </Styled.StyledFields>

            <Form.Row>
                <Form.Field size="6" {...fields.startDate} />
                <Form.Field size="6" {...fields.endDate} />
            </Form.Row>
            {dateError && (
                <Styled.FieldError className="date-error">
                    {t('StartupStep8Add.dateError')}
                </Styled.FieldError>
            )}

            <Page.Ruler />

            <Form.Field {...fields.formalized} />
            <Form.Field {...fields.valueRange} />
            {renderValueRangeWarning && <Alert.AlertRow {...propsValueRangeError} />}

            <Page.Ruler />

            <span>
                {t('StartupStep8Add.maximumScore')} {ranking.year}:{' '}
                <b>
                    {contractPoints} {t('StartupStep8Add.points')}
                </b>
            </span>

            <Page.Ruler />

            <Form.Field {...fields.isSecret} />
            <Form.Field {...fields.isUpToDate} />

            <Form.Submt
                {...form}
                disabled={dateError || !validDomain || renderValueRangeWarning || smallCompany}
                text={t('glossary:continue')}
            />
        </Form.Form>
    );
}
