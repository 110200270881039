import styled from 'styled-components';

export const Step2 = styled.div`
    min-height: 100%;
    position: relative;

    .save {
        background-color: #0094f9;
        color: #ffffff;
        border: none;
        padding: 10px;
        cursor: pointer;
        border-radius: 5px;
    }

    label {
        display: flex;
        flex-direction: row;
        font-weight: 500;
        width: 100%;
        align-items: center;
        font-size: 12px;
    }

    input {
        height: 20px;
        align-items: center;
        border-radius: 8px;
        margin-top: 4px;
        margin-right: 10px;
        border: 1px solid #ccc;
        padding: 1rem;
    }

    textarea {
        align-items: center;
        border-radius: 8px;
        margin-top: 4px;
        margin-right: 10px;
        border: 1px solid #ccc;
        padding: 1rem;
    }
`;

export const Title = styled.h1`
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.2;
    display: flex;
    color: #0094f9;
`;

export const Subtitle = styled.h1`
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.2;
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    gap: 1rem;
    align-items: baseline;

    p {
        color: #0094f9;
        font-weight: 600;
        font-size: 1.4rem;
    }

    @media (max-width: 768px) {
        font-size: 1.6rem;
    }
`;

export const Option = styled.h1`
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 1.2;
    margin-top: 15px;
    display: flex;
    gap: 1rem;
    align-items: baseline;
    font-style: italic;

    p {
        color: #0094f9;
        font-weight: 600;
        font-size: 1.2rem;
    }

    @media (max-width: 768px) {
        font-size: 1.6rem;
    }
`;

export const Questions = styled.h1`
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 15px;
    display: flex;
    gap: 1rem;
    align-items: baseline;

    @media (max-width: 768px) {
        font-size: 1.2rem;
    }
`;

export const CSE = styled.div`
    .form-radio-label {
        margin-bottom: 14px;
    }
    .form-radio-label::before {
        color: #888;
    }
`;

export const Title1 = styled.h1`
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.2;
    margin: 0;
    display: flex;
    justify-content: space-between;
    color: black;
`;

export const Block = styled.h1`
    background: #ffffff;
    box-shadow: 0px 0px 16px rgba(37, 40, 48, 0.24);
    border-radius: 8px;
    width: 100%;
    padding: 14px;
    font-size: 12px;
    margin-bottom: 10px;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 4rem;
`;
