import tooltip from 'assets/images/icons/tooltipRanking.png';
import React, { useEffect, useState } from 'react';
import { useCXI } from '../useCXI';
import * as Styled from './StyledCXIChallenges';
import { CXIChallengeReminder } from './components/challengeReminder/CXIChallengeReminder';
import CXIChallengesList from './components/challengesList/CXIChallengesList';
import CXIChallengesFilters from './components/filter-general/CXIChallengesFilters';
import { FilterTag } from './components/filter-tag/FilterTag';

export const CXIChallenges = props => {
    const { t, vars, getCXIChallenges } = useCXI();
    const { cxi, challenges } = vars;

    const [displayedChallenges, setDisplayedChallenges] = useState();
    const [filters, setFilters] = useState([]);
    const [adminFilter, setAdminFilter] = useState(false);

    const [inQuestionTooltip, setInQuestionTooltip] = useState(false);
    const [openedTooltip, setOpenedTooltip] = useState(false);
    const [closedTooltip, setClosedTooltip] = useState(false);
    const [adminTooltip, setAdminTooltip] = useState(false);

    const filteredChallenges = adminFilter
        ? displayedChallenges.filter(e => e.isAdmin)
        : displayedChallenges;

    useEffect(() => {
        async function getChallenges() {
            const filtersString = filters
                ?.map(filter => `${encodeURIComponent(filter)}`)
                ?.join(',');
            const challenges = await getCXIChallenges(filtersString);

            setDisplayedChallenges(challenges);
        }

        getChallenges();
    }, [filters]);

    const hasFilter = value => {
        return Array.isArray(filters) && filters.includes(value);
    };

    const updFilter = values => {
        const updatedFilters = Array.isArray(values) ? values : [values];
        const filtersToUpdate = [];

        updatedFilters.forEach(value => {
            const filterIndex = filters.indexOf(value);

            if (filterIndex === -1) {
                filtersToUpdate.push(value);
            } else {
                filters.splice(filterIndex, 1);
            }
        });

        setFilters([...filters, ...filtersToUpdate]);
    };
    console.log(filters);
    return (
        <Styled.CXIChallenges>
            <br />
            <Styled.Title>
                <strong>{t('challenge.title')}</strong>
                {/* <Styled.Buttn onClick={() => setModal({ modal: 'ChallengeGuide' })}>
                    <span className="icon fas fa-play" aria-hidden="true" />
                    <span className="text">{t('challenge.tutorial')}</span>
                </Styled.Buttn> */}
            </Styled.Title>

            <Styled.Descr>{t('challenge.descr')}</Styled.Descr>

            {challenges.length === 0 && cxi.currentStage > 1 && <CXIChallengeReminder />}

            <CXIChallengesFilters challenges={challenges} />

            <Styled.Navigation>
                <p> {t('challenge.filter')} </p>

                <FilterTag active={hasFilter('none')} onClick={() => updFilter('none')}>
                    {t('challenge.tab.inQuestion')}
                    <div style={{ position: 'relative' }}>
                        <img
                            src={tooltip}
                            alt=" Tooltip 1"
                            onMouseOver={() => setInQuestionTooltip(true)}
                            onMouseLeave={() => setInQuestionTooltip(false)}
                        />
                        {inQuestionTooltip && (
                            <Styled.Tooltip>{t('challenge.tab.inQuestionTooltip')}</Styled.Tooltip>
                        )}
                    </div>
                </FilterTag>

                <FilterTag
                    active={hasFilter('active', 'application')}
                    onClick={() => updFilter(['active', 'application'])}
                >
                    {t('challenge.tab.opened')}
                    <div style={{ position: 'relative' }}>
                        <img
                            src={tooltip}
                            alt=" Tooltip 2"
                            onMouseOver={() => setOpenedTooltip(true)}
                            onMouseLeave={() => setOpenedTooltip(false)}
                        />
                        {openedTooltip && (
                            <Styled.Tooltip>{t('challenge.tab.openedTooltip')}</Styled.Tooltip>
                        )}
                    </div>
                </FilterTag>

                <FilterTag active={hasFilter('ended')} onClick={() => updFilter('ended')}>
                    {t('challenge.tab.closed')}
                    <div style={{ position: 'relative' }}>
                        <img
                            src={tooltip}
                            alt=" Tooltip 3"
                            onMouseOver={() => setClosedTooltip(true)}
                            onMouseLeave={() => setClosedTooltip(false)}
                        />
                        {closedTooltip && (
                            <Styled.Tooltip>{t('challenge.tab.closedTooltip')}</Styled.Tooltip>
                        )}
                    </div>
                </FilterTag>

                <FilterTag active={adminFilter} onClick={() => setAdminFilter(prev => !prev)}>
                    Desafios que eu sou admin
                    <div style={{ position: 'relative' }}>
                        <img
                            src={tooltip}
                            alt=" Tooltip 4"
                            onMouseOver={() => setAdminTooltip(true)}
                            onMouseLeave={() => setAdminTooltip(false)}
                        />
                        {adminTooltip && (
                            <Styled.Tooltip>Desafios em que sou administrador</Styled.Tooltip>
                        )}
                    </div>
                </FilterTag>
            </Styled.Navigation>

            <Styled.Subtitle>
                <strong>{t('challenge.title2')}</strong>
                <p>
                    ( {filteredChallenges?.length > 0 ? `${filteredChallenges?.length}` : `0`}{' '}
                    {t('challenge.general.sub1')} )
                </p>
            </Styled.Subtitle>

            <Styled.List>
                {displayedChallenges && displayedChallenges?.length > 0 && (
                    <CXIChallengesList challenges={filteredChallenges} t={t} />
                )}
            </Styled.List>
        </Styled.CXIChallenges>
    );
};
