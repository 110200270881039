import React from 'react';

import { CG } from 'contexts';
import * as Styled from './StyledCXIChallengeStartupGeneral';

export const CXIChallengeStartupGeneralContact = props => {
    const { startup, t } = props;

    const shouldShowSecretInfos = !startup?.entrepreneur?.hidden;

    return (
        <Styled.CXIChallengeStartupGeneralContact>
            {!!startup?.entrepreneur?.name && (
                <>
                    <dt>{t('startup.entrepreneur')}</dt>
                    <dd>{startup?.entrepreneur?.name}</dd>
                </>
            )}

            {!!startup?.entrepreneur?.phone && (
                <>
                    <dt>Contato</dt>
                    {shouldShowSecretInfos ? (
                        <dd>{startup?.entrepreneur?.phone}</dd>
                    ) : (
                        <dd>Marque "quero conversar" para ter acesso ao contato do empreendedor</dd>
                    )}
                </>
            )}

            {!!startup?.entrepreneur?.email && (
                <>
                    <dt>Email</dt>
                    {shouldShowSecretInfos ? (
                        <dd>{startup?.entrepreneur?.email}</dd>
                    ) : (
                        <dd>Marque "quero conversar" para ter acesso ao email do empreendedor</dd>
                    )}
                </>
            )}

            {!!startup?.entrepreneur?.department && (
                <>
                    <dt>Cargo</dt>
                    <dd>{startup?.entrepreneur?.department}</dd>
                </>
            )}

            {!!startup?.entrepreneur?.position && (
                <>
                    <dt>Posição</dt>
                    <dd>{startup?.entrepreneur?.position}</dd>
                </>
            )}

            {!!startup?.links && (
                <>
                    <dt>Redes</dt>
                    <dd style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
                        {startup?.links.facebook && (
                            <a href={startup?.links.facebook} target="_blank" rel="noreferrer">
                                <i className="icon fab fa-facebook" />
                            </a>
                        )}

                        {startup?.links.twitter && (
                            <a href={startup?.links.twitter} target="_blank" rel="noreferrer">
                                <i className="icon fab fa-twitter" />
                            </a>
                        )}

                        {startup?.links.linkedin && (
                            <a href={startup?.links.linkedin} target="_blank" rel="noreferrer">
                                <i className="icon fab fa-linkedin" />
                            </a>
                        )}

                        {startup?.demographics?.entrepreneur.phone && (
                            <a
                                href={`https://wa.me/${startup?.demographics?.entrepreneur.phone}`}
                                target="_black"
                                rel="noreferrer"
                                style={{ cursor: 'pointer' }}
                                onClick={() => {
                                    alert('Este numero pode nao ser um numero whatsapp valido!');
                                    // window.open(
                                    //     `https://wa.me/${startup?.demographics?.entrepreneur.phone}`
                                    // );
                                }}
                            >
                                <i className="icon fab fa-whatsapp" />
                            </a>
                        )}

                        {startup?.links.other && (
                            <a href={startup?.links.other} target="_blank" rel="noreferrer">
                                <i className="icon fa fa-link" />
                            </a>
                        )}
                    </dd>
                </>
            )}
        </Styled.CXIChallengeStartupGeneralContact>
    );
};
