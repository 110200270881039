import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { LocalStorageProvider as LSP } from 'providers';

// import en from 'translations/en';
// import es from 'translations/es';
// import pt from 'translations/pt';
import { translations } from 'translations';

const initOptions = {
    cleanCode: true,
    contextSeparator: '|',
    debug: false,
    lng: LSP.get('100os-language') || 'en',
    fallbackLng: {
        en: ['es', 'pt'],
        es: ['en', 'pt'],
        pt: ['en', 'es'],
        default: ['en'],
    },
    interpolation: { escapeValue: false },
    pluralSeparator: '|',
    react: {
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'small', 'em', 'i', 'p'],
    },
    resources: translations,
    // resources: { en: {} },
};

i18n.use(initReactI18next).init(initOptions, err => {
    // eslint-disable-next-line no-console
    return err && console.error('I18N_INIT_ERROR', err);
});
export default i18n;
