import React from 'react';

import { useConversionTable } from 'hooks';
import { useCXI } from 'modules/cxi/useCXI';

import { Option } from '../components/option/Option';

import * as Form from '../components/form';

export const Regions = () => {
    const { t, vars, addVars } = useCXI();
    const conv = useConversionTable(['region']);
    const data = vars.profile.profile.regions;

    const handleChange = value => {
        const uniqueIds = [];

        const uniqueDataKeys = data.filter(item => {
            const isDuplicate = uniqueIds.includes(item.id);
            if (!isDuplicate) {
                uniqueIds.push(item.id);
                return true;
            }
            return false;
        });

        let newData = [...uniqueDataKeys];

        if (
            data
                .map(item => {
                    return item.id;
                })
                .includes(value)
        ) {
            newData = data.filter(v => v.id !== value);
        } else {
            newData.push({ id: value, name: conv.region[value + 1] });
        }

        const profile = {
            ...vars.profile,
            profile: {
                ...vars.profile.profile,
                regions: [...newData],
            },
        };
        addVars({ profile });
    };

    return (
        <Form.Form name="Regions">
            <Form.Title>{t('setup.Titles.regions')}</Form.Title>
            <Form.Descr>{t('setup.Descrs.regions')}</Form.Descr>

            <Form.OptionsContainer>
                {Object.keys(conv.region)
                    .map(value => value - 1)
                    .map(value => (
                        <Option
                            label={conv.region[String(value + 1)]}
                            value={value + 1}
                            key={value}
                            checked={!!data.map(d => d.id).includes(value)}
                            onChange={() => handleChange(value)}
                        />
                    ))}
            </Form.OptionsContainer>
        </Form.Form>
    );
};
