import styled from 'styled-components';

export const ButtonsContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 10px;
`;

export const Button = styled.button<{ disabled?: boolean }>`
    background-color: ${props => (props.disabled ? '#bfbfbf' : '#56c1d6')};
    color: ${props => (props.disabled ? '#000000' : '#fff')};
    border: none;
    padding: 10px 15px;
    cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};
    border-radius: 5px;
`;
