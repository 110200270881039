import { UserAvatar } from 'core/components/user/avatar/UserAvatar';
import { Button } from 'modules/cxi/components/button';
import React from 'react';
import * as Styled from './styles';

export function PocsCard({ data, operationalOnly = false, useRankingEcosystem, t }) {
    const { setVars } = useRankingEcosystem;
    const { email, photoUrl, poc, fullName, evaluatorProfile } = data;

    const localName = data?.name || fullName;
    const localDepartment = data?.department || evaluatorProfile?.department || 'N/A';
    const localPosition = data?.position || evaluatorProfile?.position || 'N/A';

    const isNewPoc = data.type === 'add';

    const handleClick = type => {
        switch (type) {
            case 'delete':
            case 'keep':
                setVars(prev => ({
                    ...prev,
                    pocsRequest: prev.pocsRequest.map(item =>
                        item.email === email && item.poc === poc ? { ...item, type } : item
                    )
                }));
                break;
            case 'remove':
                setVars(prev => ({
                    ...prev,
                    pocsRequest: prev.pocsRequest
                        .map(item => (item.email === email && item.poc === poc ? null : item))
                        .filter(item => item)
                }));
                break;
            default:
                break;
        }
    };

    return (
        <Styled.RelationshipCard>
            <UserAvatar user={{ name: localName, photoUrl }} />

            <Styled.CardInfos>
                <span className="name">{localName}</span>
                <span className="bold">{email}</span>
                {localDepartment && (
                    <span className="text">
                        {localDepartment} / {localPosition}
                    </span>
                )}
            </Styled.CardInfos>

            {!operationalOnly && (
                <div style={{ display: 'flex', gap: 3 }}>
                    <Button
                        variant={data.type !== 'keep' && !isNewPoc ? 'toggle' : 'outline'}
                        onClick={() => handleClick(isNewPoc ? 'remove' : 'delete')}
                    >
                        {t('pocsCard.remove')}
                    </Button>
                    <Button
                        variant={data.type === 'keep' ? 'keep' : isNewPoc ? 'success' : 'outline'}
                        onClick={() => !isNewPoc && handleClick('keep')}
                    >
                        {t(isNewPoc ? 'pocsCard.added' : 'pocsCard.keep')}
                    </Button>
                </div>
            )}
        </Styled.RelationshipCard>
    );
}
export default PocsCard;
