import React, { useEffect } from 'react';

import * as Alert from 'components/alert';
import * as List from 'components/list';
import * as Modal from 'components/modal';
import * as Page from 'components/page';
import * as Tabs from 'components/tabs';
import { RHP } from 'providers';

import { Relationship, RelationshipCard } from '../components';

export function StartupStep7({ useRankingStartup, t }) {
    const { vars, updateSteps } = useRankingStartup;
    const { steps } = vars.application;

    const [modalData, setModalData] = React.useState(false);

    const relationships = vars.relationships.map(i => {
        const item = { ...i };
        item.onClick = () => setModalData(i);
        return item;
    });

    // Update the step has completed if relationships exist
    useEffect(() => {
        // if (vars.relationships && vars.relationships.length > 0 && steps.step5) {
        if (steps.step6 && !steps.step7) {
            updateSteps({ step7: true });
        }
    }, []);

    // Check for previous step conclusion
    if (!steps || steps.step5 !== true) {
        RHP.replace('/ranking/latam/startup/');
        return null;
    }

    const propsAlert = {
        title: t('StartupStep7.noRelationship'),
        descr: t('StartupStep7.noRelationshipDescr'),
        icons: 'far fa-exclamation-circle',
        buttn: {
            href: '/ranking/latam/startup/step7/add',
            text: t('StartupStep7.addRelationship')
        },
        type: 'warning'
    };

    const pending = relationships
        .filter(i => i.status === 'pending')
        .sort((a, b) => {
            return new Date(b.regDate) - new Date(a.regDate);
        });

    const rejected = relationships
        .filter(i => i.status === 'rejected')
        .sort((a, b) => {
            return new Date(b.regDate) - new Date(a.regDate);
        });

    const confirmed = relationships
        .filter(i => i.status === 'confirmed')
        .sort((a, b) => {
            return new Date(b.regDate) - new Date(a.regDate);
        });

    return (
        <Page.Page
            name="StartupStep7"
            back="/ranking/latam/startup"
            customBackText={t('glossary:backToRanking')}
        >
            <Page.Title>{t('StartupStep7.title')}</Page.Title>
            <Page.Descr>{t('StartupStep7.descr')}</Page.Descr>

            {relationships
                .filter(i => i.startup.status === 'pending')
                .slice(0, 1)
                .map(i => {
                    const item = { ...i };
                    item.onClick = undefined;
                    return (
                        <Relationship
                            key={`pending-${i._id}`}
                            data={item}
                            useRankingStartup={useRankingStartup}
                        />
                    );
                })}

            {!vars.relationships.length && <Alert.Alert {...propsAlert} />}

            <Page.Buttn to="/ranking/latam/startup/step7/add" type="linkd btn-block">
                <span className="icon far fa-plus-circle" aria-hidden="true" />
                <span className="text">{t('StartupStep7.addRelationship')}</span>
                <span className="icon far fa-long-arrow-right" aria-hidden="true" />
            </Page.Buttn>

            <Tabs.TabsContainer>
                <Tabs.TabsButtons>
                    <Tabs.Tab name="ES2C">
                        {t('glossary:confirmedBy.ecosystemAgent')}{' '}
                        {confirmed.length > 0 && <span>({confirmed.length})</span>}
                    </Tabs.Tab>
                    <Tabs.Tab name="ES2P">
                        {t('glossary:pendingConfirmation')}{' '}
                        {pending.length > 0 && <span>({pending.length})</span>}
                    </Tabs.Tab>
                    <Tabs.Tab name="ES2R">
                        {t('glossary:rejectedBy.parties')}{' '}
                        {rejected.length > 0 && <span>({rejected.length})</span>}
                    </Tabs.Tab>
                </Tabs.TabsButtons>

                <List.List name="ES2C">
                    <List.Vertical
                        items={confirmed}
                        itemKey="_id"
                        itemComponent={RelationshipCard}
                    />
                </List.List>
                <List.List name="ES2P">
                    <List.Vertical items={pending} itemKey="_id" itemComponent={RelationshipCard} />
                </List.List>
                <List.List name="ES2R">
                    <List.Vertical
                        items={rejected}
                        itemKey="_id"
                        itemComponent={RelationshipCard}
                    />
                </List.List>
            </Tabs.TabsContainer>

            <div style={{ display: 'flex', margin: '10px 0' }}>
                <Page.Buttn style={{ width: '25%' }} to="/ranking/latam/startup/step6">
                    <span className="icon far fa-long-arrow-left" aria-hidden="true" />
                    <span className="text" style={{ margin: '0 0 0 auto' }}>
                        {t('glossary:back')}
                    </span>
                </Page.Buttn>
                <Page.Buttn style={{ marginLeft: '10px' }} to="/ranking/latam/startup/step8">
                    <span className="text">{t('glossary:continue')}</span>
                    <span className="icon far fa-long-arrow-right" aria-hidden="true" />
                </Page.Buttn>
            </div>

            <Modal.Modal
                close={() => setModalData(false)}
                title={t('StartupStep7.relationship')}
                isOpen={!!modalData}
            >
                {modalData && (
                    <Relationship
                        closeModal={() => setModalData(false)}
                        data={modalData}
                        style={{ marginTop: '1rem' }}
                        useRankingStartup={useRankingStartup}
                    />
                )}
            </Modal.Modal>
        </Page.Page>
    );
}
