/* eslint-disable radix */
/* eslint-disable no-plusplus */

export function findEmailAddresses(str: string) {
    if (!str || typeof str !== 'string') return '';
    let email = ''; // if no match, use this
    const emailsArray = str.match(/\b[a-z0-9.%&+_-]+@[a-z0-9.+_-]+\.[a-z]{2,63}\b/i);
    if (emailsArray) {
        // eslint-disable-next-line prefer-destructuring
        email = emailsArray[0];
    }
    return email.trim().toLowerCase();
}

export function isValidEmail(email: string) {
    return !!findEmailAddresses(email.trim());
}

export function isValidUrl(url: string) {
    try {
        const urlObject = new URL(url);
        return !!urlObject;
    } catch {
        return false;
    }
}

export const isValidCPF = (input: string) => {
    const cpf = input.replace(/[^\d]+/g, '');
    const r = /^(0{11}|1{11}|2{11}|3{11}|4{11}|5{11}|6{11}|7{11}|8{11}|9{11})$/;

    if (!cpf || cpf.length !== 11 || r.test(cpf)) {
        return false;
    }

    function validateDigit(digit: number) {
        let add = 0;
        const init = digit - 9;
        for (let i = 0; i < 9; i++) {
            add += parseInt(cpf.charAt(i + init)) * (i + 1);
        }
        return (add % 11) % 10 === parseInt(cpf.charAt(digit));
    }

    return validateDigit(9) && validateDigit(10);
};

export const isValidCNPJ = (input: any) => {
    let n = 0;
    let c = input.replace(/[^\d]/g, '');
    const b = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
    const r = /^(0{14}|1{14}|2{14}|3{14}|4{14}|5{14}|6{14}|7{14}|8{14}|9{14})$/;
    if (!c || c.length !== 14 || r.test(c)) {
        return false;
    }
    c = c.split('');
    for (let i = 0; i < 12; i++) {
        n += c[i] * b[i + 1];
    }
    n = 11 - (n % 11);
    n = n >= 10 ? 0 : n;
    if (parseInt(c[12]) !== n) {
        return false;
    }
    for (let i = 0; i <= 12; i++) {
        n += c[i] * b[i];
    }
    n = 11 - (n % 11);
    n = n >= 10 ? 0 : n;
    if (parseInt(c[13]) !== n) {
        return false;
    }
    return true;
};
