import React from 'react';
import { Route, Switch } from 'react-router-dom';

import * as Page from './index';

export const routes = [
    {
        exact: true,
        path: '/100-10-1/form',
        component: Page.ChallengeIndex
    },
    {
        exact: true,
        path: '/100-10-1/form/1',
        component: Page.Step1
    },
    {
        exact: true,
        path: '/100-10-1/form/2',
        component: Page.Step2
    },
    {
        exact: true,
        path: '/100-10-1/form/3',
        component: Page.Step3
    },
    {
        exact: true,
        path: '/100-10-1/form/4',
        component: Page.Step4
    },
    {
        exact: true,
        path: '/100-10-1/form/5',
        component: Page.Step5
    }
];

export function CXIChallengesFormRoutes(props) {
    return (
        <Switch>
            {routes.map(r => {
                const Component = r.component || React.createComponent(r.toRender);
                const routeProp = { ...r, component: undefined };
                return (
                    <Route key={r.path} {...routeProp} render={() => <Component {...props} />} />
                );
            })}
        </Switch>
    );
}
