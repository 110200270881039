import styled from 'styled-components';

export const Menu = styled.div.attrs({
    className: `styled-menu`,
})`
    ion-content {
        --background: transparent;
        --color: inherit;
    }
`;

export const MenuContainer = styled.div.attrs({
    className: `styled-menu-container container`,
})`
    padding: 3rem;
    padding-top: calc(3rem + var(--ion-safe-area-top));
    padding-bottom: calc(3rem + var(--ion-safe-area-bottom));
`;

export const MenuContent = styled.div.attrs({
    className: `styled-menu-content`,
})``;

export const MenuContentArea = styled.div.attrs(props => ({
    className: `styled-menu-content-area ${props.area}`,
}))`
    flex: 1 1 auto;

    &.user {
        background: var(--secondary);
        flex: 0 0 auto;
        margin: 4rem 0 0;
        text-align: center;
    }

    .user-startups {
        color: #fff;
        .c-page-subtitle {
            color: #fff;
        }
    }
    .user-startups-list {
        background: transparent;
        border-top: 1px solid rgba(255, 255, 255, 0.2);
        padding: 0;
    }
    .user-startups-list-item {
        border-color: rgba(255, 255, 255, 0.2);
        color: #fff;
    }
`;

export const MenuContentWrap = styled.div.attrs({
    className: `styled-menu-content-wrap`,
})`
    display: flex;
    flex-direction: column;
    height: 100%;
`;
