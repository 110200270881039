import React from 'react';

import * as Styled from '../StyledFormInput';

import { FormInputCheckboxesOptions } from './FormInputCheckboxesOptions';

export const FormInpuCheckboxes = props =>
    // Don't show this element if it is empty
    !props.options ? null : (
        <Styled.FormInput as="fieldset" name="checkboxes">
            {props.children}
            <FormInputCheckboxesOptions {...props} />
        </Styled.FormInput>
    );
export const checkboxes = FormInpuCheckboxes;
export default FormInpuCheckboxes;
