import React from 'react';
import { RHP } from 'providers';
import { ContextGlobal as CG } from 'contexts';
import { Button } from 'modules/cxi/components/button';
import { useConversionOptions } from 'hooks';
import useCXI from 'modules/cxi/useCXI';
import * as Styled from './StyledChallengeCard';

export const ChallengeCard = ({ data }) => {
    const { language } = React.useContext(CG).state;

    const { t, vars } = useCXI();

    const { company } = vars;

    const conv = useConversionOptions(['eventType']);

    const applicationDeadlines = new Date(data?.applicationDeadline);
    const initialApplicationDates = new Date(data?.initialApplicationDate);
    const today = new Date();
    const remainingDays = Math.ceil((applicationDeadlines - today) / (1000 * 60 * 60 * 24));

    const descriptionLines = data?.headline
        ?.split('<br>')
        .map((line, index) => <div key={index}>{line}</div>);

    // const getStatusMessage = () => {
    //     switch (data?.eventStatus) {
    //         case 'active':
    //         case 'application':
    //             return t('challenge.card.open');
    //         case 'none':
    //             return t('challenge.card.none');
    //         case 'ended':
    //             return t('challenge.card.ended');
    //         default:
    //             return t('challenge.card.default');
    //     }
    // };

    const getStageMessage = () => {
        return data?.stages?.application
            ? t('challenge.card.openForApp')
            : t('challenge.card.closedForApp');
    };

    const handleButtonClick = () => {
        RHP.push(`/100-10-1/challenges/${data?._id}`);
    };

    return (
        <Styled.ChallengeCard>
            <div>
                <Styled.Image img={data?.logoUrl} />
            </div>

            <div className="body">
                <Styled.Title>{data?.name}</Styled.Title>

                <Styled.Label>
                    {data.isAdmin && <span className="admin">Administrador</span>}
                    <span className="text"> {conv.eventType[data?.type].value} </span>
                    {/* <span className="text"> {getStatusMessage()} </span> */}
                    {window.innerWidth > 768 && <span className="text"> {getStageMessage()} </span>}
                </Styled.Label>

                <Styled.Descr>{descriptionLines}</Styled.Descr>

                {(data?.applicationDeadline || data?.initialApplicationDate) && (
                    <div>
                        {!data?.stages?.application && data?.eventStatus !== 'none' && (
                            <Styled.IconWithText>
                                <span className="icon far fa-calendar" aria-hidden="true" />
                                <span>
                                    {t('challenge.card.closingDate')}
                                    {applicationDeadlines.toLocaleDateString(language)}{' '}
                                    {t('challenge.card.as')} {applicationDeadlines.getHours()}{' '}
                                    {t('challenge.card.hours')}
                                </span>
                            </Styled.IconWithText>
                        )}

                        {data?.stages?.application === true && data?.eventStatus !== 'none' && (
                            <Styled.IconWithText>
                                <span className="icon far fa-calendar" aria-hidden="true" />
                                <span>
                                    {t('challenge.card.closeDate')}{' '}
                                    {applicationDeadlines.toLocaleDateString(language)}{' '}
                                    {t('challenge.card.as')} {applicationDeadlines.getHours()}{' '}
                                    {t('challenge.card.hours')}
                                </span>
                                {applicationDeadlines > today && (
                                    <span
                                        style={{
                                            display: 'inline-block',
                                            fontSize: '0.8em'
                                        }}
                                    >
                                        ({t('challenge.card.part1')} {remainingDays}{' '}
                                        {t('challenge.card.part2')})
                                    </span>
                                )}
                            </Styled.IconWithText>
                        )}

                        {data?.eventStatus === 'none' && (
                            <>
                                {data?.stages?.application === false && (
                                    <Styled.IconWithText>
                                        <span className="icon far fa-calendar" aria-hidden="true" />
                                        <span>
                                            {t('challenge.card.closingDate')}{' '}
                                            {applicationDeadlines.toLocaleDateString(language)}{' '}
                                            {t('challenge.card.as')}{' '}
                                            {applicationDeadlines.getHours()}{' '}
                                            {t('challenge.card.hours')}
                                        </span>
                                    </Styled.IconWithText>
                                )}
                                {data?.stages?.application === true && (
                                    <Styled.IconWithText>
                                        <span className="icon far fa-calendar" aria-hidden="true" />
                                        <span>
                                            {t('challenge.card.startDate')}{' '}
                                            {initialApplicationDates.toLocaleDateString(language)}{' '}
                                            {t('challenge.card.as')}{' '}
                                            {initialApplicationDates.getHours()}{' '}
                                            {t('challenge.card.hours')}
                                        </span>
                                    </Styled.IconWithText>
                                )}
                            </>
                        )}
                    </div>
                )}
            </div>

            <div>
                <Styled.Buttons>
                    <Button size="challenge" variant="green" onClick={handleButtonClick}>
                        {t('challenge.subscribers')}
                    </Button>
                    {data?.eventStatus !== 'ended' && (
                        <Button
                            size="challenge"
                            variant="outline"
                            onClick={() =>
                                (window.location.href = 'mailto:helpme@openstartups.net')
                            }
                        >
                            {t('challenge.changes')}
                        </Button>
                    )}
                </Styled.Buttons>
            </div>
        </Styled.ChallengeCard>
    );
};

export default ChallengeCard;
