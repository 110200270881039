import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Alert from 'components/alert';

export function NextStep(props) {
    const { steps, useRankingCorporate } = props;
    const { application, contracts, relationships, ranking } = useRankingCorporate.vars;

    const { t } = useTranslation('ranking');

    // Create the alert element props
    const alert = {
        // Show texts informations
        title: t('NextStep.title'),
        // Build the button
        buttn: {
            href: '/ranking',
            text: ''
        },
        // Setup color and presentation
        type: 'primary'
    };

    // Get the first false step
    const step = steps.find(s => s.isCompleted === false);

    if (step === undefined) {
        if (!application.applied) {
            alert.type = 'success';
            alert.icons = 'far fa-check-circle';
            alert.title = t('NextStep.completeCorporate.title');
            // alert.descr = t('NextStep.completeCorporate.descr');
            alert.buttn = [
                // {
                //     href: '/ranking/latam/corporate/step1',
                //     type: 'light',
                //     text: t('NextStep.completeCorporate.apply'),
                // },
                {
                    href: '/ranking/latam/corporate/step4',
                    text: t('NextStep.moreContracts')
                }
            ];
        } else {
            alert.type = 'success';
            alert.icons = 'far fa-check-circle';
            alert.title = t('NextStep.success.title');
            alert.descr = t('NextStep.success.descr');
            alert.buttn = {
                href: '/ranking/latam/corporate/step4',
                text: t('NextStep.moreContracts')
            };
        }
    } else {
        alert.buttn = {
            href: step.href,
            text: step.text
        };
    }

    // Filter only pending relationships
    const pendingRelationship = relationships.filter(
        i => i.company.status === 'pending' && i.status === 'pending'
    );

    // Check for relationship
    if (pendingRelationship) {
        if (steps[1].isCompleted && pendingRelationship && pendingRelationship.length > 0) {
            alert.buttn = {
                href: '/ranking/latam/corporate/step3',
                text: steps[2].text
            };
            alert.title = t('NextStep.pendingEcosystemRelationships.title');
            alert.descr = t('NextStep.pendingEcosystemRelationships.descr');
            alert.icons = 'far fa-exclamation-circle';
            alert.type = 'warning';
        }
    }

    const checkContractIsEligible = contract => {
        return contract.eligibility?.[ranking?.year]?.eligible;
    };

    // Filter only pending investments
    const pending = contracts.filter(
        contract => !checkContractIsEligible(contract) && contract.companyStatus === 'pending'
    );

    // Check for investments
    if (steps[2].isCompleted && pending && pending.length > 0) {
        alert.buttn = {
            href: '/ranking/latam/corporate/step4',
            text: steps[3].text
        };
        alert.title = t('NextStep.pendingContracts.title');
        alert.descr = t('NextStep.pendingContracts.descr');
        alert.icons = 'far fa-exclamation-circle';
        alert.type = 'warning';
    }

    return <Alert.Alert {...alert} />;
}
