import React from 'react';

import { iframeLink } from 'core/iframe';

import { EcosystemIndexView } from './EcosystemIndexView';
import * as Styled from './StyledEcosystemIndex';

export function EcosystemIndex(props) {
    const { vars } = props.useRankingEcosystem;
    const { application } = vars;

    // Create the steps for this index
    const steps = [
        {
            step: 1,
            href: '/ranking/latam/ecosystem/step1',
            text: props.t('EcosystemIndex.step1'),
            isBlocked: false,
            isCompleted: application.steps.step1 || false
        },
        {
            step: 2,
            href: '/ranking/latam/ecosystem/step2',
            text: props.t('EcosystemIndex.step2'),
            isBlocked: !application.steps.step1,
            isCompleted: application.steps.step2 || false
        },
        {
            step: 3,
            href: '/ranking/latam/ecosystem/step3',
            text: props.t('EcosystemIndex.step3'),
            isBlocked: !application.steps.step2,
            isCompleted: application.steps.step3 || false
        },
        {
            step: 4,
            href: '/ranking/latam/ecosystem/step4',
            text: props.t('EcosystemIndex.step4'),
            isBlocked: !application.steps.step3,
            isCompleted: application.steps.step4 || false
        }
    ];

    // Create a list for the support links
    const actions = [
        {
            id: 1,
            href: iframeLink('https://100os.net/ranking-sobre'),
            text: props.t('EcosystemIndex.RankingAbout')
        },
        {
            id: 2,
            href: '/support',
            text: props.t('EcosystemIndex.HelpAndSupport')
        }
    ];

    const propsView = { ...props, actions, steps };
    return (
        <Styled.EcosystemIndex>
            <EcosystemIndexView {...propsView} />
        </Styled.EcosystemIndex>
    );
}
export default EcosystemIndex;
