import React from 'react';

import * as Form from 'components/form';
import * as Page from 'components/page';

export function CorporateStep1Form({ form, onSubmit, t }) {
    // Create form fields object
    const fields = {
        isUpToDate: {
            error: form.errors.isUpToDate,
            label: t('glossary:profileDataIsUpToDate'),
            name: 'isUpToDate',
            type: 'checkbox',
            value: form.values.isUpToDate,
            onChange: form.handleChange
        }
    };

    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    return (
        <Form.Form name="CorporateStep1Form" onSubmit={evSubmit}>
            <Form.Field {...fields.isUpToDate} />
            <div style={{ display: 'flex', gap: '10px' }}>
                <Page.Buttn style={{ width: '25%' }} to="/ranking/latam/corporate">
                    <span className="icon far fa-long-arrow-left" aria-hidden="true" />
                    <span className="text" style={{ margin: '0 0 0 auto' }}>
                        {t('glossary:back')}
                    </span>
                </Page.Buttn>
                <div style={{ width: '80%' }}>
                    <Form.Submt paddingTop="10rem" {...form} text={t('glossary:save')} />
                </div>
            </div>
        </Form.Form>
    );
}
