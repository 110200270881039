import styled from 'styled-components';

export const Cxi = styled.a.attrs({
    className: 'cxi-link'
})`
    display: block;

    img {
        height: auto;
        max-width: 100%;
        width: 100%;
    }
`;
