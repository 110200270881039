import React from 'react';

import { useForm } from 'components/form';
import { useConversionOptions } from 'hooks';

import { RHP } from 'providers';
import { Step3Form } from './Step3Form';

export function Step3(props) {
    const { useCXI } = props;
    const { addVars, vars } = useCXI;

    const conv = useConversionOptions(['companySize']);

    const currentDate = new Date();
    const currentYear = currentDate.getUTCFullYear();

    const currentMonth =
        String(currentDate.getUTCMonth() + 1).length === 1
            ? `0${currentDate.getUTCMonth() + 1}`
            : String(currentDate.getUTCMonth() + 1);

    const currentDay =
        String(currentDate.getUTCDate()).length === 1
            ? `0${currentDate.getUTCDate()}`
            : String(currentDate.getUTCDate());

    // Initialize a new form handler
    const form = useForm({
        ...vars.challengerForm,
        startDate: `${String(currentYear)}-${currentMonth}-${currentDay}`,
        endDate: `${String(currentYear)}-${currentMonth}-${currentDay}`
    });

    // Handle form submition
    const onSubmit = values => {
        const payload = {
            ...vars.challengerForm,
            ...values,
            initialApplicationDate: values.startDate,
            applicationDeadline: values.endDate
        };

        addVars({ challengerForm: { ...payload } });
        RHP.push('/100-10-1/form/4');
    };

    const propsForm = { ...props, conv, form, onSubmit };

    return <Step3Form {...propsForm} />;
}
