import styled from 'styled-components';
import { positions } from 'utils/styles';

export const CXIChallengeStartupGeneral = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    @media (min-width: 992px) and (max-width: 1199px) {
        gap: 3.2rem;
        .cxi-layout:not(.is-open) & {
            display: grid;
            grid-template:
                'a b' auto
                'c b' auto
                'd b' auto / 1fr 42rem;
        }
    }
    @media (min-width: 1200px) {
        display: grid;
        gap: 3.2rem;
        grid-template:
            'a b' auto
            'c b' auto
            'd b' auto / 1fr 42rem;
    }

    // .presentation {
    //     grid-area: a;
    // }

    // .ranking {
    //     grid-area: c;
    // }

    .details,
    .presentation {
        height: auto;
    }

    .details,
    .presentation {
        height: calc(max-content, max-content);
    }

    .ranking .area-body {
        background: transparent;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 0.2rem;
        overflow: hidden;
        padding: 0 !important;
        position: relative;
    }

    .details .area-body {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        gap: 3.2rem;
        justify-content: space-between;
        padding: 2.4rem;
    }

    .preferences .area-body {
        align-items: flex-start;
        display: flex;
        flex-direction: row;
        gap: 3.2rem;
        padding: 1rem;
        justify-content: space-between;
    }

    .presentation-video {
        grid-area: a;
        background: #000;
        padding: 56% 0 0 0 !important;
        position: relative;

        iframe {
            border: 0;
            height: 100%;
            position: absolute;
            ${positions(0, 0, 0, 0)}
            width: 100%;
        }
    }
`;

export const CXIChallengeStartupGeneralDetails = styled.dl`
    height: 310px;
    dt {
        color: ${({ theme }) => theme.colors.energyBlue};
        font-size: 1.2rem;
        font-weight: 700;
        margin: 0;
    }
    dd {
        color: ${({ theme }) => theme.colors.neutralDark};
        font-size: 1.4rem;
        font-weight: 600;
        margin: 0.2rem 0 0;

        a {
            color: ${({ theme }) => theme.colors.neutralDark};
        }

        div {
            cursor: pointer;
        }

        & + dt {
            margin-top: 1.4rem;
        }
    }
`;

export const CXIChallengeStartupGeneralContact = styled.dl`
    height: fit-content;

    dt {
        color: ${({ theme }) => theme.colors.energyBlue};
        font-size: 1.2rem;
        font-weight: 700;
        margin: 0;
    }

    dd {
        color: ${({ theme }) => theme.colors.neutralDark};
        font-size: 1.4rem;
        font-weight: 600;
        margin: 0.2rem 0 0;

        a {
            color: ${({ theme }) => theme.colors.neutralDark};
        }

        & + dt {
            margin-top: 1.4rem;
        }
    }
`;

export const CXIChallengeStartupPreferences = styled.dl`
    display: flex;
    flex-direction: column;
    gap: 1.6rem;

    @media (min-width: 992px) {
        gap: 1rem;
    }
`;

export const CXIChallengeStartupQuestions = styled.div`
    grid-area: c;

    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    flex: 1;
    height: 100%;

    @media (min-width: 992px) {
        gap: 3.2rem;
    }
`;

export const CXIChallengeStartupGeneralSectors = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media (min-width: 992px) {
        gap: 1rem;
    }
`;

export const CXIChallengeStartupGeneralSidebar = styled.div`
    grid-area: b;

    align-items: stretch;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    flex: 1;

    @media (min-width: 992px) {
        gap: 3.2rem;
    }
`;

export const Presentation = styled.div`
    grid-area: a;
    z-index: 0;
`;

export const CXIChallengeStartupGeneralFullwidth = styled.div`
    align-items: stretch;
    display: contents;
    flex-direction: column;
    gap: 1.6rem;

    @media (min-width: 992px) {
        gap: 3.2rem;
    }
`;
