import React from 'react';
import * as Styled from './styles';

interface ProgressBarProps {
    current: number;
}

export function ProgressBar({ current }: ProgressBarProps) {
    return (
        <Styled.ProgressBar>
            <Styled.ProgressItem className={current === 1 ? 'active' : ''}>
                <span className="icon far fa-fw fa-check" aria-hidden="true" />
            </Styled.ProgressItem>

            <Styled.DashedDivider />

            <Styled.ProgressItem className={current === 2 ? 'active' : ''}>
                <span className="icon far fa-fw fa-check" aria-hidden="true" />
            </Styled.ProgressItem>

            <Styled.DashedDivider />

            <Styled.ProgressItem className={current === 3 ? 'active' : ''}>
                <span className="icon far fa-fw fa-check" aria-hidden="true" />
            </Styled.ProgressItem>

            <Styled.DashedDivider />

            <Styled.ProgressItem className={current === 4 ? 'active' : ''}>
                <span className="icon far fa-fw fa-check" aria-hidden="true" />
            </Styled.ProgressItem>

            <Styled.DashedDivider />

            <Styled.ProgressItem className={current === 5 ? 'active' : ''}>
                <span className="icon far fa-fw fa-check" aria-hidden="true" />
            </Styled.ProgressItem>
        </Styled.ProgressBar>
    );
}
