import React from 'react';

import { CA } from 'contexts';
import { useApi } from 'hooks';
import { RHP } from 'providers';
import { queryParam } from 'utils/query';
import { useEffectOnce } from 'react-use';

export function useRanking() {
    const { state, dispatch, scopeAllows } = React.useContext(CA);
    const api = useApi();

    // Initial values for the state
    const initial = {
        codeFromQuery: queryParam('verificationcode'),
        redirectTo: queryParam('returnUrl'),
        term: {},
        userTerm: {},
        ...state.user,
    };

    // Controls if the module is ready
    const [ready, setReady] = React.useState(false);

    // Define variables holder to be used on the module
    const [vars, setVars] = React.useState(initial);
    const addVars = newVars => setVars(old => ({ ...old, ...newVars }));

    /**
     * INCLUDE VARS FUNCTIONS
     * ------------------------------------------------------------------------
     */

    function setReturnTo(href) {
        addVars({ redirectTo: href });
    }

    /**
     * HANDLER FUNCTIONS
     * ------------------------------------------------------------------------
     */

    const handleReauthentication = res => {
        const { userid } = state.auth;
        // Get user informations and reauthenticate the user
        return api.get(`/users/${userid}`).then(user => {
            dispatch({ type: 'UPDATE_USER_INFO', user });
            return res;
        });
    };

    // /**
    //  * CUSTOM FUNCTIONS
    //  * ------------------------------------------------------------------------
    //  */

    function getTerm(term) {
        return api.get(`/terms/${term}`).then(res => {
            addVars({ term: res });
            return res;
        });
    }

    function getUserTermByType(type) {
        const { userid } = state.auth;
        return api.get(`/users/${userid}/terms/${type}`).then(res => {
            addVars({ userTerm: res });
            return res;
        });
    }

    function postUserTerm(payload) {
        const { userid } = state.auth;
        return api.post(`/users/${userid}/terms/`, payload).then(res => {
            addVars({ userTerm: res });
            return res;
        });
    }

    function putUser(payload) {
        const { userid } = state.auth;
        return api.put(`/users/${userid}`, payload).then(res => {
            return handleReauthentication(res);
        });
    }

    useEffectOnce(() => {
        if (!scopeAllows('ranking')) {
            RHP.replace('/home');
            return;
        }

        // Initialize the module's service
        Promise.all([getUserTermByType('ranking2024')]).then(res => setReady(true));
    });

    return {
        // Ready to load?
        ready,
        // Variables handlers
        addVars,
        setVars,
        vars,
        // Include vars functions
        setReturnTo,
        // Custom functions
        getTerm,
        getUserTermByType,
        postUserTerm,
        putUser,
    };
}
export default useRanking;
