import React from 'react';
import { useEffectOnce } from 'react-use';

import { CA } from 'contexts';
import { useApi } from 'hooks';
import { queryParam } from 'utils/query';

export function useRankingInvestor() {
    const { state } = React.useContext(CA);
    const api = useApi();

    // Initial values for the state
    const initial = {
        application: { applied: false, steps: {} },
        investor: {},
        investments: [],
        validate: false,
        ranking: {
            name: '2024investor',
            year: '2024',
            country: 'BR'
        }
    };

    // Check for queryStrings
    if (
        queryParam('investmentId') &&
        queryParam('investmentResponse') &&
        queryParam('lastUpdated')
    ) {
        initial.validate = {
            _id: queryParam('investmentId'),
            status: queryParam('investmentResponse'),
            lastUpdated: queryParam('lastUpdated')
        };
    }

    // Controls if the module is ready
    const [ready, setReady] = React.useState(false);

    // Define variables holder to be used on the module
    const [vars, setVars] = React.useState(initial);
    const addVars = newVars => setVars(old => ({ ...old, ...newVars }));

    // /////////////////////////////////
    // RANKING APPLICATION FUNCTIONS  //
    // /////////////////////////////////

    /**
     * CUSTOM FUNCTIONS
     * ------------------------------------------------------------------------
     */

    async function getUserRankingApplication() {
        const { userid } = state.user;

        const application = api.get(`/users/${userid}/rankingapplications/${vars.ranking.name}`);

        if (Object.keys(application).length) {
            addVars({ application });
            return application;
        }

        return null;
    }
    function putUserRankingApplication(payload, updateVars = true) {
        const { userid } = state.user;
        return api
            .put(`/users/${userid}/rankingapplications/${vars.ranking.name}`, payload)
            .then(res => {
                // console.log('useRankingInvestor', 'putUserRankingApplication', res);
                if (updateVars) addVars({ application: res });
                return res;
            });
    }

    /**
     * INCLUDE VARS FUNCTIONS
     * ------------------------------------------------------------------------
     */

    const updateSteps = update => {
        // Get the current application variable
        const { application } = vars;
        application.steps = { ...application.steps, ...update };
        addVars({ application });
        return putUserRankingApplication({ ...application }, false);
    };

    // /////////////////////////////////////
    // USER'S INVESTOR PROFILE FUNCTIONS  //
    // /////////////////////////////////////

    /**
     * CUSTOM FUNCTIONS
     * ------------------------------------------------------------------------
     */

    function getUserInvestor() {
        const { userid } = state.auth;
        return api.get(`/users/${userid}/investor`).then(res => {
            // console.log('useRankingInvestor', 'getUserInvestor', res);
            addVars({ investor: res });
            return res;
        });
    }
    function putUserInvestor(payload) {
        const { userid } = state.auth;
        return api.put(`/users/${userid}/investor`, payload).then(res => {
            addVars({ investor: res });
            return res;
        });
    }

    // ///////////////////////////////////
    // INVESTMENT VALIDATION FUNCTIONS  //
    // ///////////////////////////////////

    /**
     * CUSTOM FUNCTIONS
     * ------------------------------------------------------------------------
     */

    function getUserInvestments() {
        const { userid } = state.auth;
        return api.get(`/users/${userid}/investments`).then(res => {
            // console.log('useRankingInvestor', 'getUserInvestments', res);
            addVars({ investments: res });
            return res;
        });
    }

    function getUserInvestmentById(investmentId) {
        const { userid } = state.auth;
        return api.get(`/users/${userid}/investments/${investmentId}`).then(res => {
            // console.log('getUserInvestmentById', investmentId, res);
            return res;
        });
    }

    function postUserInvestment(payload) {
        // {
        //     "date": "2019-07-01",
        //     "type": "Angel",
        //     "round": "Seed 2",
        //     "value": {
        //         "currency": "BRL",
        //         "amount": 20000,
        //     "currency": "USD",
        //     "startupId": "5afc879a0dce2d0eaba9f32a",
        //     "startup": {
        //         "name" : "Opa Opa ola ola voces",
        //         "contact": {
        //             "name": "Felipe Vignon",
        //             "email" : "felipe.feliposa@gmail.com"
        //         }
        //     },
        //     "linkUrl": "https://linkao.com.br",
        //     "rankings": {
        //         "2020": true
        //     }
        // }
        const { userid } = state.auth;
        return api.post(`/users/${userid}/investments`, payload).then(res => {
            return getUserInvestments(res);
        });
    }

    function putUserInvestment(payload) {
        // {
        //     "date": "2019-07-01",
        //     "type": "Angel",
        //     "round": "Seed 2",
        //     "vaue": {"currency": "USD", "amount": 20000 },
        //     "linkUrl": "https://linkao.com.br",
        //     "rankings": { "2020": false }
        // }
        const { userid } = state.auth;
        const { _id } = payload;
        return api.put(`/users/${userid}/investments/${_id}`, payload).then(res => {
            return getUserInvestments(res);
        });
    }

    function deleteUserInvestment(payload) {
        const { userid } = state.auth;
        const { _id } = payload;
        return api.delete(`/users/${userid}/investments/${_id}`).then(res => {
            return getUserInvestments(res);
        });
    }

    function postUserInvestmentValidation(payload) {
        // {
        //     "status": "confirmed",
        //     "lastUpdated": "2020-03-09T20:32:44.701Z"
        // }
        const { userid } = state.auth;
        const { _id } = payload;
        return api.post(`/users/${userid}/investments/${_id}/validation`, payload).then(res => {
            return getUserInvestments(res);
        });
    }

    function postUserInvestmentValidationEmail(payload) {
        const { userid } = state.auth;
        const { _id } = payload;
        return api.post(`/users/${userid}/investments/${_id}/sendvalidationemail`);
    }

    useEffectOnce(() => {
        // Validate investiment if avaliable
        // Validation moved to useRanking()

        // Initialize the module's service
        Promise.all([
            getUserRankingApplication(),
            getUserInvestor(),
            getUserInvestments()
        ]).then(res => setReady(true));
    });

    return {
        // Ready to load?
        ready,
        // Variables handlers
        addVars,
        setVars,
        vars,
        // Include vars functions
        updateSteps,
        // Custom functions / Application
        getUserRankingApplication,
        putUserRankingApplication,
        // Custom functions / Investor
        getUserInvestor,
        putUserInvestor,
        // Custom functions / Investments
        deleteUserInvestment,
        getUserInvestments,
        getUserInvestmentById,
        postUserInvestment,
        putUserInvestment,
        postUserInvestmentValidation,
        postUserInvestmentValidationEmail
    };
}
export default useRankingInvestor;
