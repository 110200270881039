import styled from 'styled-components';

export const GrowingBusinessData = styled.div.attrs({
    classname: 'growing-business-data',
})`
    .fi-generic {
        max-width: '100px';
    }

    .fi-generic .form-control-label {
        font-size: 12px;
        padding-top: 5px;
    }
`;
