import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import { CA, CG } from 'contexts';
import { useTranslation } from 'react-i18next';
import * as Ranking from './index';

export const routes = [
    {
        exact: true,
        path: '/ranking/latam/ecosystem',
        component: Ranking.EcosystemIndex
    },
    {
        exact: true,
        path: '/ranking/latam/ecosystem/editPocs',
        component: Ranking.EditPocs
    },
    {
        exact: true,
        path: '/ranking/latam/ecosystem/editPocs/add',
        component: Ranking.EditPocsAdd
    },
    {
        exact: true,
        path: '/ranking/latam/ecosystem/step1',
        component: Ranking.EcosystemStep1
    },
    {
        exact: true,
        path: '/ranking/latam/ecosystem/step2',
        component: Ranking.EcosystemStep2
    },
    {
        exact: true,
        path: '/ranking/latam/ecosystem/step3',
        component: Ranking.EcosystemStep3
    },
    {
        exact: true,
        path: '/ranking/latam/ecosystem/step3/add',
        component: Ranking.EcosystemStep3Add
    },
    {
        exact: true,
        path: '/ranking/latam/ecosystem/step3/edit/:id',
        component: Ranking.EcosystemStep3Edit
    },
    {
        exact: true,
        path: '/ranking/latam/ecosystem/step4',
        component: Ranking.EcosystemStep4
    },
    {
        exact: true,
        path: '/ranking/latam/ecosystem/step4/add',
        component: Ranking.EcosystemStep4Add
    },
    {
        exact: true,
        path: '/ranking/latam/ecosystem/step4/edit/:id',
        component: Ranking.EcosystemStep4Edit
    }
];

const RouteHasCompany = props => {
    const { t } = useTranslation('ranking');
    const { user } = React.useContext(CA).state;
    const { emitAlert } = React.useContext(CG);

    const isCompanyUser = user?.profile?.company.id;

    if (!user.company || !isCompanyUser) {
        const propsRedir = { pathname: '/ranking' };

        emitAlert(t('corporateEmailOnly'));

        return <Route component={() => <Redirect to={propsRedir} />} />;
    }

    return <Route {...props} />;
};

export const RankingEcosystemRoutes = props => (
    <Switch>
        {routes.map(r => {
            const Component = r.component || React.createComponent(r.toRender);
            const routeProp = { ...r, component: undefined };
            return (
                <RouteHasCompany
                    key={r.path}
                    {...routeProp}
                    component={() => <Component {...props} />}
                />
            );
        })}
    </Switch>
);
export default RankingEcosystemRoutes;
