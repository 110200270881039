import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import contractStar from 'assets/images/icons/contractStar.png';
import { CA } from 'contexts/ContextAuth';
import UserAvatar from 'core/components/user/avatar/UserAvatar';
import { useConversionTable } from 'hooks/useConversionTable';

import { RankingContext } from '../../RankingContext';
import * as Styled from './StyledContract';

export function ContractCard({ data, contract }) {
    const { vars } = useContext(RankingContext);
    const { user } = useContext(CA).state;

    const { t } = useTranslation('ranking');
    const conv = useConversionTable(['contractType']);

    const { ranking } = vars;

    const item = data || contract;

    if (!item) {
        return null;
    }

    const badges = [];

    const bold = conv.contractType[item.contractInfo?.contractType];
    const eligibilityContract = item?.eligibility?.[ranking?.year];

    const getStatusBadge = ({ type, message }) => {
        return {
            id: `${type}-${message}`,
            item: <div className={`badge badge-${type} mr-2`}>{t(message)}</div>
        };
    };

    switch (item.companyStatus) {
        case 'confirmed':
            badges.push(getStatusBadge({ type: 'success', message: 'corporateConfirmed' }));
            break;
        case 'pending':
            badges.push(getStatusBadge({ type: 'warning', message: 'corporatePending' }));
            break;
        case 'rejected':
            badges.push(getStatusBadge({ type: 'danger', message: 'corporateRejected' }));
            break;
        default:
            return null;
    }

    switch (item.startupStatus) {
        case 'confirmed':
            badges.push(getStatusBadge({ type: 'success', message: 'startupConfirmed' }));
            break;
        case 'pending':
            badges.push(getStatusBadge({ type: 'warning', message: 'startupPending' }));
            break;
        case 'rejected':
            badges.push(getStatusBadge({ type: 'danger', message: 'startupRejected' }));
            break;
        default:
            return null;
    }

    if (item.displayAudit) {
        if (item.audited === 'rejected') {
            badges.push(getStatusBadge({ type: 'danger', message: 'auditRejected' }));
        }

        if (item.audited === 'confirmed') {
            badges.push(getStatusBadge({ type: 'success', message: 'auditConfirmed' }));
        }

        if (item.status === 'confirmed' && item.reconsiderations) {
            if (item.reconsiderationResult) {
                badges.push(getStatusBadge({ type: 'secondary', message: 'reconsiderationDone' }));
            } else if (item.reconsiderations.length > 0 && item.hasAuditTeamMessage) {
                badges.push(
                    getStatusBadge({ type: 'warning', message: 'reconsiderationRequested' })
                );
            }
        }
    }

    if (
        item.status === 'rejected' &&
        item.startupStatus === 'rejected' &&
        item.companyStatus === 'rejected'
    ) {
        badges.push(getStatusBadge({ type: 'danger', message: 'startupRejected' }));
    }

    if (item.status === 'confirmed' && !item.displayAudit) {
        badges.push(getStatusBadge({ type: 'warning', message: 'auditPending' }));
    }

    if (item.status === 'confirmed' && item.displayAudit && item.reconsiderations) {
        if (item.reconsiderationResult) {
            badges.push(getStatusBadge({ type: 'secondary', message: 'reconsiderationDone' }));
        } else if (item.reconsiderations.length > 0 && item.hasAuditTeamMessage) {
            badges.push(getStatusBadge({ type: 'warning', message: 'reconsiderationRequested' }));
        }
    }

    if (
        item.status === 'confirmed' &&
        item.displayAudit &&
        item.reconsiderations &&
        item.reconsiderations.length > 0 &&
        !item.reconsiderationResult &&
        item.hasAuditTeamMessage
    ) {
        badges.push(getStatusBadge({ type: 'secondary', message: 'messageFromAuditTeam' }));
    }

    if (['none', 'inactive'].includes(item.startup.startupStatus)) {
        badges.push(getStatusBadge({ type: 'danger', message: 'notRegistered' }));
    }

    if (!eligibilityContract?.eligible) {
        if (eligibilityContract?.outOfRange) {
            badges.push(getStatusBadge({ type: 'danger', message: 'contractOutOfRange' }));
        }
        if (eligibilityContract?.expired) {
            badges.push(getStatusBadge({ type: 'danger', message: 'contractExpired' }));
        }
    }

    return (
        <Styled.ContractCard onClick={item.onClick}>
            <UserAvatar user={{ firstName: item.startup.name, photoUrl: item.startup.logoUrl }} />

            <Styled.CardInfos>
                <span className="name">{item.startup.name}</span>
                <span className="bold">{bold}</span>

                {!!item.onClick &&
                    item.startup.startupStatus === 'registered' &&
                    item.startupStatus === 'pending' && (
                        <Styled.ResendInfo>{t('glossary:resendInfo')}</Styled.ResendInfo>
                    )}

                {item.companyContact?.id !== user._id && (
                    <Styled.CardContact>
                        <div>{t('contractDeclaredWith')}</div>
                        <span className="contact">
                            {item.companyContact.name} &lt;{item.companyContact.email}&gt;
                        </span>
                    </Styled.CardContact>
                )}

                {badges.map(badge => (
                    <React.Fragment key={badge.id}>{badge.item}</React.Fragment>
                ))}

                <br />

                {item.audited === 'confirmed' &&
                    item.displayAudit &&
                    item?.eligibility[ranking?.year]?.eligible && (
                        <Styled.MaxContractPointsBadge>
                            <img src={contractStar} alt="star icon" />
                            <p>
                                {t('StartupStep8.contractsMaxPoints', {
                                    points: item.maxPoints || 0
                                })}
                            </p>
                        </Styled.MaxContractPointsBadge>
                    )}
            </Styled.CardInfos>
        </Styled.ContractCard>
    );
}
