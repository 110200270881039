import { useUser } from 'hooks';
import { Button } from 'modules/cxi/components/button';
import { ICompanyPocs } from 'modules/ranking/interface';
import { RouterHistoryProvider as RHP } from 'providers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PocsCard } from '../pocs/PocsCard';
import * as Styled from './styledButton';
import { PocsItem } from './pocs-item';

export function Pocs({ useRankingEcosystem }) {
    const { t } = useTranslation('glossary');

    const { vars } = useRankingEcosystem;

    const allCompanyPocs: ICompanyPocs = vars.companyPocs;

    // useEffect(() => {
    //     const run = async () => {
    //         const response = await getCompanyPocs(user.company.id);
    //         setAllCompanyPocs(response);
    //     };
    //     run();
    // }, []);

    return (
        <Styled.About>
            {allCompanyPocs?.pocs && (
                <Styled.Wrapper className="user">
                    <br />
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Styled.UserProfile>{t('pocs')}</Styled.UserProfile>
                        <div style={{ flexGrow: 1 }} />
                        <div>
                            <Button
                                className="about-button"
                                variant="blue"
                                size="small"
                                onClick={() => RHP.push('/ranking/latam/ecosystem/editPocs')}
                            >
                                <span className="text">{t('glossary:edit')}</span>
                            </Button>
                        </div>
                    </div>
                    <Styled.AboutBody className="user">
                        <div className="pocs-container">
                            {allCompanyPocs.pocs
                                .filter(e => e?.pocs?.includes('operational'))
                                .map((poc, index) => (
                                    <div key={poc.email} className="pocs-item">
                                        <PocsItem data={poc} />
                                    </div>
                                ))}
                        </div>
                    </Styled.AboutBody>
                </Styled.Wrapper>
            )}
        </Styled.About>
    );
}
