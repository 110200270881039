import useCXI from 'modules/cxi/useCXI';
import React, { useState, useEffect } from 'react';
import * as Styled from './StyledCXIChallengesProfileFilters';

export const CXIChallengesProfileFilters = props => {
    const { t, vars } = useCXI();
    const { startups, challengerAdmins } = vars;
    const [startupLength, setStartupLength] = useState(0);
    const [appliedStartupLength, setAppliedStartupLength] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
            setStartupLength(startups?.length ?? 0);
            setAppliedStartupLength(
                startups?.filter(startup => startup.event.eventStatus === 'applied')?.length ?? 0
            );
        }, 1000);

        return () => clearTimeout(timer);
    }, [startups]);

    const firstAdminFullName =
        challengerAdmins?.[0]?.firstName + ' ' + challengerAdmins?.[0]?.lastName || '';
    return (
        <Styled.CXIChallengesFilters>
            <Styled.Filter className="challenges">
                <div>
                    <Styled.Icon>
                        <span className="icon far fa-thin fa-comments-alt" aria-hidden="true" />
                    </Styled.Icon>
                </div>
                <div>
                    <p> {t('challenge.profile.filter1')} </p>
                    <strong>
                        {startupLength} {t('challenge.profile.register')}
                    </strong>
                </div>
            </Styled.Filter>

            <Styled.Filter className="challenges">
                <div>
                    <Styled.Icon>
                        <span className="icon far fa-fw fa-check-circle" aria-hidden="true" />
                    </Styled.Icon>
                </div>
                <div>
                    <p> {t('challenge.profile.filter1')} </p>
                    <strong>
                        {appliedStartupLength} {t('challenge.profile.feedback')}
                    </strong>
                </div>
            </Styled.Filter>

            {challengerAdmins && challengerAdmins?.length > 0 && (
                <Styled.Filter className="admins challenges">
                    <div>
                        <Styled.Icon>
                            <span className="icon far fa-thin fa-comments-alt" aria-hidden="true" />
                        </Styled.Icon>
                    </div>
                    <div>
                        <p>{t('challenge.profile.filter1')}</p>
                        <strong>
                            {challengerAdmins.length} administradores ({firstAdminFullName}
                            {challengerAdmins.length > 1 &&
                                ` e mais ${challengerAdmins.length - 1}...`}
                            )
                        </strong>
                    </div>
                </Styled.Filter>
            )}
        </Styled.CXIChallengesFilters>
    );
};

export default CXIChallengesProfileFilters;
