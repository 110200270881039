import React from 'react';
import { useMedia } from 'react-use';
import { CA } from 'contexts';
import { RHP } from 'providers';

// import cxiInviteImage from 'assets/images/not_paid_cxi.png';
import cxiPaidImage from 'assets/images/c2a-cxi-paid.jpg';
import cxiPaidMobileImage from 'assets/images/c2a-cxi-paid-mobile.jpg';

import * as Styled from './StyledCxi';

export function Cxi() {
    const { state } = React.useContext(CA);
    const { cxi } = state;

    const isMobile = useMedia('(max-width: 991px)');

    let newWindow = true;
    let url = 'https://www.openstartups.net/site/produtos/100-10-1.html';

    if (cxi?.status === 'registered') {
        newWindow = false;
        url = '/100-10-1/home';
    } else if (cxi?.status === 'none') {
        // If 'none', use the default URL
        newWindow = true;
        url = 'https://www.openstartups.net/site/produtos/100-10-1.html';
    }

    return (
        <Styled.Cxi href={url} target={newWindow ? '_blank' : ''}>
            <img
                src={
                    cxi?.status !== 'none'
                        ? isMobile
                            ? cxiPaidMobileImage
                            : cxiPaidImage
                        : cxiPaidImage
                }
                alt={`Banner ${cxi?.status === 'registered' ? 'Paid' : 'Invite'} 100-10-1`}
            />
        </Styled.Cxi>
    );
}

export default Cxi;
