import React, { useContext } from 'react';

import { useForm } from 'components/form';
import * as Page from 'components/page';
import { RHP } from 'providers';

import { CG } from 'contexts';
import { CorporateStep3AddForm } from './add-form';

export function CorporateStep3Add(props) {
    const { emitAlert } = useContext(CG);

    const {
        vars,
        postCorpEcosystemRelationship,
        postProgramByCompanyId,
        getProgramsByCompanyId,
        getCompanyByEmail
    } = props.useRankingCorporate;

    const { ranking, application } = vars;

    const currDate = new Date();

    // Initialize a new form handler
    const form = useForm({
        year: currDate.getFullYear(),
        otherContact: false
    });

    // Check for previous step conclusion
    if (!application.steps || !application.steps.step2) {
        RHP.replace('/ranking/latam/corporate/');
        return null;
    }

    // Handle form submitions
    const onSubmit = async values => {
        try {
            const currentCompany = await getCompanyByEmail(values.ecosystemContactEmail);

            let companyPrograms = [];
            let program = null;

            if (currentCompany?.company) {
                companyPrograms = await getProgramsByCompanyId(currentCompany?.company?.id);

                program = companyPrograms?.find(e => e?._id === values.programName.value);

                if (values.programName.__isNew__ && !values?.companyName) {
                    program = await postProgramByCompanyId(
                        values.programName.label,
                        currentCompany?.company?.id
                    );
                }
            }

            delete program?.regDate;
            delete program?.companies;

            const payload = {
                description: values.description,
                ecosystem: {
                    name: currentCompany?.company?.name || values.companyName,
                    contact: {
                        name: values.ecosystemContactName,
                        email: values.ecosystemContactEmail
                    }
                },
                entity: 'company',
                linkUrl: values.linkUrl,
                programName: values.programName.label,
                ranking,
                year: values.year
            };

            if (program) {
                payload.program = program;
            }

            // Add relationship
            await postCorpEcosystemRelationship(payload);
            RHP.push('/ranking/latam/corporate/step3');
        } catch (err) {
            emitAlert({
                message: `Ocorreu um erro inesperado, tente novamente mais tarde. Erro: ${err.message}`,
                header: 'Ops...'
            });
        } finally {
            form.submited();
        }
    };

    const propsForm = { ...props, form, onSubmit };

    return (
        <Page.Page name="CorporateStep3Add">
            <Page.Title>{props.t('CorporateStep3Add.addRelationship')}</Page.Title>
            <Page.Descr>{props.t('CorporateStep3Add.descrTitle')}</Page.Descr>

            <CorporateStep3AddForm {...propsForm} />
        </Page.Page>
    );
}
