import React, { useEffect } from 'react';
import { useEffectOnce } from 'react-use';

import { CA } from 'contexts';
import { useApi } from 'hooks';
import { RHP } from 'providers';
import { queryParam } from 'utils/query';
import { RankingContext } from './RankingContext';

export function useRankingCorporate() {
    const { state } = React.useContext(CA);
    const { vars, addVars, setVars, t } = React.useContext(RankingContext);

    const api = useApi();

    // Check for queryStrings
    if (queryParam('contractId') && queryParam('contractResponse')) {
        vars.validate = {
            _id: queryParam('contractId'),
            status: queryParam('contractResponse')
        };
    }

    // Controls if the module is ready
    const [ready, setReady] = React.useState(false);

    // //////////////////////
    //  STARTUP FUNCTIONS  //
    // //////////////////////

    const type2Points = React.useMemo(
        () => ({
            none: 0,
            match: 0,
            mentoring: 1,
            connection: 0,
            award: 1,
            coworking: 1,
            voucher: 1,
            outlicensing: 5,
            resources: 5,
            employee: 5,
            channels: 5,
            acceleration: 5,
            'r&d': 10,
            inlicensing: 10,
            pilot: 10,
            supplying: 10,
            accelerationLarge: 5,
            investmentLarge: 0,
            investment: 0,
            acquisition: 0,
            investment200k: 20,
            acquisition200k: 20
        }),
        []
    );

    const checkStartupCountry = () => {};

    const calculateContractPoints = React.useCallback(
        async contract => {
            // if (contract.selectedStartup.country && contract.selectedStartup.country !== 'BR') {
            //     addVars({ contractPoints: 0 });
            //     return;
            // }

            const startDate = new Date(contract.startDate);
            let endDate = new Date(contract.endDate);

            if (contract.startDate && !contract.endDate) {
                endDate = new Date(contract.startDate);
                endDate.setFullYear(startDate.getFullYear() + 1);
            }
            // Check the dates
            if (
                endDate >= startDate &&
                endDate >= new Date('2023-07-01') &&
                startDate < new Date('2024-07-01')
            ) {
                if (contract.formalized !== 'true') {
                    addVars({ contractPoints: 0 });
                } else if (
                    contract.valueRange &&
                    Array.isArray(contract.valueRange) &&
                    contract.valueRange[0] >= 200000 &&
                    contract.type !== 'connection'
                ) {
                    addVars({ contractPoints: 20 });
                } else {
                    addVars({ contractPoints: type2Points[contract.type] || 0 });
                }
            } else {
                addVars({ contractPoints: 0 });
            }
        },
        [type2Points]
    );

    // /////////////////////////////////
    // RANKING APPLICATION FUNCTIONS  //
    // /////////////////////////////////

    /**
     * CUSTOM FUNCTIONS
     * ------------------------------------------------------------------------
     */

    const currentRanking = vars.ranking.id;

    async function getRanking(rankingId = currentRanking) {
        const response = await api.get(`/rankings`);

        const currentRanking = response.find(r => r.id === rankingId);

        addVars({ currentRanking });
        return currentRanking;
    }

    async function getUserRankingApplication() {
        const { userid } = state.user;

        const application = await api.get(
            `/users/${userid}/rankingapplications/${vars.ranking.name}`
        );

        if (Object.keys(application).length) {
            addVars({ application });
            return application;
        }

        return null;
    }

    async function putUserRankingApplication(payload, updateVars = true) {
        const { userid } = state.user;

        const application = await api.put(
            `/users/${userid}/rankingapplications/${vars.ranking.name}`,
            payload
        );

        if (updateVars) {
            addVars({ application });
        }

        return application;
    }

    /**
     * INCLUDE VARS FUNCTIONS
     * ------------------------------------------------------------------------
     */

    async function getProgramsByCompanyId(companyId) {
        const response = await api.get(`/companies/${companyId}/programs`);

        return response;
    }

    async function postProgramCompany(programName) {
        const { companyId } = vars;

        const payload = { name: programName };

        const response = await api.post(`/companies/${companyId}/programs`, payload);

        return response;
    }

    async function postProgramByCompanyId(programName, companyId) {
        const payload = { name: programName };

        const response = await api.post(`/companies/${companyId}/programs`, payload);

        return response;
    }

    function setReturnTo(href) {
        addVars({ redirectTo: href });
    }

    const updateSteps = update => {
        // Get the current application variable
        const { application } = vars;
        application.steps = { ...application.steps, ...update };
        addVars({ application });
        return putUserRankingApplication({ ...application }, false);
    };

    // /////////////////////////////////////
    // RELATIONSHIP VALIDATION FUNCTIONS  //
    // /////////////////////////////////////

    /**
     * CUSTOM FUNCTIONS
     * ------------------------------------------------------------------------
     */

    async function getUserContracts(currentRanking = vars.currentRanking) {
        const { userid } = state.auth;

        const contracts = await api.get(`/users/${userid}/contracts`);

        addVars({ contracts });
        return contracts;
    }

    async function getUserContractById(investmentId) {
        const { userid } = state.auth;

        const res = await api.get(`/users/${userid}/contracts/${investmentId}`);

        return res;
    }

    async function postUserContract(payload) {
        // {
        //   contractInfo: {
        //     contractType: 'mentoring',
        //     description: 'Piloto do 100os com xpto',
        //     startDate: '2018-01-01T00:00:00.000Z',
        //     endDate: '2019-04-01T00:00:00.000Z',
        //     formalized: true,
        //     disclosureAllowed: true,
        //     valueRange: [100000, 500000], // Optional
        //   },
        //   startupContact: { // Optional
        //     name: 'Clark Kent', // Optional
        //     email: 'clark.kent@openstartups.net', // Optional
        //   },
        //   startup: {
        //     _id: '5afc879a0dce2d0eaba9f32a', // Optional. If missing, will create new startup
        //     name: 'Acme Inc', // Only considered if no _id sent (will create new startup)
        //   },
        // };

        const { userid } = state.auth;

        await api.post(`/users/${userid}/contracts`, payload);

        return getUserContracts();
    }

    async function putUserContract(payload) {
        // {
        //     "date": "2019-07-01",
        //     "type": "Angel",
        //     "round": "Seed 2",
        //     "vaue": {"currency": "USD", "amount": 20000 },
        //     "linkUrl": "https://linkao.com.br",
        //     "rankings": { "2020": false }
        // }

        const { userid } = state.auth;
        const { _id } = payload;

        await api.put(`/users/${userid}/contracts/${_id}`, payload);

        return getUserContracts();
    }

    async function deleteUserContract(payload) {
        const { userid } = state.auth;
        const { _id } = payload;

        await api.delete(`/users/${userid}/contracts/${_id}`);

        return getUserContracts();
    }

    async function postUserContractValidation(payload) {
        // {
        //     "status": "confirmed",
        //     "lastUpdated": "2020-03-09T20:32:44.701Z"
        // }
        const { userid } = state.auth;
        const { _id } = payload;

        await api.post(`/users/${userid}/contracts/${_id}/validation`, payload);
        return getUserContracts();
    }

    async function postPocsRequest({ pocsRequest }) {
        try {
            const response = await api.post(`/companies/${vars.companyId}/pocrequest`, pocsRequest);
            return response;
        } catch (e) {
            throw new Error('RankingCorporate: Not able to post pocsRequest.');
        }
    }

    // VER COM LEVY
    // function postUserContractValidationEmail(payload) {
    //     const { userid } = state.auth;
    //     const { _id } = payload;
    //     return api.post(`/users/${userid}/contracts/${_id}/sendvalidationemail`);
    // }

    async function postUserContractValidationEmail(payload) {
        const { _id } = payload;
        const { userid } = state.auth;

        const response = await api.post(`/users/${userid}/contracts/${_id}/sendemail`);

        return response;
    }

    async function postUserContractReconsideration(payload) {
        const { userid } = state.auth;
        const { _id } = payload;

        await api.post(`/users/${userid}/contracts/${_id}/reconsideration`, payload);

        return getUserContracts();
    }

    async function getCompanyByEmail(companyEmail) {
        const response = await api.get(`/companyfromemail/${companyEmail}`);

        addVars({ searchedCompany: response });
        return response;
    }

    /// /////////////////////////
    //  CORPORATE ECOSYSTEM RELATIONSHIP FUNCTIONS  //
    // //////////////////////

    async function getCorpEcosystemRelationships() {
        const { userid } = state.auth;

        const relationships = await api.get(`/users/${userid}/corpecosystemrelationships/`, {
            params: { entity: 'company' }
        });

        addVars({ relationships });

        return relationships;
    }

    async function getCompanyPocs() {
        try {
            const companyPocs = await api.get(`/companies/${vars.companyId}/pocs`);

            let { pocsRequest } = vars;

            if (!pocsRequest.length) {
                pocsRequest = companyPocs.pocs.flatMap(poc => {
                    const uniquePocs = Array.from(new Set(poc.pocs));
                    return uniquePocs
                        .filter(e => !e.includes('hub'))
                        .map(pocItem => ({
                            name: poc.fullName,
                            email: poc.email,
                            department: poc.evaluatorProfile?.department || 'N/A',
                            position: poc.evaluatorProfile?.position || 'N/A',
                            type: 'keep',
                            status: 'confirmed',
                            poc: pocItem,
                            photoUrl: poc.photoUrl
                        }));
                });
            }

            addVars({ companyPocs, pocsRequest });
            return companyPocs;
        } catch (e) {
            throw new Error('RankingCorporate: Not able to get companyPocs.');
        }
    }

    // function getOneCorpEcosystemRelationships(res) {
    //     const { userid } = state.auth;
    //     const { _id } = res; // id vindo da response od backend
    //     const params = { queryParam: 'company' };
    //     console.log(res, 'payload da get');
    //     return api.get(`/users/${userid}/corpecosystemrelationships/${_id}`, params).then(res => {
    //         addVars({ relationships: res });
    //         return res;
    //     });
    // }

    //  /users/:userid/corpecosystemrelationships
    async function postCorpEcosystemRelationship(payload) {
        const { userid } = state.auth;

        await api.post(`/users/${userid}/corpecosystemrelationships/`, payload, {
            params: { entity: 'company' }
        });

        return getCorpEcosystemRelationships();
    }

    async function postCorpEcosystemRelationshipValidation(payload) {
        // {
        //     "status": "confirmed",
        //     "lastUpdated": "2020-03-09T20:32:44.701Z"
        // }
        const { userid } = state.auth;
        const { _id } = payload;

        const response = await api.post(
            `/users/${userid}/corpecosystemrelationships/${_id}/validation`,
            payload,
            {
                params: { entity: 'company' }
            }
        );

        return getCorpEcosystemRelationships(response);
    }

    async function postCorpEcosystemRelationshipValidationEmail(payload) {
        const { userid } = state.auth;
        const { _id } = payload;

        await api.post(
            `/users/${userid}/corpecosystemrelationships/${_id}/sendvalidationemail`,
            payload,
            {
                params: { entity: 'company' }
            }
        );
    }

    // ESSA FUNÇÃO NÃO SERÁ USADA, DE ACORDO COM AS MUDANÇAS DO DOC. VER O COMPONENT RELATIONSHIP, O QUE SERÁ FEITO NO DISPLAY DOS BOTÕES
    // SERÁ SOLICITADO A ALTERAÇÃO AO AGENTE DE ECOSISTEMA?
    async function putCorpEcosystemRelationship(payload) {
        // {
        //     "date": "2019-07-01",
        //     "type": "Angel",
        //     "round": "Seed 2",
        //     "vaue": {"currency": "USD", "amount": 20000 },
        //     "linkUrl": "https://linkao.com.br",
        //     "rankings": { "2020": false }
        // }
        const { userid } = state.auth;
        const { _id } = payload;

        // mudar rota, ver com levy
        await api.put(`/users/${userid}/corpecosystemrelationships/${_id}`, payload, {
            params: { entity: 'company' }
        });

        return getCorpEcosystemRelationships();
    }

    async function deleteCorpEcosystemRelationship(payload) {
        const { userid } = state.auth;
        const { _id } = payload;

        await api.delete(`/users/${userid}/corpecosystemrelationships/${_id}`, {
            data: { entity: 'company' }
        });

        return getCorpEcosystemRelationships();
    }

    /// /////////////////////////
    //  CORPORATE FUNCTIONS  //
    // //////////////////////

    async function getCompanyDetails(addResponseToVars = true) {
        if (!vars.companyId) {
            return null;
        }

        const companyDetails = await api.get(`/companies/${vars.companyId}`);

        if (!companyDetails) {
            RHP.push('/companies');
        }

        if (addResponseToVars) {
            addVars({ companyDetails });
        }

        return companyDetails;
    }

    async function putCompanyDetails(payload, addResponseToVars = true) {
        const companyDetails = await api.put(`/companies/${vars.companyId}`, payload);

        if (addResponseToVars) {
            addVars({ companyDetails });
        }

        return getCompanyDetails();
    }

    async function putCompanyLogo(payload) {
        const response = await api.put(`/admin/companies/${vars.companyId}/logo`, payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        await getCompanyDetails();

        return response;
    }

    // function getCorporateRankingApplication() {
    //     return api
    //         .get(`/companies/${vars.companyId}/rankingapplications/${vars.ranking.id}`)
    //         .then(res => {
    //             let application = res;
    //             if (!res.steps) {
    //                 application = initial.application;
    //             }
    //             addVars({ application });
    //             return res;
    //         });
    // }

    // function putCorporateRankingApplication(payload, updateVars = true) {
    //     // const companyId = user.company.id;

    //     return api
    //         .put(
    //             `/companies/${vars.companyId}/rankingapplications/${vars.currentRanking.id}`,
    //             payload
    //         )
    //         .then(res => {
    //             getCompanyDetails(vars.companyId);
    //             if (updateVars) addVars({ application: res });
    //             return res;
    //         });
    // }

    async function getCorpRankingPosition() {
        const companyEstimatedPosition = await api.get(
            `/companies/${vars.companyId}/rankingapplications/${vars.ranking.year}corps/estimation`
        );

        addVars({ companyEstimatedPosition });

        return companyEstimatedPosition;
    }

    useEffect(() => {
        if (vars.companyId) {
            Promise.all([getCompanyDetails()]).then(res => addVars({ companyDetails: res[0] }));
        }
    }, [vars.companyId]);

    useEffectOnce(() => {
        // Validate investiment if avaliable
        // Validation moved to useRanking()
        // Initialize the module's service
        getRanking()
            .then(res => {
                return Promise.all([
                    getUserRankingApplication(),
                    getCompanyDetails(),
                    getCompanyPocs(),
                    getCorpEcosystemRelationships(),
                    getUserContracts(res)
                ]).then(() => setReady(true));
            })
            .catch(err => {
                console.error('useRankingCorporate', err);
                return Promise.all([getUserRankingApplication(), getUserContracts()]).then(() =>
                    setReady(true)
                );
            });
    });

    return {
        t,
        // Ready to load?
        ready,
        // Variables handlers
        addVars,
        setVars,
        vars,

        calculateContractPoints,
        checkStartupCountry,
        deleteCorpEcosystemRelationship,
        deleteUserContract,
        getCompanyByEmail,
        getCompanyDetails,
        getCompanyPocs,
        getCorpEcosystemRelationships,
        getCorpRankingPosition,
        getProgramsByCompanyId,
        getRanking,
        getUserContractById,
        getUserContracts,
        getUserRankingApplication,
        postCorpEcosystemRelationship,
        postCorpEcosystemRelationshipValidation,
        postCorpEcosystemRelationshipValidationEmail,
        postPocsRequest,
        postProgramByCompanyId,
        postProgramCompany,
        postUserContract,
        postUserContractReconsideration,
        postUserContractValidation,
        postUserContractValidationEmail,
        putCompanyDetails,
        putCompanyLogo,
        putCorpEcosystemRelationship,
        putUserContract,
        putUserRankingApplication,
        setReturnTo,
        updateSteps
    };
}
export default useRankingCorporate;
