import React from 'react';

import { UserAvatar } from 'core/components/user/avatar/UserAvatar';
import { useTranslation } from 'react-i18next';
import * as Styled from './StyledRelationship';

export function RelationshipCard(props) {
    const data = props.data || props.relationship || undefined;
    const ecosystem = data.company.contact;
    const { t } = useTranslation('ranking');
    // No year found?
    if (!data.year) data.year = 'N/D';

    const bold = data.company.data?.name || data.company.name;
    const text = `${data.year} - ${data?.program?.name || data?.programName}`;

    const propsCard = {
        as: data.onClick ? 'button' : undefined,
        onClick: data.onClick
    };
    const badges = [];
    if (data?.company && data?.company.status) {
        if (data?.company.status === 'confirmed') {
            badges.push(() => <div className="badge badge-success">{t('ecosystemConfirmed')}</div>);
        }
        // if (data?.company.status === 'pending') {
        //     badges.push(() => <div className="badge badge-warning">{t('ecosystemPending')}</div>);
        // }
        if (data?.company.status === 'rejected') {
            badges.push(() => <div className="badge badge-danger">{t('ecosystemRejected')}</div>);
        }
    }
    if (data.startup && data.startup.status) {
        if (data.startup.status === 'confirmed') {
            badges.push(() => <div className="badge badge-success">{t('startupConfirmed')}</div>);
        }
        // if (data.startup.status === 'pending') {
        //     badges.push(() => <div className="badge badge-warning">{t('startupPending')}</div>);
        // }
        if (data.startup.status === 'rejected') {
            badges.push(() => <div className="badge badge-danger">{t('startupRejected')}</div>);
        }
    }
    if (data.audited && data.status && data.status === 'confirmed' && data.displayAudit === true) {
        if (data.audited === 'confirmed') {
            const cn = 'badge badge-success';
            badges.push(() => <div className={cn}>{t('auditConfirmed')}</div>);
        }
        // if (data.audited === 'pending') {
        //     const cn = 'badge badge-warning';
        //     badges.push(() => <div className={cn}>{t('auditPending')}</div>);
        // }
        if (data.audited === 'rejected') {
            const cn = 'badge badge-danger';
            badges.push(() => <div className={cn}>{t('auditRejected')}</div>);
        }
    }

    if (!data?.program.name) {
        const cn = 'badge badge-danger';
        badges.push(() => <div className={cn}>Desatualizado</div>);
    }

    return (
        <Styled.RelationshipCard {...propsCard}>
            <UserAvatar user={data.company.contact.data} />
            <Styled.CardInfos>
                <span className="name">{ecosystem.name}</span>
                <span className="bold">{bold}</span>
                <span className="text">{text}</span>

                {badges.map((Badge, k) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Badge key={`badge-${k}`} />
                ))}
            </Styled.CardInfos>
        </Styled.RelationshipCard>
    );
}
export default RelationshipCard;
