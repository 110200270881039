import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Alert from 'components/alert';

export function NextStep(props) {
    const { steps, useRankingStartup } = props;
    const { application, investments, relationships } = useRankingStartup.vars;
    const { t } = useTranslation('ranking');

    // Create the alert element props
    const alert = {
        // Show texts informations
        title: t('NextStep.title'),
        // Build the button
        buttn: {
            href: '/ranking',
            text: ''
        },
        // Setup color and presentation
        type: 'primary'
    };

    // Get the first false step
    const step = steps.filter(s => s.isCompleted === false)[0];

    if (step === undefined) {
        if (!application.applied) {
            alert.type = 'warning';
            alert.icons = 'far fa-exclamation-circle';
            alert.title = t('NextStep.completeStartup.title');
            alert.descr = t('NextStep.completeStartup.descr');
            alert.buttn = [
                {
                    href: '/ranking/latam/startup/step1',
                    type: 'light',
                    text: t('NextStep.completeStartup.apply')
                },
                {
                    href: '/ranking/latam/startup/step8',
                    text: t('NextStep.moreContracts')
                }
            ];
        } else {
            alert.type = 'success';
            alert.icons = 'far fa-check-circle';
            alert.title = t('NextStep.success.title');
            alert.descr = t('NextStep.success.keepRegistering');
            alert.buttn = {
                href: '/ranking/latam/startup/step8',
                text: t('NextStep.moreContracts')
            };
        }
    } else {
        alert.buttn = {
            href: step.href,
            text: step.text
        };
    }

    // Filter only pending relationships
    const pendingRelationship = relationships.filter(
        i => i.startup.status === 'pending' && i.status === 'pending'
    );

    // Check for relationship
    if (pendingRelationship) {
        if (steps[5].isCompleted && pendingRelationship && pendingRelationship.length > 0) {
            alert.buttn = {
                href: '/ranking/latam/startup/step7',
                text: steps[6].text
            };
            alert.title = t('NextStep.pendingEcosystemRelationships.title');
            alert.descr = t('NextStep.pendingEcosystemRelationships.descr');
            alert.icons = 'far fa-exclamation-circle';
            alert.type = 'warning';
        }
    }

    // Filter only pending investments
    const pendingInvestment = investments.filter(
        i => i.startup.status === 'pending' && i.status === 'pending'
    );

    // Check for investment
    if (steps[4].isCompleted && pendingInvestment && pendingInvestment.length > 0) {
        alert.buttn = {
            href: '/ranking/latam/startup/step6',
            text: steps[5].text
        };
        alert.title = t('NextStep.pendingInvestments.title');
        alert.descr = t('NextStep.pendingInvestments.descr');
        alert.icons = 'far fa-exclamation-circle';
        alert.type = 'warning';
    }

    return <Alert.Alert {...alert} />;
}
