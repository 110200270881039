import React from 'react';

import { useForm } from 'components/form';
import { useConversionOptions } from 'hooks';
import { RHP } from 'providers';
import * as Page from 'components/page';

import { useEffectOnce } from 'react-use';
import { RegisterIndexForm } from './RegisterIndexForm';

export function RegisterIndex(props) {
    const conv = useConversionOptions(['ecosystemRole']);
    const { vars, getCompanyDomain, postPrelogin, postUser } = props.useRegister;

    // Initialize a new form handler
    const form = useForm({
        referralcode: vars.referralcode,
        returnUrl: vars.returnUrl,
        email: vars.email
    });

    // Custom onBlur events
    const onBlur = {
        email: ev => {
            const { name, value } = ev.target;
            // Clear the errors and warnings before validating
            form.clearMessages({ name });
            // Only check if the value is filled
            if (!value) return false;
            // Check if the domain is a valid one
            getCompanyDomain(value)
                .then(res => {
                    return postPrelogin({ email: value }).then(prelogin => {
                        if (prelogin) {
                            // Emit error message for registered email
                            const error = props.t('glossary:emailRegisteredError');
                            return form.emitError({ name, error });
                        }
                        // Emit warning message
                        const warning = props.t('glossary:emailPersonalWarning');
                        return !res.validdomain && form.emitWarning({ name, warning });
                    });
                })
                .catch(err => {
                    // Emit error message for invalid email
                    const error = props.t('glossary:emailInvalid');
                    return form.emitError({ name, error });
                });
            return true;
        }
    };

    // Custom onChange events
    const onChange = {
        email: ev => {
            const error = props.t('glossary:emailCheckError');
            const name = 'emailCheck';
            form.hasEqualValues(ev, 'email', { name, error });
        },
        emailCheck: ev => {
            const error = props.t('glossary:emailCheckError');
            form.hasEqualValues(ev, 'email', { error });
        }
    };

    // Handle form submition
    const onSubmit = values => {
        const payload = values;
        // Do the submition api request
        return postUser(payload)
            .then(() => RHP.push('/register/location'))
            .catch(() => form.submited());
    };

    // Run this if the email is received from /login
    useEffectOnce(() => {
        if (vars.email) {
            onBlur.email({ target: { name: 'email', value: vars.email } });
        }
    });

    const propsForm = { ...props, conv, form, onBlur, onChange, onSubmit };
    return (
        <Page.Page name="RegisterIndex" back="/login">
            <Page.Title>{props.t('RegisterIndex.title')}</Page.Title>
            <Page.Descr>{props.t('RegisterIndex.descr')}</Page.Descr>

            <RegisterIndexForm {...propsForm} />
        </Page.Page>
    );
}
export default RegisterIndex;
