import React, { useContext, useState } from 'react';

import { useForm } from 'components/form';
import * as Page from 'components/page';
import { RHP } from 'providers';

import { CG } from 'contexts';
import { useParams } from 'react-router';
import { StartupStep7EditForm } from './edit-form';

export function StartupStep7Edit(props) {
    const { id } = useParams();

    const { emitAlert } = useContext(CG);

    const {
        vars,
        putStartupRelationship,
        postProgramByCompanyId,
        getProgramsByCompanyId
    } = props.useRankingStartup;

    const { ranking } = vars;

    const [changed, setChanged] = useState({});

    const relationship = vars.relationships.find(i => i._id === id);

    if (!relationship) {
        RHP.goBack();
    }

    // Initialize a new form handler
    const form = useForm({
        ...relationship,
        companyName: relationship?.company?.contact?.name,
        programName: {
            label: relationship.program.name || relationship?.programName,
            value: relationship.program._id || relationship?.programName
        },
        isConfirmed: !!(relationship?.company && relationship?.company?.status === 'confirmed')
    });

    // Handle form changes
    const onBlur = {
        companyName: () => changed.companyName && setChanged({ companyName: false }),
        year: () => changed.year && setChanged({ year: false })
    };

    // Handle form changes
    const onChange = {
        companyName: () => setChanged({ companyName: true }),
        year: () => setChanged({ year: true })
    };

    // Handle form submitions
    const onSubmit = async values => {
        try {
            const companyPrograms = await getProgramsByCompanyId(
                relationship?.companyId || relationship.startupId
            );

            let program = companyPrograms?.find(e => e?._id === values.programName.value);

            if (
                values?.programName?.__isNew__ ||
                !companyPrograms?.some(e => e?.name?.includes(values.programName.label))
            ) {
                program = await postProgramByCompanyId(
                    values.programName.label,
                    relationship.companyId || relationship.startupId
                );
            }

            delete program?.regDate;
            delete program?.companies;

            const payload = {
                _id: values._id,
                entity: 'startup',
                year: values.year,
                programName: values.programName.label,
                description: values.description,
                linkUrl: values.linkUrl,
                ranking
            };

            if (program) {
                payload.program = program;
            }

            await putStartupRelationship(payload);

            RHP.push('/ranking/latam/startup/step7');
        } catch (err) {
            emitAlert({
                message: `Ocorreu um erro inesperado, tente novamente mais tarde. Erro: ${err.message}`,
                header: 'Ops...'
            });
        } finally {
            form.submited();
        }
    };

    const propsForm = { ...props, form, onBlur, onChange, onSubmit };

    return (
        <Page.Page name="StartupStep7Edit" customBackText={props.t('glossary:backToRanking')}>
            <Page.Title>{props.t('StartupStep7Edit.title')}</Page.Title>
            <Page.Descr>{props.t('StartupStep7Edit.descr')}</Page.Descr>

            <StartupStep7EditForm {...propsForm} relationship={relationship} />
        </Page.Page>
    );
}
