import styled from 'styled-components';
import { positions } from 'utils/styles';

const circle = `
    background-color: var(--os-startups);
    border-radius: 50%;
    border: 0;
    color: #fff;
    font-size: 1.6rem;
    height: 4.4rem;
    position: fixed;
    ${positions('auto', '1rem', '1rem', 'auto')}
    width: 4.4rem;
`;

export const RoutesMenu = styled.div.attrs(props => {
    const o = props.open ? 'o open' : '';
    const v = props.visible ? 'v visible' : '';
    return { className: `routes-menu ${o} ${v}` };
})`
    &.o .routes-menu-toggler {
        background-color: var(--primary-darken);
    }
    &.o .routes-menu-wrapper {
        display: block;
    }
    &.o .routes-menu-ellipse::before {
        height: 100vmax;
        transform: scale(2) translate(-50%, -50%);
        width: 100vmax;
    }

    &.o.v .routes-menu-wrapper {
        opacity: 1;
        transition-delay: 0.3s;
    }
`;

export const RoutesMenuEllipse = styled.div.attrs({
    className: 'routes-menu-ellipse',
})`
    ${circle}
    z-index: 1210;

    &::before {
        ${circle}
        background: var(--secondary);
        content: '';
        height: 1rem;
        position: absolute;
        ${positions('50%', 'auto', 'auto', '50%')}
        transform: translate(-50%, -50%);
        transition: all 0.3s ease;
        width: 1rem;
        z-index: 1220;
    }
`;

export const RoutesMenuToggler = styled.div.attrs({
    className: 'routes-menu-toggler',
})`
    ${circle}
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 1240;

    .icon {
        transform: translate(1px, 1px);
    }
`;

export const RoutesMenuWrapper = styled.div.attrs({
    className: 'routes-menu-wrapper background',
})`
    background: var(--secondary);
    color: #fff;
    display: none;
    opacity: 0;
    position: fixed;
    ${positions(0, 0, 0, 0)}
    transition: all 0.3s ease;
    transition-delay: 0s;
    z-index: 1230;

    ion-content {
        --background: transparent;
        --color: #fff;
    }
`;
