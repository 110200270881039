import { useConversionOptions, useNewConversionOptions } from 'hooks';
import React from 'react';
import * as Form from 'components/form';

import { ButtonsForm } from '../components/buttons-form';
import { ProgressBar } from '../components/progress-bar';
import * as Styled from './StyledStep4';

export function Step4Form({ form, onSubmit, t }) {
    const CURRENT_STEP = 4;
    const conv = useNewConversionOptions(['challenge', 'industry', 'trend', 'community']);

    const fields = {
        challenges: {
            name: 'challenges',
            type: 'checkboxes',
            value: form.values.challenges,
            onChange: form.handleChange,
            options: [...conv.challenge]
        },
        industries: {
            name: 'industries',
            type: 'checkboxes',
            value: form.values.industries,
            onChange: form.handleChange,
            options: [...conv.industry]
        },
        trends: {
            name: 'trends',
            type: 'checkboxes',
            value: form.values.trends,
            onChange: form.handleChange,
            options: [...conv.trend]
        },
        communities: {
            name: 'communities',
            type: 'checkboxes',
            value: form.values.communities,
            onChange: form.handleChange,
            options: [...conv.community]
        }
    };

    const evSubmit = ev => form.handleSubmit(ev, onSubmit);

    return (
        <Styled.Step4>
            <ProgressBar current={CURRENT_STEP} />

            <Styled.Title>
                {t('form.step4.title')}
                {/* <Styled.Project> 2500 Projects </Styled.Project> */}
            </Styled.Title>

            <Styled.Subtitle>{t('form.step4.descr')}</Styled.Subtitle>

            <Form.Form onSubmit={evSubmit}>
                <Styled.Questions>{t('form.step4.question1')}</Styled.Questions>
                <Styled.Block>
                    <Form.Field {...fields.challenges} />
                </Styled.Block>

                <Styled.Questions>{t('form.step4.question2')}</Styled.Questions>
                <Styled.Block>
                    <Form.Field {...fields.industries} />
                </Styled.Block>

                <Styled.Questions>{t('form.step4.question3')}</Styled.Questions>
                <Styled.Block>
                    <Form.Field {...fields.trends} />
                </Styled.Block>

                <Styled.Questions>{t('form.step4.question4')}</Styled.Questions>
                <Styled.Block>
                    <Form.Field {...fields.communities} />
                </Styled.Block>

                <br />

                <ButtonsForm currentStep={CURRENT_STEP} />
            </Form.Form>
        </Styled.Step4>
    );
}
