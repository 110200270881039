import styled from 'styled-components';

import bg from 'assets/images/bg-oiweek.jpg';

export const OiweekWidget = styled.div.attrs(props => {
    return { className: 'oiweek-widget' };
})`
    background: transparent no-repeat center center;
    background-image: url(${props => (props.bgUrl ? props.bgUrl : bg)});
    background-size: cover;
    color: #fff;

    & > .c-alert-image {
        background: #fff;
        border-radius: 1rem;
        margin: 0;
        padding: 0.5rem;
    }

    .oiweek-call2action {
        margin-top: 2rem !important;
    }
`;
