import React from 'react';

import { useForm } from 'components/form';
import { useConversionOptions } from 'hooks';
import { RHP } from 'providers';
import * as Page from 'components/page';

import { StartupStep6AddForm } from './add-form';

export function StartupStep6Add(props) {
    const conv = useConversionOptions(['investmentType']);
    const { vars, postStartupInvestment } = props.useRankingStartup;
    const { ranking } = vars;
    // const [registerInvestmentAsContract, setRegisterInvestmentAsContract] = useState(false);

    // Initialize a new form handler
    const form = useForm({
        investmentValue: { currency: '', amount: '' },
        otherContact: false,
        rankingIndication: true
    });

    // Check for previous step conclusion
    if (!vars.application.steps || vars.application.steps.step5 !== true) {
        RHP.replace('/ranking/latam/startup/');
        return null;
    }

    // function RegisterInvestmentAsContract(state) {
    //     setRegisterInvestmentAsContract(state);
    // }

    // Handle form submitions
    const onSubmit = values => {
        const date = `${values.date.substring(0, 7)}-01T00:00:00.000+0000`;

        const payload = {
            date,
            type: values.type,
            round: values.round,
            value: values.investmentValue,
            ranking,
            investor: {
                name: values.investorName,
                email: values.investorEmail
            }
        };
        // Add CompanyName to investor
        if (values.companyName) {
            payload.investor = { ...payload.investor, companyName: values.companyName };
        }
        // Remove value if the amount is empty
        if (!values.investmentValue.amount) {
            payload.value = undefined;
        }

        // REGISTER AS CONTRACT
        // if (registerInvestmentAsContract) {
        //     const contractPayload = {
        //         // qual será o payload? são dados diferentes que o backend requer
        //     };
        //     postStartupContract(contractPayload).then(res)
        // }
        return postStartupInvestment(payload)
            .then(() => RHP.push('/ranking/latam/startup/step6'))
            .catch(() => form.submited());
    };

    const propsForm = { ...props, conv, form, onSubmit };
    return (
        <Page.Page name="StartupStep6Add">
            <Page.Title>{props.t('StartupStep6Add.title')}</Page.Title>
            <Page.Descr>{props.t('StartupStep6Add.descr')}</Page.Descr>

            <StartupStep6AddForm {...propsForm} />
        </Page.Page>
    );
}
