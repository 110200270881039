import React from 'react';
import { useTranslation } from 'react-i18next';

import * as Modal from 'components/modal';

import * as Styled from './styles';

export function ContractTypesModal(props) {
    const { t } = useTranslation('ranking');

    const ModalContent = () => {
        return (
            <Styled.ContractTypesModal>
                {/* <Styled.GroupTitle>{t('ContractTypesModal.noPoints')}</Styled.GroupTitle> */}
                <ol start="0">
                    <Styled.GroupSubtitle>
                        {t('ContractTypesModal.connection')}
                    </Styled.GroupSubtitle>
                    <Styled.SubtitleParag>
                        {t('ContractTypesModal.connectionParag')}
                    </Styled.SubtitleParag>
                </ol>
                <Styled.GroupTitle>{t('ContractTypesModal.groupA')}</Styled.GroupTitle>
                <ol start="1">
                    <Styled.GroupSubtitle>{t('ContractTypesModal.mentoring')}</Styled.GroupSubtitle>
                    <Styled.SubtitleParag>
                        {t('ContractTypesModal.mentoringParag')}
                    </Styled.SubtitleParag>

                    <Styled.GroupSubtitle>{t('ContractTypesModal.award')}</Styled.GroupSubtitle>
                    <Styled.SubtitleParag>
                        {t('ContractTypesModal.awardParag')}
                    </Styled.SubtitleParag>
                    <Styled.GroupSubtitle>{t('ContractTypesModal.coworking')}</Styled.GroupSubtitle>
                    <Styled.SubtitleParag>
                        {t('ContractTypesModal.coworkingParag')}
                    </Styled.SubtitleParag>
                    <Styled.GroupSubtitle>{t('ContractTypesModal.voucher')}</Styled.GroupSubtitle>
                    <Styled.SubtitleParag>
                        {t('ContractTypesModal.voucherParag')}
                    </Styled.SubtitleParag>
                </ol>

                <Styled.GroupTitle>{t('ContractTypesModal.groupB')}</Styled.GroupTitle>
                <ol start="5">
                    <Styled.GroupSubtitle>
                        {t('ContractTypesModal.outlicensing')}
                    </Styled.GroupSubtitle>
                    <Styled.SubtitleParag>
                        {t('ContractTypesModal.outlicensingParag')}
                    </Styled.SubtitleParag>
                    <Styled.GroupSubtitle>{t('ContractTypesModal.resources')}</Styled.GroupSubtitle>
                    <Styled.SubtitleParag>
                        {t('ContractTypesModal.resourcesParag')}
                    </Styled.SubtitleParag>
                    <Styled.GroupSubtitle>{t('ContractTypesModal.employee')}</Styled.GroupSubtitle>
                    <Styled.SubtitleParag>
                        {t('ContractTypesModal.employeeParag')}
                    </Styled.SubtitleParag>
                    <Styled.GroupSubtitle>{t('ContractTypesModal.channels')}</Styled.GroupSubtitle>
                    <Styled.SubtitleParag>
                        {t('ContractTypesModal.channelsParag')}
                    </Styled.SubtitleParag>
                    <Styled.GroupSubtitle>
                        {t('ContractTypesModal.acceleration')}
                    </Styled.GroupSubtitle>
                    <Styled.SubtitleParag>
                        {t('ContractTypesModal.accelerationParag')}
                    </Styled.SubtitleParag>
                </ol>

                <Styled.GroupTitle>{t('ContractTypesModal.groupC')}</Styled.GroupTitle>
                <ol start="10">
                    <Styled.GroupSubtitle>{t('ContractTypesModal.r&d')}</Styled.GroupSubtitle>
                    <Styled.SubtitleParag>{t('ContractTypesModal.r&dParag')}</Styled.SubtitleParag>
                    <Styled.GroupSubtitle>
                        {t('ContractTypesModal.inlicensing')}
                    </Styled.GroupSubtitle>
                    <Styled.SubtitleParag>
                        {t('ContractTypesModal.inlicensingParag')}
                    </Styled.SubtitleParag>
                    <Styled.GroupSubtitle>{t('ContractTypesModal.pilot')}</Styled.GroupSubtitle>
                    <Styled.SubtitleParag>
                        {t('ContractTypesModal.pilotParag')}
                    </Styled.SubtitleParag>
                    <Styled.GroupSubtitle>{t('ContractTypesModal.supplying')}</Styled.GroupSubtitle>
                    <Styled.SubtitleParag>
                        {t('ContractTypesModal.supplyingParag')}
                    </Styled.SubtitleParag>
                </ol>

                <Styled.GroupTitle>{t('ContractTypesModal.groupD')}</Styled.GroupTitle>
                <ol start="14">
                    <Styled.GroupSubtitle>
                        {t('ContractTypesModal.investment')}
                    </Styled.GroupSubtitle>
                    <Styled.SubtitleParag>
                        {t('ContractTypesModal.investmentParag')}
                    </Styled.SubtitleParag>
                    <Styled.GroupSubtitle>
                        {t('ContractTypesModal.acquisition')}
                    </Styled.GroupSubtitle>
                    <Styled.SubtitleParag>
                        {t('ContractTypesModal.acquisitionParag')}
                    </Styled.SubtitleParag>
                </ol>
            </Styled.ContractTypesModal>
        );
    };

    return (
        <Modal.Modal {...props}>
            <ModalContent />
        </Modal.Modal>
    );
}
