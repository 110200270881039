import React from 'react';

import * as List from 'components/list';
import * as Page from 'components/page';

import * as Styled from './StyledStartupIdIndex';

export const StartupIdIndexView = props => {
    const { vars } = props.useStartupId;

    return (
        <Styled.StartupIdIndex as={Page.Page} name="StartupIdIndex" back="/home">
            <Styled.Image img={vars.startup.logoUrl} />

            <Page.Title>{props.t('StartupIdIndex.defaultTitle')}</Page.Title>
            <Page.Descr>
                {props.t('StartupIdIndex.descr', { startupName: vars.startup.name })}
            </Page.Descr>

            <List.List name="StartupIdIndexActions">
                <List.Vertical items={props.actions} itemKey="name" />
                <List.Vertical items={props.profiles} itemKey="name" />
            </List.List>
        </Styled.StartupIdIndex>
    );
};

export default StartupIdIndexView;
