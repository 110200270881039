import React from 'react';
import { FormInputSelectOptions } from './FormInputSelectOptions';

export const FormInputSelectGroups = ({ optgroups, input }) =>
    optgroups.length &&
    optgroups.map((g, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <optgroup key={`optgroup-${input.name}-${i}`} label={g.label}>
            <FormInputSelectOptions input={input} options={g.options} />
        </optgroup>
    ));
export default FormInputSelectGroups;
