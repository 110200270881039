import React, { useState } from 'react';
import { useCXI } from 'modules/cxi/useCXI';
import { UserAvatar } from 'core/components/user';
import { Button } from 'modules/cxi/components/button';
import { Container } from '../container';

import * as Styled from './StyledChallengeForm';

export const ChallengeForm = () => {
    const { modal, setModal } = useCXI();
    const { startup } = modal;

    const handleClose = () => {
        setModal(null);
    };

    return (
        <Styled.ChallengeForm as={Container.Slim}>
            <Styled.Avatar>
                <UserAvatar user={{ firstName: startup?.name, photoUrl: startup?.logoUrl }} />
            </Styled.Avatar>

            <Container.Body>
                <Styled.Title>Pedido de abertura de desafio enviado!</Styled.Title>
                <br />
                <Styled.SubTitle>
                    Nosso time irá analisar o seu pedido antes de lançá-lo às startups. O tempo
                    estimado de retorno é de <strong>2 dias úteis</strong>.
                </Styled.SubTitle>
            </Container.Body>

            <Container.Feet>
                <Button variant="blue" onClick={handleClose}>
                    Fechar
                </Button>
            </Container.Feet>
        </Styled.ChallengeForm>
    );
};
