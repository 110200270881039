import React from 'react';
import { AboutUser } from 'modules/ranking/ranking-latam/_corporate/components/user/AboutUser';
import logoRanking from 'assets/images/logo-ranking-2022.svg';

import * as List from 'components/list';
import * as Page from 'components/page';
import * as Alert from 'components/alert';

import { ContextGlobal as CG } from 'contexts';
import { Pocs } from '../components/user/button';

import { NextStep } from './next-step';

const country = {
    pt: 'Brasil',
    en: 'Brazil',
    es: 'Brasil'
};

export const CorporateIndexView = props => {
    const { state } = React.useContext(CG);
    const { vars } = props.useRankingCorporate;

    const isCompanySmall = vars?.companyDetails?.size === 1 || vars?.companyDetails?.size === 2;
    const revenuesMinValue = vars?.companyDetails?.revenuesRange?.[1];
    const corpDontMeetRequirements = isCompanySmall && Number(revenuesMinValue) <= 20000000;

    const propsCorpDoesntMeetRequirements = {
        descr: props.t('CorporateStep2.corpDoesntMeetRequirements'),
        icons: 'far fa-exclamation-circle',
        type: 'warning',
        bgColor: '#f44336',
        color: 'white'
    };

    return (
        <Page.Page
            name="CorporateIndex"
            back="/ranking/latam"
            customBackText={props.t('glossary:backToRanking')}
        >
            <Page.Image>
                <img src={logoRanking} alt="Logo Ranking 100 Open Startups" width="40%" />
            </Page.Image>

            <Page.Title>
                {props.t('CorporateIndex.title', { country: country[state.language] })}
            </Page.Title>
            <Page.Descr>{props.t('CorporateIndex.descr')}</Page.Descr>

            <List.List name="CorporateIndexSteps">
                <List.Steps items={props.steps} itemKey="step" />
            </List.List>

            <NextStep {...props} />

            {corpDontMeetRequirements && <Alert.AlertRow {...propsCorpDoesntMeetRequirements} />}

            <Page.Ruler type="dots" />

            <AboutUser useRankingCorporate={props.useRankingCorporate} />
            <Pocs useRankingCorporate={props.useRankingCorporate} />

            <List.List name="CorporateIndexActions">
                <List.Vertical items={props.actions} itemKey="id" />
            </List.List>
        </Page.Page>
    );
};
