import React from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from 'modules/cxi/components/button';
import { RHP } from 'providers';
import { StartupImage } from 'core/components/startup';
import { UserAvatar } from 'core/components/user';
import { useUser } from 'hooks';
import { useRankingStartup } from '../../useRankingStartup';
import * as Styled from './styledAboutUser';

export const AboutUser = () => {
    const user = useUser();
    const { vars } = useRankingStartup();
    const { t } = useTranslation('glossary');
    const { startupDetails } = vars;

    return (
        <Styled.About>
            <Styled.Wrapper className="user">
                <Styled.UserProfile>{t('yourProfile')}</Styled.UserProfile>
                <Styled.AboutBody className="user">
                    <div className="user">
                        <Styled.UserLogo>
                            <UserAvatar />
                            <StartupImage data={startupDetails} />
                        </Styled.UserLogo>

                        <div className="infos">
                            <span className="name">{user.fullName}</span>
                            <span className="comp">
                                <strong style={{ fontWeight: 500 }}>{startupDetails.name}</strong> -{' '}
                                {user.position}
                            </span>
                            <span className="post">{user.email}</span>
                        </div>
                    </div>
                    <Button
                        className="about-button"
                        variant="blue"
                        size="medium"
                        onClick={() => RHP.push('/profile')}
                    >
                        <span className="text">{t('edit')}</span>
                    </Button>
                </Styled.AboutBody>
            </Styled.Wrapper>
        </Styled.About>
    );
};
