import styled from 'styled-components';

export const MenuListModules = styled.ul.attrs({
    className: 'menu-list-modules',
})`
    margin-top: 0;
    padding-top: 4rem;
    ion-item + ion-item {
        margin-top: 2rem;
    }
`;

export const MenuListModulesItem = styled.a.attrs({
    className: 'menu-list-modules-item',
})`
    align-items: center;
    color: inherit;
    display: flex;
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 2.4rem;
    justify-content: flex-start;
    width: 100%;

    .icon {
        margin: 0 1.5rem 0 0;
        text-align: center;
    }
    small {
        display: inline-block;
        font-size: 1.3rem;
        font-weight: 400;
        margin: 0.5rem 0 0 1rem;
    }
`;
