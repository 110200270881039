import React from 'react';
import { Redirect, Route as ReactRoute, Switch } from 'react-router-dom';

// eslint-disable-next-line import/no-cycle
import * as Ranking from './index';

export const routes = [
    {
        exact: true,
        path: '/ranking/latam/',
        component: Ranking.RankingIndex,
    },

    // Ranking submodules
    {
        exact: false,
        icon: 'far fa-user',
        name: 'Startup',
        path: '/ranking/latam/startup',
        signed: false,
        component: Ranking.RankingStartup,
    },
    {
        exact: false,
        icon: 'far fa-user-tie',
        name: 'Corporate',
        path: '/ranking/latam/corporate',
        signedRecently: true,
        component: Ranking.RankingCorporate,
    },
    {
        exact: false,
        icon: 'far fa-user-chart',
        name: 'Investor',
        path: '/ranking/latam/investor',
        signed: true,
        component: Ranking.RankingInvestor,
    },
    {
        exact: false,
        icon: 'far fa-users',
        name: 'Ecosystem',
        path: '/ranking/latam/ecosystem',
        signed: true,
        component: Ranking.RankingEcosystem,
    },

    // Ranking pages outside the default modules
    // This page applies to all the modules
    {
        exact: true,
        path: '/ranking/latam/term',
        component: Ranking.RankingTerm,
    },
];

const RouteSigned = props => {
    const { vars, setReturnTo } = props.props.useRanking;
    if (vars.userTerm.signed !== true) {
        setReturnTo(props.path);
        const propsRedir = { pathname: '/ranking/latam/term', search: `?returnUrl=${props.path}` };
        return <ReactRoute component={() => <Redirect to={propsRedir} />} />;
    }
    return <ReactRoute {...props} />;
};

export const RankingRoutes = props => (
    <Switch>
        {routes.map(r => {
            const Component = r.component || React.createComponent(r.toRender);
            const Route = r.signed ? RouteSigned : ReactRoute;
            const routeProp = { ...r, props, component: undefined };
            return <Route key={r.path} {...routeProp} render={() => <Component {...props} />} />;
        })}
    </Switch>
);
export default RankingRoutes;
