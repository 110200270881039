import React, { useEffect, useState } from 'react';

import tooltip from 'assets/images/icons/tooltipRanking.png';
import { useCXI } from 'modules/cxi/useCXI';
import { useParams } from 'react-router';
import { RHP } from 'providers';

import { FilterTag } from '../components/filter-tag/FilterTag';

import * as Styled from './StyledCXIChallengeProfile';
import { InscribedStartup } from './registered/inscribed/InscribedStartup';
import { CXIChallengeCard } from './components/challenge-card/ChallengeCard';
import { CXIChallengesProfileFilters } from './components/profile-filter-general/CXIChallengesProfileFilters';

export const CXIChallengeProfile = () => {
    const { challengeId } = useParams();
    const { t, vars, getCXIEvents, getChallengerAdmins } = useCXI();
    const { startups } = vars;

    const [inQuestionTooltip, setInQuestionTooltip] = useState(false);
    const [applicantTooltip, setApplicantTooltip] = useState(false);
    const [selectedTooltip, setSelectedTooltip] = useState(false);
    const [filedTooltip, setFiledTooltip] = useState(false);
    const [challengerAdmins, setChallengerAdmins] = useState();
    const [displayedStartups, setDisplayedStartups] = useState();
    const [filters, setFilters] = useState([]);

    useEffect(() => {
        async function getStartups() {
            const filtersString = filters?.map(filter => `${encodeURIComponent(filter)}`);
            const startups = await getCXIEvents(challengeId, filtersString);

            setDisplayedStartups(startups);
        }

        getStartups();
    }, [challengeId, filters]);

    const hasFilter = value => {
        return Array.isArray(filters) && filters.includes(value);
    };

    const updFilter = values => {
        const updatedFilters = Array.isArray(values) ? values : [values];
        const filtersToUpdate = [];

        updatedFilters.forEach(value => {
            const filterIndex = filters.indexOf(value);

            if (filterIndex === -1) {
                filtersToUpdate.push(value);
            } else {
                setFilters(prev => prev.filter(filter => filter !== value));
            }
        });

        setFilters([
            ...filters.filter(filter => !updatedFilters.includes(filter)),
            ...filtersToUpdate
        ]);
    };

    const handleButtonClick = () => {
        RHP.push(`/100-10-1/challenges`);
    };

    useEffect(() => {
        if (challengeId) {
            getChallengerAdmins(challengeId).then(admins => {
                setChallengerAdmins(admins);
            });
        }
    }, [challengeId]);

    return (
        <Styled.CXIChallengeProfile>
            <button
                type="button"
                className="icon far fa-arrow-left"
                onClick={() => handleButtonClick()}
                style={{
                    border: 'none',
                    color: 'black',
                    background: 'transparent'
                }}
            />
            <br />
            <br />

            <CXIChallengeCard />

            <CXIChallengesProfileFilters />

            <Styled.Filter>
                <p> {t('challenge.filter')} </p>

                <FilterTag active={hasFilter('applied')} onClick={() => updFilter('applied')}>
                    {t('challenge.tab.applicant')}
                    <div style={{ position: 'relative' }}>
                        <img
                            src={tooltip}
                            onMouseOver={() => setApplicantTooltip(true)}
                            onMouseLeave={() => setApplicantTooltip(false)}
                            alt="tooltip"
                        />
                        {applicantTooltip && (
                            <Styled.Tooltip>
                                <h1>{t('challenge.tab.applicantTooltip')}</h1>
                            </Styled.Tooltip>
                        )}
                    </div>
                </FilterTag>
                <FilterTag
                    active={hasFilter('selected', 'registered', 'attended')}
                    onClick={() => updFilter(['selected', 'registered', 'attended'])}
                >
                    {t('challenge.tab.selected')}
                    <div style={{ position: 'relative' }}>
                        <img
                            src={tooltip}
                            onMouseOver={() => setSelectedTooltip(true)}
                            onMouseLeave={() => setSelectedTooltip(false)}
                            alt="tooltip"
                        />
                        {selectedTooltip && (
                            <Styled.Tooltip>{t('challenge.tab.selectedTooltip')}</Styled.Tooltip>
                        )}
                    </div>
                </FilterTag>

                <FilterTag active={hasFilter('inprogress')} onClick={() => updFilter('inprogress')}>
                    {t('challenge.tab.inQuestions')}
                    <div style={{ position: 'relative' }}>
                        <img
                            src={tooltip}
                            onMouseOver={() => setInQuestionTooltip(true)}
                            onMouseLeave={() => setInQuestionTooltip(false)}
                            alt="tooltip"
                        />
                        {inQuestionTooltip && (
                            <Styled.Tooltip>{t('challenge.tab.inQuestionsTooltip')}</Styled.Tooltip>
                        )}
                    </div>
                </FilterTag>

                <FilterTag active={hasFilter('rejected')} onClick={() => updFilter('rejected')}>
                    {t('challenge.tab.filed')}
                    <div style={{ position: 'relative' }}>
                        <img
                            src={tooltip}
                            onMouseOver={() => setFiledTooltip(true)}
                            onMouseLeave={() => setFiledTooltip(false)}
                            alt="tooltip"
                        />
                        {filedTooltip && (
                            <Styled.Tooltip>{t('challenge.tab.filedTooltip')}</Styled.Tooltip>
                        )}
                    </div>
                </FilterTag>
            </Styled.Filter>

            <Styled.Subtitle>
                <strong>{t('challenge.title3')}</strong>
                <p>( {startups?.length > 0 ? `${startups?.length}` : `0`} Startups )</p>
            </Styled.Subtitle>

            {displayedStartups && displayedStartups?.length > 0 && (
                <InscribedStartup filters={filters} />
            )}
        </Styled.CXIChallengeProfile>
    );
};

export default CXIChallengeProfile;
