import React from 'react';

import logoOiweek from 'assets/images/logo-oiweek-digital.png';

import * as Alert from 'components/alert';

import bg from 'assets/images/bg-oiweek.jpg';
import { useOiweek } from '../../useOiweek';
import { OiweekCall2Action } from '../call2action/OiweekCall2Action';
import * as Styled from './StyledOiweekWidget';

export function OiweekWidget() {
    const hookd = useOiweek(true);
    const { oiweek } = hookd.vars;

    // Create the alert element props
    const propsAlert = {
        // Set the image to be shown
        image: {
            src: oiweek.logoUrl || logoOiweek,
            alt: 'Logotipo Oiweek Digital'
        },
        // Setup color and presentation
        type: 'primary'
    };

    // Check if oiweek is available and if is still running
    // return !oiweek || (oiweek && new Date() > new Date(oiweek.endDate)) ? null : (
    return hookd.ready === false || !oiweek ? null : (
        // <Styled.OiweekWidget as={Alert.Alert} bgUrl={oiweek.bgUrl} {...propsAlert}>
        <Styled.OiweekWidget as={Alert.Alert} bgUrl={bg} {...propsAlert}>
            <OiweekCall2Action useOiweek={hookd} />
        </Styled.OiweekWidget>
    );
}
export default OiweekWidget;
