import React from 'react';

import { RHP } from 'providers';
import { useForm } from 'components/form';

import * as Form from 'components/form';
import * as Page from 'components/page';
import logoOpenAngels from 'assets/images/logo-100-open-angels.png';

import { Ruler } from 'components/page/ruler/PageRuler';
import * as Styled from './styles';

import { StartupStep5Form } from './step5-form';

export function StartupStep5(props) {
    const { vars, putStartupDetails, updateSteps } = props.useRankingStartup;
    const { startupDetails } = vars;

    // Initialize a new form handler
    const form = useForm({
        financial: startupDetails.scaleup.financial,
        openAngelsInterest: String(startupDetails.investmentProfile.applied),
        isFundingRoundOpen:
            startupDetails.demographics.financial.seekingInvestment === 1 ? 'true' : 'false',
        seekingHowMuch: startupDetails.demographics.financial.seekingHowMuch
    });

    // Check for previous step conclusion
    if (!vars.application.steps || vars.application.steps.step4 !== true) {
        RHP.replace('/ranking/latam/startup/');
        return null;
    }

    // Handle form submitions
    const onSubmit = values => {
        const payload = {
            demographics: {
                financial: {
                    seekingInvestment: values.isFundingRoundOpen === 'true' ? 1 : 2,
                    seekingHowMuch: values.seekingHowMuch
                }
            },
            investmentProfile: { applied: values.openAngelsInterest === 'true' },
            scaleup: {
                financial: values.financial
                    .filter(elem => elem.revenues || elem.employees)
                    .sort((a, b) => a.year - b.year)
            },
            language: startupDetails.language
        };

        return putStartupDetails(payload)
            .then(() => updateSteps({ step5: true }))
            .then(() => RHP.push('/ranking/latam/startup/step6'))
            .catch(() => form.submited());
    };

    const fields = {
        openAngelsInterest: {
            error: form.errors.openAngelsInterest,
            label: props.t('StartupStep5.openAngelsInterest'),
            name: 'openAngelsInterest',
            type: 'YesNo',
            value: form.values.openAngelsInterest,
            onChange: form.handleChange
        }
    };

    const propsForm = { ...props, form };

    const evSubmit = ev => form.handleSubmit(ev, onSubmit);
    return (
        <Page.Page
            name="StartupStep5"
            back="/ranking/latam/startup"
            customBackText={props.t('glossary:backToRanking')}
        >
            <Styled.StartupStep5>
                <Page.Title>{props.t('StartupStep5.title')}</Page.Title>

                <div className="text-and-image">
                    <div>
                        <span className="text">{props.t('StartupStep5.descr')} </span>
                        <Page.LWTXT
                            as="a"
                            target="_blank"
                            href="https://100os.net/openangels-startups"
                        >
                            <span className="link">Open Angels</span>
                        </Page.LWTXT>
                        <p className="descr2">{props.t('StartupStep5.descr2')}</p>
                    </div>
                    <img
                        className="ranking-logo"
                        width={80}
                        src={logoOpenAngels}
                        alt="100 Open Angels"
                    />
                </div>

                <Form.Form name="StartupStep5Form" onSubmit={evSubmit}>
                    <Form.Field {...fields.openAngelsInterest} />

                    {form.values.openAngelsInterest === 'true' && (
                        <>
                            <Ruler />
                            <StartupStep5Form {...propsForm} />
                        </>
                    )}
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <Page.Buttn style={{ width: '25%' }} to="/ranking/latam/startup/step4">
                            <span className="icon far fa-long-arrow-left" aria-hidden="true" />
                            <span className="text" style={{ margin: '0 0 0 auto' }}>
                                {props.t('glossary:back')}
                            </span>
                        </Page.Buttn>
                        <div style={{ width: '80%' }}>
                            <Form.Submt
                                paddingTop="10rem"
                                {...form}
                                text={props.t('glossary:save')}
                            />
                        </div>
                    </div>
                </Form.Form>
            </Styled.StartupStep5>
        </Page.Page>
    );
}
