import React, { useState } from 'react';

import { useForm } from 'components/form';
import { RHP } from 'providers';
import * as Page from 'components/page';

import { useParams } from 'react-router';
import { CG } from 'contexts';
import { CorporateStep3EditForm } from './edit-form';

export function CorporateStep3Edit(props) {
    const { id } = useParams();

    const { emitAlert } = React.useContext(CG);

    const {
        vars,
        putCorpEcosystemRelationship,
        postProgramByCompanyId,
        getProgramsByCompanyId
    } = props.useRankingCorporate;

    const { ranking } = vars;

    const [changed, setChanged] = useState({});

    const relationship = vars.relationships.find(i => i._id === id);

    if (!relationship) {
        RHP.goBack();
    }

    // Initialize a new form handler
    const form = useForm({
        ...relationship,
        companyName: relationship.ecosystem.contact.name,
        programName: {
            label: relationship.program.name || relationship.programName,
            value: relationship.program._id || relationship.programName
        },
        isConfirmed: !!(relationship.company && relationship.company.status === 'confirmed')
    });

    // Handle form changes
    const onBlur = {
        companyName: () => changed.companyName && setChanged({ companyName: false }),
        year: () => changed.year && setChanged({ year: false })
    };

    // Handle form changes
    const onChange = {
        companyName: () => setChanged({ companyName: true }),
        year: () => setChanged({ year: true })
    };

    // Handle form submitions
    const onSubmit = async values => {
        try {
            const companyPrograms = await getProgramsByCompanyId(relationship?.ecosystemId);

            let program = companyPrograms?.find(e => e?._id === values.programName.value);

            if (
                values.programName.__isNew__ ||
                !companyPrograms.some(e => e.name.includes(values.programName.label))
            ) {
                program = await postProgramByCompanyId(
                    values.programName.label,
                    relationship?.ecosystemId
                );
            }

            delete program?.regDate;
            delete program?.companies;

            const payload = {
                _id: values._id,
                year: values.year,
                programName: values.programName.label,
                description: values.description,
                linkUrl: values.linkUrl,
                ranking,
                entity: 'company'
            };

            if (program) {
                payload.program = program;
            }

            await putCorpEcosystemRelationship(payload);

            RHP.push('/ranking/latam/corporate/step3');
        } catch (err) {
            emitAlert({
                message: `Ocorreu um erro inesperado, tente novamente mais tarde. Erro: ${err.message}`,
                header: 'Ops...'
            });
        } finally {
            form.submited();
        }
    };

    const propsForm = { ...props, form, onBlur, onChange, onSubmit };

    return (
        <Page.Page name="CorporateStep3Edit">
            <Page.Title>{props.t('CorporateStep3Edit.title')}</Page.Title>
            <Page.Descr>{props.t('CorporateStep3Edit.descr')}</Page.Descr>

            <CorporateStep3EditForm {...propsForm} relationship={relationship} />
        </Page.Page>
    );
}
