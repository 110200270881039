import React, { createContext } from 'react';
import { useMedia } from 'react-use';
import { useTranslation } from 'react-i18next';

const initialState = {
    isMenuOpen: false,
};

export const StartupCXIContext = createContext();
export const StartupCXIContextProvider = ({ children, useStartupId }) => {
    const { startup, startupId } = useStartupId.vars;
    const { t } = useTranslation('startup-cxi');

    const isMobile = useMedia('(max-width: 991px)');

    // Define variables holder to be used on the module
    const [modal, setModal] = React.useState(null);
    const [vars, setVars] = React.useState({
        ...initialState,
        startup,
        startupId,
        isMenuOpen: !isMobile,
    });
    const addVars = newVars => setVars(old => ({ ...old, ...newVars }));
    const stateValue = {
        t,
        vars,
        addVars,
        setVars,
        modal,
        setModal,
    };
    return <StartupCXIContext.Provider value={stateValue}>{children}</StartupCXIContext.Provider>;
};
