import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo100OS } from 'assets/images/logo-100os.svg';

import { ContextGlobal as CG } from 'contexts';
import * as Styled from './StyledMenuLogo';

export function MenuLogo(props) {
    // Get the dispatch function to close menu
    const { dispatch } = React.useContext(CG);
    // Function to change the language
    const click = ev => {
        dispatch({ type: 'MENU_OPEN', menu: false });
    };
    return (
        <Styled.MenuLogo as={Link} to="/home" onClick={click}>
            <Logo100OS alt="Logo 100 Open Startups" />
        </Styled.MenuLogo>
    );
}
export default MenuLogo;
