import React from 'react';
import { Route, Switch } from 'react-router-dom';

// eslint-disable-next-line import/no-cycle
import * as MatchesCXI from './index';

export const routes = [
    {
        exact: true,
        path: '/matches/100-10-1',
        component: MatchesCXI.CXIIndex,
    },
];

export const MatchesCXIRoutes = props => (
    <Switch>
        {routes.map(r => {
            const Component = r.component || React.createComponent(r.toRender);
            const routeProp = { ...r, props, component: undefined };
            return <Route key={r.path} {...routeProp} render={() => <Component {...props} />} />;
        })}
    </Switch>
);
export default MatchesCXIRoutes;