import styled from 'styled-components';

export const Step3 = styled.div`
    min-height: 100%;
    position: relative;

    .form-control:focus,
    .iGCocn input.form-control:not(:placeholder-shown) {
        padding-top: 7px;
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        gap: 10px;
    }

    .calendars {
        display: flex;
        flex-direction: row;
    }

    .form-container {
        display: flex;
        flex-direction: column;
    }

    .form-row {
        margin-bottom: 1rem;

        label {
            display: flex;
            flex-direction: column;
            font-weight: bold;
            width: 100%;
        }
    }

    .form-row1 {
        margin-bottom: 1rem;
        margin-right: 15%;
    }

    .form-row2 {
        margin-bottom: 1rem;

        label {
            font-weight: bold;
            width: 100%;
        }

        input {
            width: 100%;
            font-weight: 400;
        }
    }

    .label {
        display: flex;
        flex-direction: column;
        font-weight: bold;
        width: 100%;
    }

    input {
        height: 40px;
        border-radius: 8px;
        margin-top: 1px;
        border: 1px solid #ccc;
        padding: 1rem;
    }

    input::placeholder {
        font-size: 10px;
        font-style: italic;
        margin-left: 5px;
    }

    .legend-text {
        font-size: 11px;
        font-weight: normal;
        color: #66696e;
    }

    .datesOpened {
        margin-right: 50px;
    }
`;

export const CheckboxGroup = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 400;
`;
export const Title1 = styled.h1`
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.2;
    margin: 0;
    display: flex;
    justify-content: space-between;
    color: black;
`;

export const Questions = styled.h1`
    font-size: 1.4rem;
    font-weight: 700;
    margin-top: 25px;
    margin-bottom: 15px;
    display: flex;
    gap: 1rem;
    align-items: baseline;

    @media (max-width: 768px) {
        font-size: 1.2rem;
    }
`;

export const CheckboxLabel = styled.label`
    display: flex;
    flex-direction: row;

    align-items: center;
    margin-right: 10px;
    font-weight: normal;
`;

export const Dates = styled.div`
    display: flex;
    flex-direction: row;
`;

export const CheckboxInput = styled.input`
    position: absolute;
    opacity: 0;
`;

export const CheckboxButton = styled.span`
    position: relative;
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 2px solid #56c1d6;
    margin-right: 8px;

    &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #ffffff;
        border: none;
        opacity: 0;
    }
`;

export const StyledCheckboxInput = styled(CheckboxInput)`
    /* Definir estilos específicos para o elemento de entrada do checkbox */
    &:checked + ${CheckboxButton}::before {
        /* Estilizar o botão interno quando o checkbox estiver marcado */
        opacity: 1;
        background-color: #56c1d6;
    }
`;

export const Title = styled.h1`
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.2;
    display: flex;
    color: #0094f9;
`;

export const Subtitle = styled.h1`
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 1.2;
    margin-top: 25px;
    margin-bottom: 25px;
    display: flex;
    gap: 1rem;
    align-items: baseline;

    p {
        color: #0094f9;
        font-weight: 600;
        font-size: 1.4rem;
    }

    @media (max-width: 768px) {
        font-size: 1.6rem;
    }
`;
