import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const TabsContainer = styled.div.attrs({
    className: 'tabs-container',
})`
    background-color: #f2f2f2;
`;

export const FounderCard = styled.div.attrs({
    className: 'founder-card',
})`
    align-items: center;
    background: #fff;
    border: 0;
    color: inherit;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 1rem;
    -webkit-appearance: none;
`;

export const CardInfos = styled.div.attrs({
    className: 'founder-card-infos',
})`
    flex: 1 1 calc(100% - 6.5rem);
    font-size: 1.4rem;
    margin-left: 1.5rem;
    text-align: left;

    .card-header {
        padding: 0;
        border-bottom: 0;
        background-color: white;
        display: flex;
        justify-content: space-between;

        .icon {
            font-size: 1.6rem;
            color: var(--primary);
        }
    }

    .name {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1.4;
        /* ${truncate(1)} */
    }

    .bold {
        display: block;
        font-weight: 600;
        margin-top: 0;
        ${truncate(1)}
    }

    .text {
        display: block;
        margin-top: 0.1rem;
        ${truncate(1)}
    }
`;

export const CardContact = styled.div.attrs({
    className: 'founder-card-contact',
})`
    margin-top: 1rem;
    font-size: 1rem;

    .contact {
        ${truncate(1, 1.4)}
    }

    span {
        display: inline-block;
    }
`;

export const InvestmentCard = styled.div.attrs({
    className: 'investment-card',
})`
    align-items: center;
    background: #fff;
    border: 0;
    color: inherit;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 1rem;
    -webkit-appearance: none;
`;

export const MergerCard = styled.div.attrs({
    className: 'merger-card',
})`
    align-items: center;
    background: #fff;
    border: 0;
    color: inherit;
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    padding: 1rem;
    -webkit-appearance: none;
`;

export const BtnContainer = styled.div.attrs({
    className: 'btn-container',
})`
    margin-top: 4rem;
`;

export const Data = styled.div.attrs({
    className: 'basic-data-session',
})`
    .component-form-field {
        margin-top: 0;
    }

    #label-empty-label {
        font-weight: bold;
        margin: 2rem 0 0 0;
    }
`;

export const SubDescr = styled.span.attrs({
    className: 'sub-descr',
})`
    display: 'block';
    margin-top: '2rem';
`;

export const ContactCard = styled.div`
    align-items: center;
    background-color: #f5f5f5;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border: 0;
    color: inherit;
    display: flex;
    margin-top: 2rem;
    padding: 1rem;
    -webkit-appearance: none;

    @media (max-width: 768px) {
        font-size: 1.5rem;
        margin: 0;
        height: 80%;
    }
`;

export const Buttons = styled.div.attrs({
    className: 'cxi-challenges-card-buttons',
})`
    align-items: center;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin-top: 20%;
    
    
    button {
        width:250px;
        flex: 1;
        margin-bottom: 10px;
    }

    @media (max-width: 768px) {
        width: 120px;

        button {
            flex: 1;
            font-size: 0.9rem;
            margin-bottom: 10px;
            padding: 1rem 1rem;
    }
    }
`;

export const Image = styled.div.attrs({
    className: 'user-company-card-image',
    role: 'img',
})`
    background: #fff no-repeat 50% 50%;
    background-image: url('${props => props.img}');
    background-size: contain;
    background-origin: content-box;
    border: 4px solid #ddd;
    border-radius: 50%;
    flex: 0 0 14rem;
    width: 8rem;
    height: 8rem;
    
    @media (max-width: 768px) {
        width: 5rem;
        height: 5rem;
        margin-right: 15px;
    }
`;

export const Title = styled.strong`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.2;
    text-align: left;
    ${truncate(2, 1.2)}
    @media (max-width: 768px) {
        font-size: 1.5rem;
        margin: 0;
    }
`;

export const Label = styled.div`
    margin: 1.2rem 0 1.2rem;
    position: relative;

    .text {
        background: ${({ theme }) => theme.colors.deepBlue};
        color: ${({ theme }) => theme.colors.white};
        border-radius: 1.2rem;
        font-size: 1.3rem;
        margin-right: 10px;
        font-weight: 600;
        padding: 0.3rem 0.8rem 0.4rem;
    }

    @media (max-width: 768px) {
        font-size: 0.9rem;
    }
`;

export const Descri = styled.p`
    color: ${({ theme }) => theme.colors.darkBlue};
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.4;
    margin: 2.4rem 0 0;
    margin-top: 15px;
    text-align: justify;

    @media (max-width: 768px) {
        display: none;
    }
`;
