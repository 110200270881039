import React, { useEffect, useState } from 'react';
import { CA, CG } from 'contexts';
import { routes } from 'modules/routes';
import * as List from 'components/list';
import { useApi } from 'hooks';
import { MenuListModulesItem } from './MenuListModulesItem';
import * as Styled from './StyledMenuListModules';

export function MenuListModules(props) {
    const { state, scopeAllows } = React.useContext(CA);
    const { language } = React.useContext(CG).state;
    const { authenticated, user } = state;
    const [hasHubAccess, setHasHubAccess] = useState(false);
    const api = useApi();
    const { cxi } = state;

    useEffect(() => {
        function getCompanyById(companyId) {
            const id = companyId || state?.user?.company?.id;
            if (!id) return;
            api.get(`/companies/${id}`).then(res => {
                setHasHubAccess(res?.hasHubAccess);
            });
        }
        getCompanyById();
    }, [state.user.company]);

    // Filter the routes to be shown to the user
    const filter = r => {
        // Check if the scope is avaliable
        if (!scopeAllows(r.scope)) {
            return null;
        }

        // Check if the language is avaliable
        if (r.menuLang && !r.menuLang?.includes(language)) {
            return null;
        }
        // Remove /matches from the menu if user has access to CXI
        if (cxi?.status === 'registered' && r.path === '/matches') {
            return null;
        }

        if (
            r.path === '/corporatehub' &&
            Array.isArray(user.pocs) &&
            (!user?.pocs?.includes('hub') || !hasHubAccess)
        ) {
            return null;
        }
        if (r.name === 'RegisterContact') {
            return null;
        }
        return r.name && r.icon && (r.private === false || r.private === authenticated);
    };
    const listProps = {
        items: routes.filter(filter),
        itemKey: 'path',
        itemComponent: MenuListModulesItem,
    };

    return !listProps.items.length ? null : (
        <Styled.MenuListModules as={List.List} name="MenuListModules">
            <List.Vertical {...listProps} />
        </Styled.MenuListModules>
    );
}
export default MenuListModules;
