import styled from 'styled-components';

export const CXIChallenges = styled.div.attrs({
    className: 'container'
})`
    min-height: 100%;
    padding: 1.6rem;
    padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    position: relative;

    @media (min-width: 768px) {
        padding: 3.2rem;
        padding-bottom: calc(5.9rem + var(--ion-safe-area-bottom));
    }

    .c-header.filter {
        margin-top: 2.4rem;
    }
    .c-header.filter > * {
        align-items: center;
        background: rgba(0, 0, 0, 0.075);
        color: ${({ theme }) => theme.colors.black};
        display: flex;
        flex-wrap: wrap;
        gap: 0.8rem;
    }
    .c-header.filter + .c-header.filter {
        margin-top: 0.4rem;
    }
`;

export const CXIChallengesFilters = styled.div`
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    margin: 3.2rem 0 3.2rem;
    gap: 3.2rem;

    @media (min-width: 992px) {
        .cxi-layout:not(.is-open) & {
            grid-template-columns: repeat(2, 1fr);
        }
    }
    @media (min-width: 1200px) {
        grid-template-columns: repeat(2, 1fr);
    }
`;

export const Filter = styled.div`
    display: flex;
    align-items: center;
    background: ${({ theme }) => theme.colors.white};
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    flex-direction: row;
    gap: 1.5rem;
    overflow: hidden;
    padding: 2.4rem;

    p {
        margin: 0;
        font-weight: 500;
    }

    &.challenges strong {
        color: #0094f9;
    }

    .challenges > div {
        flex: 1;
    }
`;

export const Icon = styled.nav`
    display: flex;
    gap: 0rem;
    font-size: ${props => props.size || '4rem'};
    color: ${({ theme }) => theme.colors.deepBlue};

    hr {
        border-top: 2px solid ${({ theme }) => theme.colors.energyBlue};
        margin: 1.2rem -2.4rem;
    }
`;
