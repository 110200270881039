import React, { createContext, useContext, useState } from 'react';

export const DataContext = createContext({});

export const DataContextProvider = props => {
    const dataContext = {
        company: {
            logoUrl: '',
            awards: []
        },
        configVars: {
            configUrl: '',
            startDate: '',
            endDate: '',
            viewOptions: [],
            displayHeader: true,
            displayTime: 5000,
            displayMode: '',
            companyId: '',
            includeGroup: false
        },
        startupsPageVars: {
            mostContractedCommunities: [],
            contractsWithStartupsTotal: 0,
            contractsWithStartupsChartData: [],
            contractedStartupsChartData: [],
            contractedStartupsTotal: 0,
            totalContractsAmount: {},
            latestContractedStartups: [],
            startDate: new Date(),
            endDate: new Date()
        },

        challengesPageVars: {
            speedDatingMeetingsTotals: 0,
            preparedSpeedDatingByDate: [],
            challengesTotals: 0,
            challengesChartData: [],
            startupsAppliedTotal: 0,
            latestChallenges: [],
            oiweeksMeetings: 0,
            latestApplicationStartups: [
                // {
                //     alt: '100 Open Startups',
                //     src: 'assets/images/logo-100-10-1.png',
                // },
            ]
        },

        relationshipsPageVars: {
            // GRÁFICO DE BOLHA
            bubbleChartData: [
                // {
                //     label: 'Agritechs3',
                //     value: 11,
                //     group: 'AgriTechs',
                // },
            ],
            // MAPA DO BRASIL
            geoChartData: []
        },

        executivesPageVars: {
            startupsMatchedTotal: 0, // startups com matches
            startupsMatchedChartData: [
                // {
                //     date: '2022-08',
                //     startupMatches: 10,
                // },
            ],
            executivesRegistered: 0, // executivos cadastrados na base Open Startups
            executivesCertified: 0, // executivos certificados no programa 100-10-1
            cxiRanking:
                // inicializar um formato de array de objetos vazio, puxar dados por funcao depois de confirmar com felipe
                [
                    // {
                    //     name:
                    //         'igorigorigorigorigorigorigorigorigorigorigorigorigorigorigorigorigorigorigorigor',
                    //     points: 120,
                    // },
                ]
        },

        groupsPageVars: {
            // NetworkChartData
            groupNetwork: {
                nodes: [
                    // {
                    //     id: '5a56fc90e3c7d2d4d33769e7',
                    //     name: 'Unimed Piracicaba',
                    //     contracts: 11,
                    //     radius: 50,
                    //     type: 'company',
                    // },
                    // {
                    //     id: '5a56fc90e3c7d2d4d33769e2',
                    //     name: '100 Open Startups',
                    //     contracts: 1,
                    //     radius: 20,
                    //     type: 'startup',
                    // },
                ],
                nodeLinks: [
                    // {
                    //     source: '5a56fc90e3c7d2d4d33769e7',
                    //     target: '5a56fc90e3c7d2d4d33769e2',
                    //     contracts: 1,
                    //     radius: 1,
                    // },
                    // {
                    //     source: '5a56fc90e3c7d2d4d33769e7',
                    //     target: '5a56fc90e3c7d2d4d3376933',
                    //     contracts: 1,
                    //     radius: 1,
                    // },
                    // {
                    //     source: '5a56fc90e3c7d2d4d33769e7',
                    //     target: '5a56fc90e3c7d2d4d3376944',
                    //     contracts: 1,
                    //     radius: 1,
                    // },
                ]
            }, // ver com felipe

            // grupos que mais contratam
            hireTheMost: [
                // { name: '', points: 0 },
            ],
            // startups mais contratadas pelo grupo
            mostHiredStartupsByGroup: [
                // { name: '', points: 0 },
            ]
        }
    };
    const [company, setCompany] = useState({ ...dataContext?.company });
    const [configVars, setConfigVars] = useState({ ...dataContext?.configVars });
    const [headerVars, setHeaderVars] = useState({ ...dataContext?.headerVars });
    const [startupsPageVars, setStartupsPageVars] = useState({ ...dataContext?.startupsPageVars });
    const [challengesPageVars, setChallengesPageVars] = useState({
        ...dataContext?.challengesPageVars
    });
    const [relationshipsPageVars, setRelationshipsPageVars] = useState({
        ...dataContext?.relationshipsPageVars
    });
    const [executivesPageVars, setExecutivesPageVars] = useState({
        ...dataContext?.executivesPageVars
    });
    const [groupsPageVars, setGroupsPageVars] = useState({ ...dataContext?.groupsPageVars });
    return (
        <DataContext.Provider
            value={{
                state: {
                    company,
                    configVars,
                    headerVars,
                    startupsPageVars,
                    challengesPageVars,
                    relationshipsPageVars,
                    executivesPageVars,
                    groupsPageVars
                },
                mutations: {
                    setCompany,
                    setConfigVars,
                    setHeaderVars,
                    setStartupsPageVars,
                    setChallengesPageVars,
                    setRelationshipsPageVars,
                    setExecutivesPageVars,
                    setGroupsPageVars
                }
            }}
        >
            {props.children}
        </DataContext.Provider>
    );
};

export const useData = () => useContext(DataContext);
