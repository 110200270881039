import React from 'react';

// eslint-disable-next-line import/no-cycle
import { iframeLink } from 'core/iframe/IframeLink';
import { StartupIdIndexView } from './StartupIdIndexView';
// eslint-disable-next-line import/no-cycle
import { profileItems, routeItems } from './routeItems';

export const StartupIdIndex = props => {
    const { vars } = props.useStartupId;

    const normalizeRoutes = r => {
        const route = {
            icon: r.icon,
            text: props.t(`menu.${r.name}`),
            href: r.path
        };
        if (r.replace) {
            const newRoute = route.href.replace(/{{startupId}}/g, vars.startupId);
            route.href = newRoute;
            if (r.redirect) {
                route.href = iframeLink(newRoute);
            }
        }

        return route;
    };

    // Create the secondary actions options
    const actions = routeItems
        .filter(r => {
            if (r?.name === 'growup' && !vars?.startup?.growUp) {
                return null;
            }
            return r.name;
        })
        .map(normalizeRoutes);

    const profiles = profileItems.filter(r => r.name).map(normalizeRoutes);

    const propsView = { ...props, actions, profiles };
    return <StartupIdIndexView {...propsView} />;
};
export default StartupIdIndex;
