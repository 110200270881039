import React from 'react';
import { useConversionOptions } from 'hooks';
import { useEffectOnce } from 'react-use';
import { ContextGlobal as CG } from 'contexts';

import { useCXI } from 'modules/cxi/useCXI';
import { useParams } from 'react-router';
import * as Styled from './StyledChallengeCard';

export const CXIChallengeCard = props => {
    const { language } = React.useContext(CG).state;

    const { vars, getCXIChallenges, t } = useCXI();
    const { challengeId } = useParams();
    const { challenges } = vars;

    const conv = useConversionOptions(['eventType']);
    const challengeFiltered = challenges.filter(
        challenge => Number(challenge?._id) === Number(challengeId)
    );

    const challenge = challengeFiltered?.[0];

    useEffectOnce(() => {
        if (vars.challenges.length === 0) {
            getCXIChallenges();
        }
    });

    const applicationDeadlines = new Date(challenge?.applicationDeadline);
    const initialApplicationDates = new Date(challenge?.initialApplicationDate);

    const today = new Date();
    const remainingDays = Math.ceil((applicationDeadlines - today) / (1000 * 60 * 60 * 24));

    const descriptionLine = challenge?.headline?.replace(/<br>/g, '\n');

    const getStatusMessage = () => {
        switch (challenge?.eventStatus) {
            case 'active':
            case 'application':
                return t('challenge.card.open');
            case 'none':
                return t('challenge.card.none');
            case 'ended':
                return t('challenge.card.ended');
            default:
                return t('challenge.card.default');
        }
    };

    return (
        <Styled.ChallengeCard>
            <div>
                <Styled.Image img={challenge?.logoUrl} />
            </div>
            <div>
                <div className="header-challenge">
                    <Styled.Title>{challenge?.name}</Styled.Title>
                </div>

                {(challenge?.applicationDeadline || challenge?.initialApplicationDate) && (
                    <div>
                        {!challenge?.stages?.application && challenge?.eventStatus !== 'none' && (
                            <Styled.IconWithText>
                                <span className="icon far fa-calendar" aria-hidden="true" />
                                <span>
                                    {t('challenge.card.closingDate')}
                                    {applicationDeadlines.toLocaleDateString(language)}{' '}
                                    {t('challenge.card.as')} {applicationDeadlines.getHours()}{' '}
                                    {t('challenge.card.hours')}
                                </span>
                            </Styled.IconWithText>
                        )}

                        {challenge?.stages?.application === true &&
                            challenge?.eventStatus !== 'none' && (
                                <Styled.IconWithText>
                                    <span className="icon far fa-calendar" aria-hidden="true" />
                                    <span>
                                        {t('challenge.card.closeDate')}
                                        {applicationDeadlines.toLocaleDateString(language)}{' '}
                                        {t('challenge.card.as')} {applicationDeadlines.getHours()}{' '}
                                        {t('challenge.card.hours')}
                                    </span>
                                    {applicationDeadlines > today && (
                                        <span
                                            style={{
                                                display: 'inline-block',
                                                fontSize: '0.8em'
                                            }}
                                        >
                                            ({t('challenge.card.part1')} {remainingDays}{' '}
                                            {t('challenge.card.part2')})
                                        </span>
                                    )}
                                </Styled.IconWithText>
                            )}

                        {challenge?.eventStatus === 'none' && (
                            <>
                                {challenge?.stages?.application === false && (
                                    <Styled.IconWithText>
                                        <span className="icon far fa-calendar" aria-hidden="true" />
                                        <span>
                                            {t('challenge.card.closingDate')}
                                            {applicationDeadlines.toLocaleDateString(language)}{' '}
                                            {t('challenge.card.as')}{' '}
                                            {applicationDeadlines.getHours()}{' '}
                                            {t('challenge.card.hours')}
                                        </span>
                                    </Styled.IconWithText>
                                )}
                                {challenge?.stages?.application === true && (
                                    <Styled.IconWithText>
                                        <span className="icon far fa-calendar" aria-hidden="true" />
                                        <span>
                                            {t('challenge.card.startDate')}
                                            {initialApplicationDates.toLocaleDateString(
                                                language
                                            )}{' '}
                                            {t('challenge.card.as')}{' '}
                                            {initialApplicationDates.getHours()}{' '}
                                            {t('challenge.card.hours')}
                                        </span>
                                    </Styled.IconWithText>
                                )}
                            </>
                        )}
                    </div>
                )}

                <Styled.Label>
                    <span className="text"> {conv.eventType[challenge?.type]?.value} </span>
                    <span className="text"> {getStatusMessage()} </span>
                </Styled.Label>

                <Styled.Descr>{descriptionLine}</Styled.Descr>
            </div>
        </Styled.ChallengeCard>
    );
};

export default CXIChallengeCard;
