import React from 'react';

import { Area } from '../components/area/Area';

import * as Styled from './StyledCXIChallengeStartupGeneral';
import { CXIChallengeStartupGeneralPresentation } from './Presentation';
import { CXIChallengeStartupFinancial } from '../financial/CXIChallengeStartupFinancial';
import { CXIChallengeStartupProfile } from '../profile/CXIChallengeStartupProfile';
import { CXIChallengeStartupQuestions } from '../questions/CXIChallengeStartupQuestions';
import { CXIChallengeStartupGeneralSectors } from './Sectors';
import { CXIChallengeStartupGeneralDetails } from './Details';
import { Progress } from '../components/progress';
import { CXIChallengeStartupGeneralContact } from './contact';

export const CXIChallengeStartupGeneral = props => {
    const { startup, t } = props;
    const hasQuestions = startup && startup?.event?.answers && startup?.event?.answers.length > 0;

    return (
        <Styled.CXIChallengeStartupGeneral>
            <Styled.Presentation>
                <Area className="presentation" title={t('startup.presentation')}>
                    <CXIChallengeStartupGeneralPresentation startup={startup} t={t} />
                </Area>
            </Styled.Presentation>

            <Styled.CXIChallengeStartupGeneralSidebar>
                <Area className="details" title={t('startup.details')}>
                    <CXIChallengeStartupGeneralDetails startup={startup} t={t} />
                    <Progress currentStage={startup?.stage} />
                </Area>

                <Area className="contact" title="CONTATO">
                    <CXIChallengeStartupGeneralContact startup={startup} t={t} />
                </Area>

                <Area className="preferences" title={t('startup.preferences')}>
                    <CXIChallengeStartupGeneralSectors startup={startup} t={t} />
                </Area>

                <CXIChallengeStartupFinancial startup={startup} t={t} />
            </Styled.CXIChallengeStartupGeneralSidebar>

            {hasQuestions ? (
                <div style={{ height: '100%' }}>
                    <Styled.CXIChallengeStartupQuestions>
                        <Area
                            style={{ height: '100%' }}
                            className="questions"
                            title={t('startup.challenges')}
                        >
                            <CXIChallengeStartupQuestions
                                style={{ height: '100%' }}
                                startup={startup}
                                t={t}
                            />
                        </Area>
                    </Styled.CXIChallengeStartupQuestions>
                </div>
            ) : (
                <CXIChallengeStartupProfile startup={startup} t={t} />
            )}

            {hasQuestions && <CXIChallengeStartupProfile startup={startup} t={t} />}
        </Styled.CXIChallengeStartupGeneral>
    );
};
