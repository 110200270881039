/* eslint-disable react/no-danger */
import React from 'react';

import { iframeLink } from 'core/iframe';
import * as Page from 'components/page';

export const PagesAbout = props => (
    <Page.Page name="PagesAbout">
        <Page.Title>{props.t('PagesAbout.title')}</Page.Title>
        <Page.Descr>{props.t('PagesAbout.descr')}</Page.Descr>

        <Page.Cntnt>
            <p dangerouslySetInnerHTML={{ __html: props.t('PagesAbout.p1') }} />
            <p dangerouslySetInnerHTML={{ __html: props.t('PagesAbout.p2') }} />
        </Page.Cntnt>

        <Page.LWTXT to={iframeLink('http://openstartups.net/site/rede')}>
            <span className="link">{props.t('PagesAbout.moreNetworks')}</span>
        </Page.LWTXT>

        <Page.LWTXT to={iframeLink('http://openstartups.net/site/sobre')}>
            <span className="link">{props.t('PagesAbout.moreAbout')}</span>
        </Page.LWTXT>
    </Page.Page>
);
export default PagesAbout;
