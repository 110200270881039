import React from 'react';

import { useConversionTable } from 'hooks';
import { useCXI } from 'modules/cxi/useCXI';

import { Option } from '../components/option/Option';

import * as Form from '../components/form';

export const XpEvaluating = () => {
    const { t, vars, addVars } = useCXI();
    const conv = useConversionTable(['xpEvaluating']);
    const data = vars.profile.profile.xpEvaluating;

    const handleChange = value => {
        const profile = {
            ...vars.profile,
            profile: {
                ...vars.profile.profile,
                xpEvaluating: value,
            },
        };
        addVars({ profile });
    };

    return (
        <Form.Form name="XpEvaluating">
            <Form.Title>{t('setup.Titles.xpEvaluating')}</Form.Title>

            <Form.OptionsContainer>
                {Object.keys(conv.xpEvaluating)
                    .map(value => parseInt(value, 10))
                    .map(value => (
                        <Option
                            label={conv.xpEvaluating[String(value)]}
                            value={value}
                            key={value}
                            checked={data === value}
                            onChange={() => handleChange(value)}
                        />
                    ))}
            </Form.OptionsContainer>
        </Form.Form>
    );
};
