/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import * as Styled from './StyledFormControl';

export const FormControlIcons = props =>
    // Don't show this element if it is empty
    !props.icon ? null : (
        <Styled.FormControlIcons>
            {typeof props.icon === 'string' && (
                <span className={`icon fa-fw far ${props.icon}`} aria-hidden="true" />
            )}
            {typeof props.icon !== 'string' && <>{props.icon}</>}
        </Styled.FormControlIcons>
    );
export default FormControlIcons;
