import React, { useEffect, useState } from 'react';

import { useConversionOptions, useUser } from 'hooks';

import * as Alert from 'components/alert';
import * as Form from 'components/form';
import * as Page from 'components/page';
import { CG } from 'contexts';
import { CompanyCard } from 'core/components/company';
import CreatableSelect from 'react-select/creatable';
import { isValidEmail } from 'utils/validation';

import * as Styled from './StyledEcosystemStep4AddForm';

export function EcosystemStep4AddForm({ form, onSubmit, language, useRankingEcosystem, t }) {
    const user = useUser();
    const ContextGlobal = React.useContext(CG);
    const { emitAlert, state } = ContextGlobal;

    const { getCompanyByEmail, getProgramsByCompanyId } = useRankingEcosystem;

    const [renderWarning, setRenderWarning] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [showEmailLanguageField, setShowEmailLanguageField] = useState(false);
    const [companyByEmail, setCompanyByEmail] = useState(null);
    const [companyPrograms, setCompanyPrograms] = useState(null);

    const convEmailLanguage = useConversionOptions(['language']);
    const emailLanguageOptions = [
        ...convEmailLanguage.language.filter(type => type && type.value !== 'none')
    ];

    useEffect(() => {
        if (user.company.id) {
            const getProgramsOptions = async () => {
                const allCompanyPrograms = await getProgramsByCompanyId(user.company.id);

                setCompanyPrograms(allCompanyPrograms);
            };

            getProgramsOptions();
        }
    }, [companyByEmail]);

    // Create form fields object
    const fields = {
        companyContactName: {
            error: form.errors.companyContactName,
            label: t('companyContactName'),
            name: 'companyContactName',
            type: 'text',
            value: form.values.companyContactName,
            onChange: form.handleChange
        },
        companyContactEmail: {
            error: form.errors.companyContactEmail,
            label: t('companyContactEmail'),
            name: 'companyContactEmail',
            type: 'email',
            value: form.values.companyContactEmail,
            onBlur: async ev => {
                form.clearMessages({ name: ev.target.name });

                const companyEmail = ev.target.value;

                if (!companyEmail || !isValidEmail(ev.target.value)) {
                    setCompanyByEmail(null);
                    setCompanyPrograms(null);
                    return;
                }

                const response = await getCompanyByEmail(companyEmail);

                setCompanyByEmail(response);

                if (response.company) {
                    form.addValues({ companyName: response.company.name });
                }
                if (!response.validdomain) {
                    // emitAlert(t('StartupStep8Add.domainError'));

                    form.emitError({
                        name: 'companyContactEmail',
                        error: t('StartupStep8Add.domainError')
                    });
                }
            },
            onChange: form.handleChange
        },
        companyConfirmationEmail: {
            error: form.errors.companyConfirmationEmail,
            label: t('companyContactEmail2'),
            name: 'companyConfirmationEmail',
            type: 'email',
            value: form.values.companyConfirmationEmail,
            onChange: form.handleChange
        },
        companyName: {
            error: form.errors.companyName,
            label: t('glossary:companyName'),
            name: 'companyName',
            type: 'text',
            value: form.values.companyName,
            onChange: form.handleChange,
            disabled: companyByEmail?.company?.id,
            required: !companyByEmail?.validcompany,
            onlyIf: !companyByEmail?.validcompany
        },
        emailLanguage: {
            error: form.errors.emailLanguage,
            label: t('glossary:emailLanguage'),
            name: 'emailLanguage',
            type: 'select',
            options: emailLanguageOptions,
            required: showEmailLanguageField,
            value: form.values.emailLanguage,
            onChange: form.handleChange
        },
        ecosystemCompanyName: {
            error: form.errors.ecosystemCompanyName,
            label: t('ecossystemCompanyName'),
            name: 'ecosystemCompanyName',
            type: 'text',
            value: form.values.ecossystemCompanyName,
            onChange: form.handleChange,
            readOnly: user?.company?.id
        },
        programName: {
            error: form.errors.programName,
            label: t('glossary:programName'),
            name: 'programName',
            type: 'generic',
            options: companyPrograms?.map(item => {
                return { label: item.name, value: item._id };
            }),
            value: form.values.programName,
            onChange: ev => form.setValues(prev => ({ ...prev, programName: ev }))
        },
        description: {
            error: form.errors.description,
            label: t('glossary:description'),
            name: 'description',
            required: false,
            type: 'textarea',
            value: form.values.description,
            onChange: form.handleChange
        },
        year: {
            displayFormat: 'YYYY',
            error: form.errors.year,
            label: t('glossary:year'),
            maxLength: 4,
            name: 'year',
            type: 'text',
            value: form.values.year,
            onChange: form.handleChange
        },
        linkUrl: {
            error: form.errors.linkUrl,
            label: t('glossary:linkUrl'),
            name: 'linkUrl',
            type: 'text',
            required: false,
            value: form.values.linkUrl,
            onChange: form.handleChange
        }
    };

    const companyContactEmailValue = fields.companyContactEmail.value;
    const companyConfirmationEmailValue = fields.companyConfirmationEmail.value;

    useEffect(() => {
        setIsEmailValid(companyConfirmationEmailValue === companyContactEmailValue);
    }, [isEmailValid, companyContactEmailValue, companyConfirmationEmailValue]);

    const propsAlert = {
        descr: t('glossary:emailConfirmationError'),
        icons: 'far fa-exclamation-circle',
        type: 'warning',
        color: 'red'
    };

    // Handle form submition
    const evSubmit = ev => {
        ev.persist();
        ev.preventDefault();

        if (!form.values.programName || !form.values.programName.value) {
            emitAlert(t('EcosystemStep4.programNameRequired'));
            return false;
        }

        form.values.emailLanguage = language;
        if (showEmailLanguageField) {
            form.values.emailLanguage = fields.emailLanguage.value;
        }

        if (!isEmailValid) {
            setRenderWarning(true);
            return false;
        }
        return form.handleSubmit(ev, onSubmit);
    };

    return (
        <Form.Form name="EcosystemStep4AddForm" onSubmit={evSubmit}>
            <Form.Field {...fields.companyContactName} />

            <Form.Field {...fields.companyContactEmail} />

            {/* {smallCompany && (
                <Styled.FieldError className="domain-error">
                    {t('StartupStep8Add.smallCompanyError')}
                </Styled.FieldError>
            )} */}

            {companyByEmail?.company && (
                <Page.Boxed style={{ marginTop: '2rem' }}>
                    {!!Object.keys(companyByEmail.company).length && (
                        <CompanyCard {...companyByEmail} />
                    )}

                    <Styled.LanguageText onClick={() => setShowEmailLanguageField(true)}>
                        {!showEmailLanguageField &&
                            t('CorporateStep4Add.emailLanguageInfo', {
                                startupLanguage: t(`conversionTable:language.${language}`)
                            })}
                    </Styled.LanguageText>

                    {showEmailLanguageField && <Form.Field {...fields.emailLanguage} />}
                </Page.Boxed>
            )}

            <Form.Field {...fields.companyConfirmationEmail} />

            {renderWarning && <Alert.AlertRow {...propsAlert} />}

            <Form.Field {...fields.companyName} />

            <Page.Ruler />

            <Styled.ProgramLabel>{t('glossary:programName')}</Styled.ProgramLabel>
            <CreatableSelect
                placeholder={t('glossary:programNameSelect')}
                options={fields.programName.options}
                styles={{
                    control: baseStyles => ({
                        ...baseStyles,
                        borderRadius: 0,
                        borderColor: '#ddd'
                    }),
                    menu: baseStyles => ({
                        ...baseStyles,
                        zIndex: 91
                    })
                }}
                onChange={fields.programName.onChange}
                value={fields.programName.value}
            />

            <Form.Row>
                <Form.Field size="4" {...fields.year} />
                <Form.Field size="8" {...fields.linkUrl} />
            </Form.Row>
            <Form.Field {...fields.description} />

            <Form.Submt
                {...form}
                text={t('glossary:save')}
                disabled={!isEmailValid || !companyByEmail?.validdomain}
            />
        </Form.Form>
    );
}
export default EcosystemStep4AddForm;
