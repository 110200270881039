import React from 'react';
// import { CardsOiweek } from './components/cards/CardsOiweek';
import { CardsSupport } from './components/cards/CardsSupport';
import { CardsTradeSpace } from './components/cards/CardsTradeSpace';
// import { CardsWebSummit } from './components/cards/CardsWebSummit';

import { About } from './components/about/About';
import { Belts } from './components/belts/Belts';
import { Contents } from './components/contents/Contents';
import { Forums } from './components/forums/Forums';
import { Metrics } from './components/metrics/Metrics';
import { ProfileInfoReminder } from './components/profile-info-reminder/ProfileInfoReminder';
import { ProgressPoints } from './components/progress-points';
import { ProgressSteps } from './components/progress-steps';
import { Ranking } from './components/ranking/Ranking';

import * as Styled from './StyledCXIHome';

export function CXIHome() {
    return (
        <Styled.CXIHome>
            <ProfileInfoReminder />
            <Styled.Row>
                <Styled.Col>
                    <ProgressSteps />
                </Styled.Col>
                <Styled.Col>
                    <ProgressPoints />
                    <div style={{ marginTop: '1.6rem' }} />
                    <Belts />
                </Styled.Col>
            </Styled.Row>

            <Metrics />

            <Styled.CXIHomeAbout>
                <About />
            </Styled.CXIHomeAbout>

            {/* <Dashboard /> */}

            <Ranking />

            <Styled.CXIHomeArea>
                <Forums />
                <Styled.TradeAndSupportCards>
                    <CardsTradeSpace />
                    <CardsSupport />
                </Styled.TradeAndSupportCards>
            </Styled.CXIHomeArea>
            <Contents />

            {/* <Articles /> */}
            {/* <Testimonials /> */}
        </Styled.CXIHome>
    );
}
