import React from 'react';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

import { CONFIG } from 'config';
import * as Styled from './StyledFormInput';

export function FormInputTel(props) {
    const propsIntlTelInput = {
        autoComplete: props.autoComplete || 'tell',
        defaultCountry: 'br',
        fieldId: props.input.id,
        fieldName: props.input.name,
        format: true,
        inputClassName: 'form-control',
        telInputProps: {},
        placeholder: props.input.placeholder,
        preferredCountries: CONFIG.preferredCountries,
        value: props.input.value,

        onPhoneNumberChange: (status, value, country) => {
            props.input.onChange({
                persist: () => null,
                preventDefault: () => null,
                target: { name: props.input.name, value },
            });
        },
    };

    return !props.input ? null : (
        <Styled.FormInput name="tell">
            {props.children}
            <IntlTelInput {...propsIntlTelInput} />
        </Styled.FormInput>
    );
}
export const tell = FormInputTel;
export default FormInputTel;
