import styled from 'styled-components';
import { positions } from 'utils/styles';

import bg from 'assets/images/bg-image.png';

export const CardImage = styled.div.attrs({
    className: 'event-card-image',
})`
    flex: 0 0 15.5rem;
    height: 28rem;
    margin: -0.5rem -0.5rem 0;
    position: relative;
    margin-top: -60px;

    &::before {
        background: transparent no-repeat 50% 50%;
        background-image: url(${bg});
        background-size: cover;
        content: '';
        opacity: 0.3;
        position: absolute;
        ${positions(0, 'auto', 0, '50%')}
        transform: translateX(-50%);
        width: 100vw;
    }
    &::after {
        background: transparent no-repeat 50% 50%;
        background-image: url(${props => props.img});
        background-size: contain;
        background-origin: content-box;
        content: '';
        padding: 1.5rem 1.5rem 2.5rem;
        position: absolute;
        ${positions(0, 0, 0, 0)}
    }
`;

export const EventCard = styled.div.attrs({
    className: 'event-card',
})``;

export const SocialMediaContainer = styled.div.attrs({
    className: 'social-media-icon',
})`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -1.5rem;
    position: relative;
    z-index: 99;
`;

export const IconsContainer = styled.div.attrs({
    className: 'icon-container',
})`
    display: flex;

    .icon ~ .icon {
        margin-left: 1.5rem;
    }
`;

export const SocialMediaIcon = styled.div.attrs({
    className: 'icon',
})`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    background-color: #45aaf2;
    border-radius: 50%;
    font-size: 1.5rem;

    a {
        color: white;
    }
`;

export const ShadowedText = styled.div.attrs({
    className: 'shadowed-text',
})`
    margin-top: 1rem;
    font-size: 1.6rem;
    opacity: 0.3;
    text-transform: uppercase;
`;

export const EventInfoContainer = styled.div.attrs({
    className: 'event-info-container',
})`
    margin: 30px 16px;
`;

export const AddressDateContainer = styled.div.attrs({
    className: 'adress-date-container',
})`
    margin-top: 15px;
`;

export const SubTextContainer = styled.div.attrs({
    className: 'subtext-container',
})`
    display: flex;
    align-items: center;
    margin-bottom: 11px;
    font-size: 1.5rem;

    span {
        margin-right: 0.8rem;
    }
`;

export const SubText = styled.p.attrs({
    className: 'subtext',
})`
    margin: 0;
`;

export const DescriptionContainer = styled.div.attrs({
    className: 'description-container',
})`
    margin: 30px 16px;
`;

export const DescriptionTitle = styled.h2.attrs({
    className: 'description-title',
})`
    font-size: 1.6rem;
    margin-bottom: 2rem;
`;
