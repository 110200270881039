import React from 'react';

import { useTranslation } from 'react-i18next';
import * as Styled from './StyledStartupCard';

export function StartupCard(props) {
    const { t } = useTranslation('ranking');
    const data = props.data || props.startup || undefined;

    const info = {};
    if (data.data) {
        info.image = { img: data.data.logoUrl, alt: data.data.name };
        info.title = data.data.name;
        if (data.contact) {
            info.name = data.contact.name;
            info.mail = data.contact.email;
        }
    }
    if (data._id) {
        info._id = data.shortId;
    }
    if (data.startupStatus) {
        info.image = { img: data.logoUrl, alt: data.name };
        info.title = data.name;
        // ADICIONAR WARNING SEM CONTATO DE EMAIL
        info.name = data?.entrepreneur?.firstName;
        info.mail = data?.entrepreneur?.email;
    }

    const sideBadges = [];
    if (['none', 'inactive'].includes(data.startupStatus)) {
        const cn = 'badge badge-danger mr-2';
        sideBadges.push(() => <div className={cn}>{t('notRegistered')}</div>);
    }
    return !data ? null : (
        <Styled.StartupCard>
            <Styled.CardImage {...info.image} />
            <Styled.CardInfos>
                <span className="name">{info.title}</span>
                <span className="text">ID {info._id}</span>
                <span className="text">{info.name}</span>
                <span className="text">{info.mail}</span>
                {sideBadges.map((Badge, k) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <Badge key={`sidebadge-${k}`} />
                ))}
            </Styled.CardInfos>
        </Styled.StartupCard>
    );
}
export default StartupCard;
