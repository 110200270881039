import React from 'react';
import { useTranslation } from 'react-i18next';
import { IonActionSheet } from '@ionic/react';

import { ContextGlobal as CG } from 'contexts';
import { Button, Descr, LWTXT } from 'components/page';
import { iframeLink } from 'core/iframe';
import * as Table from 'components/table';
import { useCopyToClipboard } from 'react-use';
import { FileCopy } from '@mui/icons-material';
import { RelationshipCard } from './RelationshipCard';
import * as Styled from './StyledRelationship';

export function Relationship(props) {
    const { emitAlert } = React.useContext(CG);
    const { t } = useTranslation('ranking');
    const [copy, setCopy] = useCopyToClipboard();
    const [showAS, setShowAS] = React.useState(false);

    const {
        deleteUserRelationship,
        postUserRelationshipValidation,
        postUserRelationshipValidationEmail
    } = props.useRankingEcosystem;
    const data = props.data || props.relationship || undefined;

    const relationship = {
        companyName: data.company.name,
        programName: data.programName
    };
    if (data.year) {
        relationship.year = `${data.year}`;
    }

    // Properties for the table
    const propsTable = {
        href: `/ranking/latam/ecosystem/step3/edit/${data._id}`,
        subtitle: {
            link: t('glossary:edit'),
            icon: 'far fa-pencil'
        },
        object: {
            data: { ...relationship },
            name: 'Relationship',
            dataConv: {}
        }
    };
    if (data.company.status === 'rejected') {
        propsTable.href = undefined;
        propsTable.subtitle = {
            link: '',
            icon: ''
        };
    }

    const closeModal = () => {
        if (props.closeModal) props.closeModal();
        return true;
    };

    function handleConfirm() {
        const payload = {
            _id: data._id,
            status: 'confirmed',
            lastUpdated: data.lastUpdated
        };
        return postUserRelationshipValidation(payload).then(closeModal);
    }
    function handleDelete() {
        const payload = { _id: data._id };
        return deleteUserRelationship(payload).then(res => {
            closeModal();
            emitAlert(t('relationshipDeleted'));
            return res;
        });
    }
    function handleReject() {
        const payload = {
            _id: data._id,
            status: 'rejected',
            lastUpdated: data.lastUpdated
        };
        return postUserRelationshipValidation(payload).then(closeModal);
    }
    function handleResend() {
        const payload = { _id: data._id };
        return postUserRelationshipValidationEmail(payload).then(res => {
            emitAlert(t('relationshipResent'));
            return res;
        });
    }
    function handleReaudit() {
        emitAlert(t('relationship ReauditSent'));
    }

    const buttons = [];
    if (data.company && data.company.status === 'pending' && data.status === 'pending') {
        const s = { type: 'success', onClick: handleConfirm };
        const d = { type: 'danger', onClick: handleReject };
        buttons.push(() => (
            <Styled.RelationshipButtons>
                <Button {...s}>{t('glossary:confirm')}</Button>
                <Button {...d}>{t('glossary:reject')}</Button>
            </Styled.RelationshipButtons>
        ));
    }
    if (data.startup && data.startup.status === 'pending') {
        buttons.push(() => (
            <Button type="dark" onClick={handleResend}>
                <span className="text">{t('glossary:resend')}</span>
                <span className="icon far fa-long-arrow-right" aria-hidden="true" />
            </Button>
        ));
    }
    if (data.audited === 'rejected' && data.displayAudit) {
        buttons.push(() => (
            <Button type="dark" onClick={handleReaudit}>
                <span className="text">{t('glossary:reaudit')}</span>
                <span className="icon far fa-long-arrow-right" aria-hidden="true" />
            </Button>
        ));
    }

    // VER LOGICA DE DELETE COM LEVY
    if (data.startup && data.startup.status === 'pending') {
        const l = { type: 'link', onClick: () => setShowAS(true) };
        buttons.push(() => <Button {...l}>{t('relationshipDelete')}</Button>);
    }

    return (
        <Styled.Relationship className={props.className} style={props.style}>
            <RelationshipCard data={data} />

            <Table.Table {...propsTable} />
            <Styled.ContractIdContainer>
                <span>
                    {data?._id}
                    <Styled.CopyButton
                        type="dark"
                        variant="neutral"
                        onClick={() => setCopy(data?._id)}
                    >
                        <FileCopy> </FileCopy>
                        {!copy.value && <span className="text"> </span>}
                        {!!copy.value && <span className="text"> </span>}
                    </Styled.CopyButton>
                </span>
                <p>ID</p>
            </Styled.ContractIdContainer>
            {data.description && <Descr>{data.description}</Descr>}
            {data.linkUrl && (
                <LWTXT to={iframeLink(data.linkUrl)}>
                    <span className="text">{t('glossary:linkUrl')}:</span>
                    <span className="link">{data.linkUrl}</span>
                </LWTXT>
            )}

            {buttons.map((Btn, k) => (
                // eslint-disable-next-line react/no-array-index-key
                <Btn key={`button-${k}`} />
            ))}
            <IonActionSheet
                isOpen={showAS}
                onDidDismiss={() => setShowAS(false)}
                buttons={[
                    {
                        text: t('relationshipDelete'),
                        role: 'destructive',
                        handler: handleDelete
                    },
                    {
                        text: t('glossary:cancel'),
                        role: 'cancel'
                    }
                ]}
            />
        </Styled.Relationship>
    );
}
export default Relationship;
