import React from 'react';

import { ContextAuth as CA } from 'contexts';

// eslint-disable-next-line import/no-cycle
import { routes } from '../ProfileRoutes';
import { ProfileIndexView } from './ProfileIndexView';

export function ProfileIndex(props) {
    const { state } = React.useContext(CA);

    // Create the secondary actions options
    const actions = routes
        .filter(r => r.name)
        .map(r => ({
            icon: r.icon,
            href: r.path,
            text: props.t(`menu.${r.name}`),
        }));

    // Get the user fullname
    const { fullname } = state.user;

    const propsView = { ...props, actions, fullname };
    return <ProfileIndexView {...propsView} />;
}
export default ProfileIndex;
