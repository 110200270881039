import styled from 'styled-components';
import { truncate } from 'utils/styles';

export const Wrapper = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 2.4rem;
    height: 100%;
    width: 100%;

    @media (min-width: 992px) {
        &.banner {
            margin-top: 5.4rem;
        }
    }

    /* @media (min-width: 1400px) {
        &.user {
            flex: 1 1.5 auto;
        }
        &.challenges {
            flex: 1.5 1 auto;
        }
    } */
`;
export const About = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4.8rem;
    height: 100%;
    align-items: center;

    @media (min-width: 992px) {
        flex-direction: row;
    }
`;

export const AboutHead = styled.div`
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    h2 h3 {
        color: ${({ theme }) => theme.colors.darkBlue};
        font-size: 22px;
        font-weight: 400;
        margin: 0;
    }
`;

export const AboutBody = styled.div`
    align-items: center;
    background: ${({ theme }) => theme.colors.white};
    border: 0;
    border-radius: 0.8rem;
    box-shadow: 0px 0px 15px #3428680d;
    display: flex;
    flex-direction: column;
    gap: 1.6rem;
    padding: 2.3rem;
    text-align: center;

    @media (min-width: 768px) {
        &.user {
            flex-direction: row;
        }
        &.user .about-button {
            flex: 0 0 auto;
            margin-left: auto;
            width: auto;
        }
    }

    @media (min-width: 1400px) {
        &.challenges {
            flex-direction: row;
        }
        &.challenges .about-button {
            flex: 0 0 auto;
            margin-left: auto;
            width: auto;
        }
    }

    .user-avatar {
        flex: 0 0 6.4rem;
        width: 6.4rem;
    }

    .user {
        align-items: center;
        display: flex;
        flex-direction: row;
        gap: 1.6rem;
        text-align: center;
    }

    .infos {
        align-items: flex-start;
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        gap: 0.4rem;
    }
    .infos span {
        font-weight: 400;
        line-height: 1.2;
        ${truncate(1, 1.2)}
    }
    .infos .name {
        font-size: 1.6rem;
        font-weight: 600;
    }
    .infos .comp {
        font-size: 1.4rem;
    }
    .infos .post {
        font-size: 1.2rem;
        opacity: 0.6;
        text-align: left;
    }

    .challenges {
        align-items: flex-start;
        display: flex;
        flex: 1 1 auto;
        flex-direction: row;
        gap: 0.2rem;
    }
    .challenges .name {
        font-size: 1.4rem;
        font-weight: 500;
        text-align: left;
    }

    .about-button {
        flex: 1 1 auto;
        white-space: nowrap;
        width: 100%;
    }
`;
export const Banner = styled.div`
    align-items: flex-end;
    flex-direction: row;
    gap: 2.4rem;
    height: 100%;

    img {
        width: 100%;
    }
`;
