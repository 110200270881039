import React from 'react';

import { RHP } from 'providers';
import { ContextAuth as CA } from 'contexts';
import { useConversionTable } from 'hooks';
import { useForm } from 'components/form';

import * as Page from 'components/page';
import * as Table from 'components/table';

import { StartupStep1Form } from './form-step-1';

export function StartupStep1(props) {
    const conv = useConversionTable(['ecosystemRole']);
    const { state } = React.useContext(CA);

    const { vars, putStartupRankingApplication } = props.useRankingStartup;
    const { ranking, startupId, application } = vars;

    // Initialize a new form handler
    const form = useForm({
        isUpToDate: !!application.appliedAt
    });

    // Handle form submitions
    const onSubmit = values => {
        const payload = {
            ...application,
            ...values
        };

        if (!payload.applied) {
            payload.applied = false;
        }

        // Set the current step to true;
        if (!payload.steps) {
            payload.steps = {};
        }

        payload.steps.step1 = true;

        return putStartupRankingApplication(payload)
            .then(() => RHP.push('/ranking/latam/startup/step2'))
            .catch(() => form.submited());
    };

    // Properties for the table
    const tableAbout = {
        href: `/profile/edit?returnTo=${encodeURIComponent(
            `/ranking/latam/startup/step1?startupId=${startupId}`
        )}`,
        subtitle: {
            text: props.t('StartupStep1.aboutYou'),
            link: props.t('glossary:edit'),
            icon: 'far fa-pencil'
        },
        object: {
            data: {
                fullName: state.user.fullName,
                email: state.user.email,
                personalEmail: state.user.personalEmail,
                phone: state.user.phone,
                gender: state.user.gender,
                ecosystemRole: state.user.role
            },
            name: 'aboutYou',
            dataConv: { ...conv, role: conv.ecosystemRole }
        }
    };

    const tableLocation = {
        href: `/profile/location?returnTo=${encodeURIComponent(
            `/ranking/latam/startup/step1?startupId=${startupId}`
        )}`,
        subtitle: {
            text: props.t('StartupStep1.aboutLocation'),
            link: props.t('glossary:edit'),
            icon: 'far fa-pencil'
        },
        object: {
            data: {
                location: state.user.locationString
            },
            name: 'aboutLocation'
        }
    };

    const tableWork = {
        href: '/profile/company',
        subtitle: {
            text: props.t('StartupStep1.aboutWork'),
            link: props.t('glossary:edit'),
            icon: 'far fa-pencil'
        },
        object: {
            data: {
                companyName: state.user.company?.name || '',
                position: state.user.position,
                department: state.user.department
            },
            name: 'aboutWork',
            dataConv: { ...conv, role: conv.ecosystemRole }
        }
    };

    const propsForm = { ...props, form, onSubmit };

    return (
        <Page.Page
            name="StartupStep1"
            back="/ranking/latam/startup"
            customBackText={props.t('glossary:backToRanking')}
        >
            <Page.Title>{props.t('StartupStep1.title')}</Page.Title>
            <Page.Descr>{props.t('StartupStep1.descr', { rankingYear: ranking.year })}</Page.Descr>

            <Table.Table {...tableAbout} />
            <Table.Table {...tableLocation} />
            <Table.Table {...tableWork} />

            <Page.Ruler />

            <StartupStep1Form {...propsForm} useRankingStartup={props.useRankingStartup} />
        </Page.Page>
    );
}
