import React from 'react';

import { Header } from 'modules/cxi/components/header/Header';
import { useLocation } from 'react-router';
import { useEffectOnce } from 'react-use';
import { useStartupCXI } from '../useStartupCXI';
import { MentoringRequested } from '../mentoring/components/requested/MentoringRequested';

import * as Nav from './components/navigation';
import { CXIFeedbacksRoutes } from './CXIFeedbacksRoutes';
import * as Styled from './StyledCXIFeedbacks';

// const CXI_LINK = 'https://www.openstartups.net/site/produtos/100-10-1.html';

export const CXIFeedbacks = props => {
    const { t, setModal } = useStartupCXI();
    const location = useLocation();
    const matchIdResponse = location?.state?.matchIdResponse;
    useEffectOnce(() => {
        if (matchIdResponse) {
            setModal({ matchIdResponse, modal: 'MatchFromShare' });
        }
    });

    return (
        <Styled.CXIFeedbacks>
            <Styled.Texts>
                <p className="title">{t('feedbacks.texts1')}</p>
                <p className="descr">{t('feedbacks.texts2')}</p>
                <p>{t('feedbacks.texts3')}</p>
                <p>{t('feedbacks.texts4')}</p>
                <p className="footr">{t('feedbacks.texts5')}</p>
            </Styled.Texts>

            <MentoringRequested />

            <Header
                className="c-header--main"
                title={t('feedbacks.title')}
                tooltip={t('feedbacks.tooltip')}
            >
                <Nav.Navigation>
                    <Nav.Page path="">{t('feedbacks.tab.all')}</Nav.Page>
                    <Nav.Page path="/unevaluated">{t('feedbacks.tab.unevaluated')}</Nav.Page>
                    <Nav.Page path="/evaluated">{t('feedbacks.tab.evaluated')}</Nav.Page>
                </Nav.Navigation>
            </Header>

            <Styled.CXIFeedbacksRoutes>
                <CXIFeedbacksRoutes />
            </Styled.CXIFeedbacksRoutes>
        </Styled.CXIFeedbacks>
    );
};
