import React from 'react';

import * as Styled from './StyledFormInputRadio';

export const FormInputRadioItem = props => (
    <Styled.FormInputRadioItem>
        <input className="form-radio" {...props.input} value={props.value} />
        <label className="form-radio-label" htmlFor={props.input.id}>
            {props.name}
        </label>
    </Styled.FormInputRadioItem>
);
export default FormInputRadioItem;
