import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import * as Tabs from 'modules/cxi/components/tabs';

import { useCXI } from 'modules/cxi/useCXI';
import { StartupCard } from './components/startup-card';

import { CXIChallengeStartupGeneral } from './general/CXIChallengeStartupGeneral';

import * as Styled from './StyledStartupPage';
import { CXIChallengeStartupFinancial } from './financial/CXIChallengeStartupFinancial';
import { CXIChallengeStartupProfile } from './profile/CXIChallengeStartupProfile';
import { CXIChallengeStartupSummaries } from './summaries/CXIChallengeStartupSummaries';

export const CXIStartupPage = props => {
    const { getCXIChallengeStartup, t } = useCXI();
    const { startupId, challengeId } = useParams();

    const [startup, setStartup] = useState();
    const [lastStartupId, setLastStartupId] = useState();
    const [lastChallengeId, setLastChallengeId] = useState();

    useEffect(() => {
        async function getStartup() {
            if (startupId !== lastStartupId || challengeId !== lastChallengeId) {
                setStartup(await getCXIChallengeStartup(startupId, challengeId));
                setLastStartupId(startupId);
                setLastChallengeId(challengeId);
            }
        }
        getStartup();
    }, [getCXIChallengeStartup, startupId, challengeId, lastStartupId, lastChallengeId]);

    return (
        <Styled.CXIStartupLayout className={startup?.typeOfProject === 'tech' ? 'is-tech' : ''}>
            <Styled.CXIStartup>
                <button
                    type="button"
                    className="icon far fa-arrow-left"
                    onClick={() => window.history.back()}
                    style={{
                        border: 'none',
                        color: 'white',
                        background: 'transparent'
                    }}
                />

                <Styled.CXIStartupCard>
                    <StartupCard startup={startup} t={t} />
                </Styled.CXIStartupCard>

                <Tabs.TabsContainer initial="general">
                    <Styled.CXIStartupTabs>
                        <Tabs.Tab name="general">
                            <CXIChallengeStartupGeneral startup={startup} t={t} />
                        </Tabs.Tab>

                        <Tabs.Tab name="financial">
                            <CXIChallengeStartupFinancial startup={startup} t={t} />
                        </Tabs.Tab>

                        <Tabs.Tab name="profile">
                            <CXIChallengeStartupProfile startup={startup} t={t} />
                        </Tabs.Tab>

                        {startup?.typeOfProject === 'tech' && (
                            <Tabs.Tab name="summaries">
                                <CXIChallengeStartupSummaries startup={startup} t={t} />
                            </Tabs.Tab>
                        )}
                    </Styled.CXIStartupTabs>
                </Tabs.TabsContainer>
            </Styled.CXIStartup>
        </Styled.CXIStartupLayout>
    );
};

export default CXIStartupPage;
