import * as Alert from 'components/alert';
import * as Form from 'components/form';
import * as Page from 'components/page';
import { CG } from 'contexts';
import { Loading } from 'modules/cxi/components/loading';
import { ICompanyPocs, PocRequest } from 'modules/ranking/interface';
import { RHP } from 'providers';
import React, { useState } from 'react';
import { formatDate } from 'utils/date';
import PocsCard from '../components/pocs/PocsCard';
import * as Styled from './styles';

interface IVarsProps {
    pocsRequest: PocRequest[];
    companyPocs: ICompanyPocs;
}

export function EditPocs({ useRankingCorporate, t }) {
    const { vars, postPocsRequest } = useRankingCorporate;
    const { pocsRequest, companyPocs } = vars as IVarsProps;

    const { emitAlert } = React.useContext(CG);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const seniorPocs = pocsRequest?.filter(poc => poc.poc === 'senior');
    const communicationPocs = pocsRequest?.filter(poc => poc.poc === 'communication');
    const operationalPocs = pocsRequest?.filter(poc => poc.poc === 'operational');

    const form = Form.useForm({
        message: '',
        hasAuthorizationBox: ''
    });

    const fields = {
        message: {
            // @ts-ignore
            error: form.errors.message,
            label: t('rankingEditPocs.justify'),
            name: 'message',
            type: 'textarea',
            required: true,
            value: form.values.message,
            onChange: form.handleChange
        },
        hasAuthorizationBox: {
            // @ts-ignore
            error: form.errors.hasAuthorizationBox,
            label: t('rankingEditPocs.hasAuthorizationBox'),
            name: 'hasAuthorizationBox',
            type: 'checkbox',
            required: true,
            value: form.values.hasAuthorizationBox,
            onChange: form.handleChange
        }
    };

    const handleGoBack = () => {
        RHP.goBack();
    };

    const onSubmit = async (values: { message: string }) => {
        setIsLoading(true);
        const body = {
            message: values.message,
            changes: pocsRequest
        };

        try {
            await postPocsRequest({ pocsRequest: body });
        } catch (e) {
            emitAlert({
                message: `Houve algum problema ao enviar o formulário, tente novamente mais tarde. ou se persistir entre em contato com nossa equipe: helpme@openstartups.net.`,
                header: 'Ops...'
            });
        } finally {
            setIsLoading(false);
        }

        RHP.push('/ranking/latam/corporate/step3');
    };

    // @ts-ignore
    const evSubmit = (ev: any) => form.handleSubmit(ev, onSubmit);

    if (isLoading) {
        return <Loading>{t('rankingEditPocs.sendingRequest')}</Loading>;
    }

    return (
        <Page.Page name="PocsEdit" customBackText={t('glossary:backToRanking')}>
            <Page.Title>{t('rankingEditPocs.title')}</Page.Title>

            {companyPocs?.lastUpdated?.regDate && (
                <Styled.LastChangeContainer>
                    <div>{t('rankingEditPocs.lastChange')}</div>
                    <span>
                        {t('rankingEditPocs.date')}
                        {formatDate(new Date(companyPocs.lastUpdated.regDate), 'dd/MM/yyyy')}
                    </span>
                    <span>
                        {t('rankingEditPocs.sendBy')}
                        {companyPocs.lastUpdated.userName}
                    </span>
                </Styled.LastChangeContainer>
            )}

            {companyPocs?.lastRequest?.regDate && companyPocs?.lastRequest?.status !== 'confirmed' && (
                <Alert.Alert type="warning">
                    <Styled.LastChangeContainer>
                        <div>{t('rankingEditPocs.pendingChange')}</div>
                        <span>
                            {t('rankingEditPocs.date')}
                            {formatDate(new Date(companyPocs.lastRequest.regDate), 'dd/MM/yyyy')}
                        </span>
                        <span>
                            {t('rankingEditPocs.sendBy')}
                            {companyPocs.lastRequest.userName}
                        </span>
                        <p>{t('rankingEditPocs.lastRequestInfo')}</p>
                    </Styled.LastChangeContainer>
                </Alert.Alert>
            )}

            <Styled.PocsContainer>
                <Styled.PocsSession>
                    <Page.Title>{t('rankingEditPocs.seniorPoc.title')}</Page.Title>
                    <span>{t('rankingEditPocs.seniorPoc.description')}</span>

                    {seniorPocs.map(poc => {
                        return (
                            <PocsCard
                                key={`${poc.email}-${poc.poc}`}
                                data={poc}
                                useRankingCorporate={useRankingCorporate}
                            />
                        );
                    })}

                    <Page.Buttn
                        to="/ranking/latam/corporate/editPocs/add?poc=senior"
                        // @ts-ignore
                        type="linkd btn-block"
                    >
                        <span className="icon far fa-plus-circle" aria-hidden="true" />
                        <span className="text">{t('rankingEditPocs.addNewPoc')}</span>
                        <span className="icon far fa-long-arrow-right" aria-hidden="true" />
                    </Page.Buttn>
                </Styled.PocsSession>

                <Page.Ruler />

                <Styled.PocsSession>
                    <Page.Title>{t('rankingEditPocs.communicationPoc.title')}</Page.Title>
                    <span>{t('rankingEditPocs.seniorPoc.description')}</span>

                    {communicationPocs.map(poc => {
                        return (
                            <PocsCard
                                key={`${poc.email}-${poc.poc}`}
                                data={poc}
                                useRankingCorporate={useRankingCorporate}
                            />
                        );
                    })}

                    <Page.Buttn
                        to="/ranking/latam/corporate/editPocs/add?poc=communication"
                        // @ts-ignore
                        type="linkd btn-block"
                    >
                        <span className="icon far fa-plus-circle" aria-hidden="true" />
                        <span className="text">{t('rankingEditPocs.addNewPoc')}</span>
                        <span className="icon far fa-long-arrow-right" aria-hidden="true" />
                    </Page.Buttn>
                </Styled.PocsSession>

                <Page.Ruler />

                <Styled.PocsSession>
                    <Page.Title>{t('rankingEditPocs.operationalPoc.title')}</Page.Title>
                    <span>{t('rankingEditPocs.operationalPoc.description')}</span>

                    {operationalPocs.map(poc => {
                        return (
                            <PocsCard
                                key={`${poc.email}-${poc.poc}`}
                                data={poc}
                                useRankingCorporate={useRankingCorporate}
                            />
                        );
                    })}

                    <Page.Buttn
                        to="/ranking/latam/corporate/editPocs/add?poc=operational"
                        // @ts-ignore
                        type="linkd btn-block"
                    >
                        <span className="icon far fa-plus-circle" aria-hidden="true" />
                        <span className="text">{t('rankingEditPocs.addNewPoc')}</span>
                        <span className="icon far fa-long-arrow-right" aria-hidden="true" />
                    </Page.Buttn>
                </Styled.PocsSession>
            </Styled.PocsContainer>

            <Page.Ruler />

            <Form.Form name="editPocsForm" onSubmit={evSubmit}>
                <Form.Field {...fields.message} />

                <Form.Field {...fields.hasAuthorizationBox} />

                <p style={{ fontStyle: 'italic', fontSize: 13, marginTop: 20 }}>
                    {t('rankingEditPocs.formInfo')}
                </p>

                <div style={{ display: 'flex', gap: '10px' }}>
                    {/* @ts-ignore*/}
                    <Page.Buttn style={{ width: '25%' }} onClick={handleGoBack}>
                        <span className="icon far fa-long-arrow-left" aria-hidden="true" />
                        <span className="text" style={{ margin: '0 0 0 auto' }}>
                            {t('rankingEditPocs.cancel')}
                        </span>
                    </Page.Buttn>
                    <div style={{ width: '80%' }}>
                        {/* @ts-ignore*/}
                        <Form.Submt
                            paddingTop="10rem"
                            text={t('rankingEditPocs.sendSolicitation')}
                        />
                    </div>
                </div>
            </Form.Form>
        </Page.Page>
    );
}
