import logo100os from 'assets/images/logo-100os.svg';
import { useData } from 'modules/corporatehub/context/dataContext';
import React from 'react';
import { useHistory } from 'react-router';
import shareIcon from '../../../assets/share-icon.png';
import * as Styled from './StyledHeader';

function Header() {
    const history = useHistory();
    const dataContext = useData();
    const { configVars, company } = dataContext.state;
    const configLink = configVars?.configUrl?.split('/startups')[1];

    const handleClick = () => {
        return history.push(`/corporatehub/config${configLink}`);
    };

    return (
        <Styled.Header onClick={handleClick}>
            <Styled.FirstHeaderContainer>
                <span>
                    <Styled.TitleContainer>
                        <h1>Painel de Performance</h1>
                        <p>em Open Innovation com startups</p>
                    </Styled.TitleContainer>
                </span>

                <span>
                    <Styled.UserLogo>
                        <img src={company?.logourl} alt="Logo da corporação" />
                    </Styled.UserLogo>

                    {company?.awards > 0 && (
                        <Styled.PrizesContainer>
                            <h1>Premiações recentes</h1>
                            <Styled.PrizesLogosContainer>
                                {company?.awards?.map(award => {
                                    return (
                                        <Styled.PrizesIndividualLogo key={award.logoUrl}>
                                            <img
                                                src={award.logoUrl}
                                                alt="100 Open Startups"
                                                key={award.logoUrl}
                                            />
                                        </Styled.PrizesIndividualLogo>
                                    );
                                })}
                            </Styled.PrizesLogosContainer>
                        </Styled.PrizesContainer>
                    )}

                    <Styled.CopyButton
                        type="dark"
                        variant="neutral"
                        onClick={() => setCopy(shareLink)}
                    >
                        <img src={shareIcon} alt="Share Link Icon" />
                        {!copy.value && <span className="text">Compartilhar</span>}
                        {!!copy.value && (
                            <span className="text-gray" style={{ color: 'gray' }}>
                                Link copiado
                            </span>
                        )}
                    </Styled.CopyButton>
                </span>
            </Styled.FirstHeaderContainer>

            <Styled.PoweredByContainer>
                Powered by{' '}
                <Styled.PoweredByLogo>
                    <img src={logo100os} alt="100 Open Startups" />
                </Styled.PoweredByLogo>
            </Styled.PoweredByContainer>
        </Styled.Header>
    );
}

export default Header;
