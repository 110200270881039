import React from 'react';
import Moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IonActionSheet } from '@ionic/react';
import { useCopyToClipboard } from 'react-use';
import { FileCopy } from '@mui/icons-material';
import { ContextGlobal as CG } from 'contexts';
import { useConversionTable } from 'hooks';
import { Button } from 'components/page';
import * as Table from 'components/table';

import { InvestmentCard } from './InvestmentCard';
import * as Styled from './StyledInvestment';

export function Investment(props) {
    const { emitAlert } = React.useContext(CG);
    const { t } = useTranslation('ranking');
    const conv = useConversionTable(['investmentType']);
    const [copy, setCopy] = useCopyToClipboard();
    const [showAS, setShowAS] = React.useState(false);

    const {
        deleteStartupInvestment,
        postStartupInvestmentValidation,
        postStartupInvestmentValidationEmail
    } = props.useRankingStartup;
    const data = props.data || props.investment || undefined;
    const investment = {
        investorName: data.investor.name,
        investorEmail: data.investor.email,
        investmentType: conv?.investmentType[data?.type],
        round: data?.round
    };
    if (data.startup && data.startup.contact && data.startup.contact.name) {
        investment.startupContactName = data.startup.contact.name;
    }
    if (data.startup && data.startup.contact && data.startup.contact.email) {
        investment.startupContactEmail = data.startup.contact.email;
    }
    if (data?.date) {
        const momentDate = Moment(data?.date).utcOffset(data?.date);
        const formatDate = momentDate?.format('MM/YYYY');
        investment.date = formatDate;
    }
    if (data?.investor && data?.investor.companyName) {
        investment.investorCompany = data?.investor.companyName;
    }
    if (data?.value) {
        investment.investmentValue = Intl.NumberFormat(undefined, {
            style: 'currency',
            currency: data?.value.currency
        }).format(data?.value.amount);
    }

    // Properties for the table
    const propsTable = {
        href: `/ranking/latam/startup/step6/edit/${data?._id}`,
        subtitle: {
            link: t('glossary:edit'),
            icon: 'far fa-pencil'
        },
        object: {
            data: { ...investment },
            name: 'Investment',
            dataConv: {}
        }
    };
    if (data?.investor.status === 'rejected') {
        propsTable.href = undefined;
        propsTable.subtitle = {
            link: '',
            icon: ''
        };
    }

    const closeModal = () => {
        if (props.closeModal) props.closeModal();
        return true;
    };

    function handleConfirm() {
        const payload = {
            _id: data?._id,
            status: 'confirmed',
            lastUpdated: data?.lastUpdated
        };
        return postStartupInvestmentValidation(payload).then(closeModal);
    }
    function handleDelete() {
        const payload = { _id: data?._id };
        closeModal();
        return deleteStartupInvestment(payload).then(res => {
            emitAlert(t('investmentDeleted'));
            return res;
        });
    }
    function handleReject() {
        const payload = {
            _id: data?._id,
            status: 'rejected',
            lastUpdated: data?.lastUpdated
        };
        return postStartupInvestmentValidation(payload).then(closeModal);
    }
    function handleResend() {
        const payload = { _id: data?._id };
        return postStartupInvestmentValidationEmail(payload).then(res => {
            emitAlert(t('investmentResent'));
            return res;
        });
    }
    function handleReaudit() {
        emitAlert(t('investmentReauditSent'));
    }

    const buttons = [];
    if (data?.startup && data?.status === 'pending' && data?.startup.status === 'pending') {
        const s = { type: 'success', onClick: handleConfirm };
        const d = { type: 'danger', onClick: handleReject };
        buttons.push(() => (
            <Styled.InvestmentButtons>
                <Button {...s}>{t('glossary:confirm')}</Button>
                <Button {...d}>{t('glossary:reject')}</Button>
            </Styled.InvestmentButtons>
        ));
    }
    if (data?.investor && data?.status === 'pending' && data?.investor.status === 'pending') {
        buttons.push(() => (
            <Button type="dark" onClick={handleResend}>
                <span className="text">{t('glossary:resend')}</span>
                <span className="icon far fa-long-arrow-right" aria-hidden="true" />
            </Button>
        ));
    }
    if (data?.audited === 'rejected') {
        buttons.push(() => (
            <Button type="dark" onClick={handleReaudit}>
                <span className="text">{t('glossary:reaudit')}</span>
                <span className="icon far fa-long-arrow-right" aria-hidden="true" />
            </Button>
        ));
    }
    if (data?.investor && data?.investor.status === 'pending') {
        const l = { type: 'link', onClick: () => setShowAS(true) };
        buttons.push(() => <Button {...l}>{t('investmentDelete')}</Button>);
    }

    return (
        <Styled.Investment className={props.className} style={props.style}>
            <InvestmentCard data={data} />

            <Table.Table {...propsTable} />
            <Styled.ContractIdContainer>
                <span>
                    {data?._id}
                    <Styled.CopyButton
                        type="dark"
                        variant="neutral"
                        onClick={() => setCopy(data?._id)}
                    >
                        <FileCopy> </FileCopy>
                        {!copy.value && <span className="text"> </span>}
                        {!!copy.value && <span className="text"> </span>}
                    </Styled.CopyButton>
                </span>
                <p>ID</p>
            </Styled.ContractIdContainer>
            {buttons.map((Btn, k) => (
                // eslint-disable-next-line react/no-array-index-key
                <Btn key={`button-${k}`} />
            ))}
            <IonActionSheet
                isOpen={showAS}
                onDidDismiss={() => setShowAS(false)}
                buttons={[
                    {
                        text: t('investmentDelete'),
                        role: 'destructive',
                        handler: handleDelete
                    },
                    {
                        text: t('glossary:cancel'),
                        role: 'cancel'
                    }
                ]}
            />
        </Styled.Investment>
    );
}
export default Investment;
