import React from 'react';
import { Trans } from 'react-i18next';
import { Doughnut } from 'react-chartjs-2';
import Button from 'components/button';

import { Ranking } from '../components/ranking/Ranking';

import { useCXIDashboard } from '../useCXIDashboard';
import { DashboardArea } from '../components/area/DashboardArea';
import { DashboardNumber } from '../components/number/DashboardNumber';

import * as Styled from '../components/StyledDashboardPage';
import * as StyledIndex from './StyledCXIDashboardIndex';

export const CXIDashboardIndex = () => {
    const { t, vars, downloadDashboardGeral } = useCXIDashboard();
    const { company, users, stats } = vars;
    const totalevaluations = stats.totalEvaluations ?? 0;
    const a = stats.group?.a ?? 0;

    const graphEvaluationsData = {
        labels: ['A', 'B', 'C', 'D'],
        datasets: [
            {
                data: [
                    stats.group?.a ?? 0,
                    stats.group?.b ?? 0,
                    stats.group?.c ?? 0,
                    stats.group?.d ?? 0
                ],
                backgroundColor: ['#0094F9', '#005995', '#4BAAB7', '#8EE2ED'],
                borderColor: ['#0094F9', '#005995', '#4BAAB7', '#8EE2ED']
            }
        ]
    };
    const graphEvaluations = <Doughnut data={graphEvaluationsData} width={200} />;

    const graphStartupsData = {
        labels: ['A', 'B', 'C', 'D'],
        datasets: [
            {
                data: [
                    stats.startupsEvaluatedGroup?.a ?? 0,
                    stats.startupsEvaluatedGroup?.b ?? 0,
                    stats.startupsEvaluatedGroup?.c ?? 0,
                    stats.startupsEvaluatedGroup?.d ?? 0
                ],
                backgroundColor: ['#0094F9', '#005995', '#4BAAB7', '#8EE2ED'],
                borderColor: ['#0094F9', '#005995', '#4BAAB7', '#8EE2ED']
            }
        ]
    };
    const graphStartups = <Doughnut data={graphStartupsData} width={200} />;

    const graphPointsData = {
        labels: [
            t('dashboard.index.PointsChart.stage1'),
            t('dashboard.index.PointsChart.stage2'),
            t('dashboard.index.PointsChart.stage3')
        ],
        datasets: [
            {
                data: [
                    stats.totalPointsStage1 ?? 0,
                    stats.totalPointsStage2 ?? 0,
                    stats.totalPointsStage3 ?? 0
                ],
                backgroundColor: ['#0094F9', '#005995', '#4BAAB7'],
                borderColor: ['#0094F9', '#005995', '#4BAAB7']
            }
        ]
    };
    const graphPoints = <Doughnut data={graphPointsData} width={200} />;

    const graphUsersData = {
        labels: [
            t('dashboard.index.UsersChart.withPoints'),
            t('dashboard.index.UsersChart.withoutPoints')
        ],
        datasets: [
            {
                data: [stats.usersWithPoints ?? 0, stats.users - stats.usersWithPoints ?? 0],
                backgroundColor: ['#0094F9', '#dadada'],
                borderColor: ['#0094F9', '#dadada']
            }
        ]
    };
    const graphUsers = <Doughnut data={graphUsersData} width={200} />;

    const graphUsersStagesData = {
        labels: [
            t('dashboard.index.Users.stage0'),
            t('dashboard.index.Users.stage1'),
            t('dashboard.index.Users.stage2'),
            t('dashboard.index.Users.stage3')
        ],
        datasets: [
            {
                data: [
                    stats?.usersBeltWhite || 0,
                    stats?.usersBeltGreen || 0,
                    stats?.usersBeltBrown || 0,
                    stats?.usersBeltBlack || 0
                ],
                backgroundColor: ['#0094F9', '#005995', '#4BAAB7', '#8EE2ED'],
                borderColor: ['#0094F9', '#005995', '#4BAAB7', '#8EE2ED']
            }
        ]
    };
    const graphUsersStages = <Doughnut data={graphUsersStagesData} width={200} />;

    // const totalStage1 = users.reduce(
    //     (total, user) => total + (user.cxiStats?.currentStage === 1 ? 1 : 0),
    //     0
    // );
    // const totalStage2 = users.reduce(
    //     (total, user) => total + (user.cxiStats?.currentStage === 2 ? 1 : 0),
    //     0
    // );
    // const totalStage3 = users.reduce(
    //     (total, user) => total + (user.cxiStats?.currentStage === 3 ? 1 : 0),
    //     0
    // );
    // const totalStage4 = users.reduce(
    //     (total, user) => total + (user.cxiStats?.currentStage === 4 ? 1 : 0),
    //     0
    // );
    return (
        <Styled.DashboardPage>
            <Styled.DashboardPageHead>
                <h2>
                    {t('dashboard.index.title1')} <span className="blue-text">{company.name}</span>{' '}
                    {t('dashboard.index.title2')}
                </h2>
                <Button style={{ marginBottom: '0.5rem' }} onClick={() => downloadDashboardGeral()}>
                    Baixar em CSV
                </Button>
            </Styled.DashboardPageHead>

            <Styled.DashboardPagePhrase>
                <Trans
                    parent="p"
                    t={t}
                    i18nKey="dashboard.index.shareable"
                    company={company.name}
                    totalevaluations={totalevaluations}
                    a={a}
                >
                    {{ company: company.name }}
                    <strong>
                        {{ totalevaluations }} {t('dashboard.index.Evaluations.evaluations')}
                    </strong>
                    <strong>
                        {t('dashboard.classified')} {{ a }} startup
                    </strong>
                </Trans>
            </Styled.DashboardPagePhrase>

            <StyledIndex.DashboardPageAreas>
                <DashboardArea
                    graph={graphEvaluations}
                    title="Avaliações"
                    graphData={graphEvaluationsData}
                >
                    <div>
                        <DashboardNumber number={totalevaluations}>
                            {t('dashboard.index.Evaluations.evaluations')}
                        </DashboardNumber>
                        <DashboardNumber number={stats.startupsEvaluated}>
                            {t('dashboard.index.Evaluations.variedEvaluations')}
                        </DashboardNumber>
                    </div>

                    <div>
                        <DashboardNumber number={stats.group?.a ?? 0}>
                            {t('dashboard.index.Evaluations.a')}
                        </DashboardNumber>
                    </div>
                </DashboardArea>

                <DashboardArea graph={graphStartups} title="Startups" graphData={graphStartupsData}>
                    <DashboardNumber number={stats.startupsEvaluated ?? 0} active>
                        {t('dashboard.index.Startups.startups')}
                    </DashboardNumber>
                    <DashboardNumber number={stats.startupsEvaluatedGroup?.a ?? 0}>
                        {t('dashboard.index.Startups.a')}
                    </DashboardNumber>
                </DashboardArea>

                <DashboardArea graph={graphPoints} title="Pontuação" graphData={graphPointsData}>
                    <DashboardNumber number={stats.totalPoints ?? 0} active>
                        {t('dashboard.index.Points.total')}
                    </DashboardNumber>
                    <DashboardNumber style={{ marginLeft: '50%' }}>
                        <strong>{stats.totalPointsStage1 ?? 0}</strong>{' '}
                        {t('dashboard.index.Points.stage1')}
                    </DashboardNumber>
                    <DashboardNumber>
                        <strong>{stats.totalPointsStage2 ?? 0}</strong>{' '}
                        {t('dashboard.index.Points.stage2')}
                    </DashboardNumber>
                    <DashboardNumber>
                        <strong>{stats.totalPointsStage3 ?? 0}</strong>{' '}
                        {t('dashboard.index.Points.stage3')}
                    </DashboardNumber>
                </DashboardArea>

                <DashboardArea graph={graphUsers} title="Pessoas" graphData={graphUsersData}>
                    <DashboardNumber number={stats.users ?? 0} active>
                        {t('dashboard.index.Users.total')}
                    </DashboardNumber>
                    <DashboardNumber number={stats.usersWithPoints ?? 0}>
                        {t('dashboard.index.Users.withPoints')}
                    </DashboardNumber>
                </DashboardArea>

                <DashboardArea
                    graph={graphUsersStages}
                    title="Pessoas por classificação"
                    graphData={graphUsersStagesData}
                >
                    <DashboardNumber number={stats?.usersBeltBlack} active>
                        {t('dashboard.index.Users.stage3')}
                    </DashboardNumber>
                    <DashboardNumber number={stats?.usersBeltBrown}>
                        {t('dashboard.index.Users.stage2')}
                    </DashboardNumber>
                    <div
                        style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
                    >
                        <DashboardNumber number={stats?.usersBeltGreen}>
                            {t('dashboard.index.Users.stage1')}
                        </DashboardNumber>
                        <DashboardNumber number={stats?.usersBeltWhite}>
                            {t('dashboard.index.Users.stage0')}
                        </DashboardNumber>
                    </div>
                </DashboardArea>
            </StyledIndex.DashboardPageAreas>

            {/* <Ranking /> */}
        </Styled.DashboardPage>
    );
};
