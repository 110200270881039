import contractStar from 'assets/images/icons/contractStar.png';
import UserAvatar from 'core/components/user/avatar/UserAvatar';
import { useParseDate } from 'hooks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as Styled from './styles';
import { RankingStartupContext } from '../../RankingStartupContext';

export function ContractCard({ data, relationship }) {
    const { vars } = React.useContext(RankingStartupContext);
    const { t } = useTranslation('ranking');

    const { ranking } = vars;

    const item = data || relationship;

    if (!item) {
        return null;
    }

    const [noStartDate, setNoStartDate] = useState(false);
    const [noEndDate, setNoEndDate] = useState(false);
    const [contractPeriod, setContractPeriod] = useState();

    const parsedDate = useParseDate({ startDate: item.startDate, endDate: item.endDate });
    // const { postStartupContractValidationEmail } = useRankingStartup({ startupId: });

    useEffect(() => {
        if (!item.startDate) {
            setNoStartDate(true);
        }
        if (!item.endDate) {
            setNoEndDate(true);
        }
        if (!item.startDate && !item.endDate) {
            setContractPeriod(null);
        } else {
            setContractPeriod(parsedDate);
        }
    }, []);

    //   const contractPeriod = useParseDate({ startDate: data.startDate, endDate: data.endDate });

    const eligibilityContract = item.eligibility?.[ranking?.year];

    const bold = item?.companyContact?.name;

    const text = contractPeriod
        ? `${
              !noStartDate
                  ? `${contractPeriod?.formattedDate?.formattedStartDate.mm} ${contractPeriod?.formattedDate?.formattedStartDate.yyyy}`
                  : 'Data de início não declarada'
          }
            -
            ${
                !noEndDate
                    ? `${contractPeriod?.formattedDate?.formattedEndDate.mm} ${contractPeriod?.formattedDate?.formattedEndDate.yyyy}`
                    : 'data de fim não declarada'
            }`
        : 'Vigência não declarada';

    const getStatusBadge = ({ type, message }) => {
        return {
            id: `${type}-${message}`,
            item: <div className={`badge badge-${type} mr-2`}>{t(message)}</div>
        };
    };

    const badges = [];

    const isConfirmed = item.status === 'confirmed';
    const isAudited = item.audited && item.displayAudit;

    if (isConfirmed) {
        if (isAudited) {
            if (item.audited === 'confirmed') {
                badges.push(getStatusBadge({ type: 'success', message: 'auditConfirmed' }));
            } else if (item.audited === 'rejected') {
                badges.push(getStatusBadge({ type: 'danger', message: 'auditRejected' }));
            }
        } else if (!item.displayAudit) {
            badges.push(getStatusBadge({ type: 'warning', message: 'auditPending' }));
        }

        if (item.reconsiderationResult) {
            badges.push(getStatusBadge({ type: 'secondary', message: 'reconsiderationDone' }));
        } else if (item?.reconsiderations && item.reconsiderations.length > 0) {
            badges.push(getStatusBadge({ type: 'warning', message: 'reconsiderationRequested' }));
        }
    }

    switch (item.companyStatus) {
        case 'rejected':
            badges.push(getStatusBadge({ type: 'danger', message: 'corporateRejected' }));

            break;
        case 'pending':
            if (item.status === 'pending') {
                badges.push(getStatusBadge({ type: 'warning', message: 'corporatePending' }));
            }
            break;
        default:
            break;
    }

    switch (item.startupStatus) {
        case 'rejected':
            badges.push(getStatusBadge({ type: 'danger', message: 'startupRejected' }));
            break;
        case 'pending':
            badges.push(getStatusBadge({ type: 'warning', message: 'startupPending' }));
            break;
        default:
            break;
    }

    if (!eligibilityContract?.eligible) {
        if (eligibilityContract?.outOfRange) {
            badges.push(getStatusBadge({ type: 'danger', message: 'contractOutOfRange' }));
        }

        if (eligibilityContract?.expired) {
            badges.push(getStatusBadge({ type: 'danger', message: 'contractExpired' }));
        }
    }

    if (
        isConfirmed &&
        item.displayAudit &&
        item.reconsiderations &&
        item.reconsiderations.length > 0 &&
        !item.reconsiderationResult &&
        item.hasAuditTeamMessage
    ) {
        badges.push(getStatusBadge({ type: 'secondary', message: 'messageFromAuditTeam' }));
    }

    return (
        <Styled.ContractCard onClick={item.onClick}>
            <UserAvatar user={{ firstName: item.company?.name, photoUrl: item.company?.logoUrl }} />

            <Styled.CardInfos>
                <span className="name">{item.company?.name}</span>
                <span className="bold">{bold}</span>
                <span className="text">{text}</span>

                {!!item.onClick && item?.companyStatus === 'pending' && (
                    <>
                        <Styled.ResendInfo>{t('glossary:resendInfo')}</Styled.ResendInfo>
                        <br />
                    </>
                )}

                {badges.map(badge => (
                    <React.Fragment key={badge.id}>{badge.item}</React.Fragment>
                ))}

                <br />

                {item.audited === 'confirmed' &&
                    item.displayAudit === true &&
                    item?.eligibility?.[ranking?.year]?.eligible && (
                        <Styled.MaxContractPointsBadge>
                            <img src={contractStar} alt="star icon" />
                            <p>
                                {t('StartupStep8.contractsMaxPoints', { points: item?.maxPoints })}
                            </p>
                        </Styled.MaxContractPointsBadge>
                    )}
            </Styled.CardInfos>
        </Styled.ContractCard>
    );
}
