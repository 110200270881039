import { CA } from 'contexts';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { queryParam } from 'utils/query';

export const RankingStartupContext = React.createContext();

export const RankingStartupContextProvider = ({ children }) => {
    const { t } = useTranslation('ranking');

    // Initial values for the state
    const initial = {
        ranking: {
            id: '2024',
            name: '2024',
            year: '2024',
            country: 'BR'
        },
        application: { applied: false, steps: {} },
        investor: {},
        investments: [],
        validate: false,
        relationships: [],
        startups: [],
        startupDetails: {},
        contracts: [],
        searchedCompany: {},
        contractPoints: 0,
        startupId: queryParam('startupId') || 0,
        startupTerm: {},
        term: {},
        redirectTo: '',
        companyDetails: {}
    };

    // Define variables holder to be used on the module
    const [vars, setVars] = React.useState(initial);
    const addVars = newVars => setVars(old => ({ ...old, ...newVars }));

    const stateValue = {
        t,
        vars,
        addVars,
        setVars
    };
    return (
        <RankingStartupContext.Provider value={stateValue}>
            {children}
        </RankingStartupContext.Provider>
    );
};
