import React from 'react';

import { ContextGlobal as CG } from 'contexts';
import { useForm } from 'components/form';
import { useConversionOptions } from 'hooks';
import { RHP } from 'providers';

import * as Page from 'components/page';

import { ProfileEditForm } from './ProfileEditForm';

export function ProfileEdit(props) {
    const conv = useConversionOptions(['ecosystemRole', 'gender']);
    const { emitToast } = React.useContext(CG);
    const { vars, putUser } = props.useProfile;

    // Initialize a new form handler
    const form = useForm({
        ...vars
    });

    // Handle form submition
    const onSubmit = values => {
        const payload = values;
        // Do the submition api request
        return putUser(payload)
            .then(() => {
                emitToast(props.t('ProfileEdit.saveSuccess'));
                if (vars.returnTo) {
                    RHP.push(vars.returnTo);
                    return;
                }
                RHP.goBack();
            })
            .catch(() => form.submited());
    };

    const propsForm = { ...props, conv, form, onSubmit };
    return (
        <Page.Page name="ProfileEdit" back={vars.returnTo || '/home'}>
            <Page.Title>{props.t('ProfileEdit.title')}</Page.Title>
            <Page.Descr>{props.t('ProfileEdit.descr')}</Page.Descr>

            <ProfileEditForm {...propsForm} />
        </Page.Page>
    );
}
export default ProfileEdit;
