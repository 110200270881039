import React from 'react';

import { UserAvatar } from 'core/components/user';
import { useUser } from 'hooks';
import { RHP } from 'providers';

import { useAuthContext } from 'hooks/useAuthContext';
import { useCXI } from 'modules/cxi/useCXI';
import * as Styled from './StyledMenu';
import { MenuItem } from './MenuItem';

export function Menu() {
    const { hasScope } = useAuthContext();

    const { t, vars, setModal } = useCXI();
    const { company, cxi, unreadMessages } = vars;
    const user = useUser();

    const { hasChallengesVisibleForAll, hasChallenges } = company || {};

    const editProfile = () => {
        RHP.push('/100-10-1/profile');
    };

    const shouldShowChallengesButtons =
        cxi.isCxiAdmin || hasChallengesVisibleForAll || hasChallenges;

    return (
        <Styled.Menu>
            <Styled.MenuAvatar onClick={editProfile}>
                <UserAvatar />
                {/* <Styled.MenuCompany img={user.company.logo} /> */}
                {!!(
                    user.pocs.includes('operational') ||
                    user.pocs.includes('hub') ||
                    cxi.isCxiAdmin
                ) && <Styled.PocBadge>POC</Styled.PocBadge>}
                <span className="icon fas fa-cog" aria-hidden="true" />
            </Styled.MenuAvatar>

            <Styled.Name>{user.fullname}</Styled.Name>
            <Styled.Desc>{user.company?.name || '-'}</Styled.Desc>

            <MenuItem className="is-highlight stage-1" to="/100-10-1/matches">
                <span className="icon far fa-fw fa-comment-alt-smile" aria-hidden="true" />
                <span className="text">{t('menu.matches')}</span>
            </MenuItem>

            <MenuItem className="is-highlight stage-2" to="/100-10-1/networks/evaluated">
                <span className="icon far fa-fw fa-hands-helping" aria-hidden="true" />
                <span className="text">{t('menu.speed-dating')}</span>
            </MenuItem>

            <MenuItem className="is-highlight stage-3" to="/100-10-1/networks/mentoring">
                <span className="icon far fa-fw fa-user-chart" aria-hidden="true" />
                <span className="text">{t('menu.mentoring')}</span>
            </MenuItem>

            <Styled.MenuItems>
                <MenuItem to="/100-10-1/home">
                    <span className="icon far fa-fw fa-user" aria-hidden="true" />
                    <span className="text">{t('menu.home')}</span>
                </MenuItem>
                <MenuItem to="/100-10-1/networks">
                    <span className="icon far fa-fw fa-chart-network" aria-hidden="true" />
                    <span className="text">{t('menu.networks')}</span>
                </MenuItem>
                <MenuItem to="/100-10-1/messages" badge={unreadMessages}>
                    <span className="icon far fa-fw fa-comments-alt" aria-hidden="true" />
                    <span className="text">{t('menu.messages')}</span>
                </MenuItem>
                <MenuItem to="/100-10-1/contents">
                    <span className="icon far fa-fw fa-clipboard" aria-hidden="true" />
                    <span className="text">{t('menu.contents')}</span>
                </MenuItem>

                <MenuItem to="/100-10-1/opportunities">
                    <span className="icon far fa-fw fa-clipboard-list-check" aria-hidden="true" />
                    <span className="text">{t('menu.opportunitiesBoard')}</span>
                </MenuItem>
                <MenuItem to="/100-10-1/sharemyprofile">
                    <span className="icon far fa-fw fa-share" aria-hidden="true" />
                    <span className="text">{t('menu.shareMyProfile')}</span>
                </MenuItem>

                <hr />

                {shouldShowChallengesButtons && (
                    <>
                        <MenuItem to="/100-10-1/form">
                            <span className="icon far fa-fw fa-rocket" aria-hidden="true" />
                            <span className="text">{t('menu.challenges')}</span>
                        </MenuItem>

                        <MenuItem to="/100-10-1/challenges">
                            <span className="icon far fa-fw fa-pager" aria-hidden="true" />
                            <span className="text">{t('menu.manageChallenges')}</span>
                        </MenuItem>
                        <hr />
                    </>
                )}

                {hasScope('cxiAdmin') && user.company?.id && (
                    <MenuItem to={`/100-10-1/dashboard/${user.company?.id}`}>
                        <span
                            className="icon far fa-fw fa-tachometer-alt-average"
                            aria-hidden="true"
                        />
                        <span className="text">{t('menu.dashboard')}</span>
                        <hr />
                    </MenuItem>
                )}

                <MenuItem onClick={() => setModal({ modal: 'Guide' })}>
                    <span className="icon fab fa-fw fa-youtube" aria-hidden="true" />
                    <span className="text">{t('menu.guide')}</span>
                </MenuItem>
                <MenuItem onClick={() => setModal({ modal: 'Support' })}>
                    <span className="icon far fa-fw fa-question-circle" aria-hidden="true" />
                    <span className="text">{t('menu.support')}</span>
                </MenuItem>
            </Styled.MenuItems>
        </Styled.Menu>
    );
}
