import React from 'react';

import { FormInputRadioItem } from './FormInputRadioItem';
import * as Styled from './StyledFormInputRadio';

const fakeEvent = {
    persist: () => null,
    preventDefault: () => null,
    target: {}
};

export function FormInputRadio(props) {
    const { input } = props;

    // Handle the change event on inputs
    const onChange = ev => {
        const { value } = ev.target;
        const event = { ...fakeEvent, target: { name: input.name, value } };
        input.onChange(event);
    };

    return (
        <Styled.FormInputRadio as="fieldset" name="radio">
            {props.children}
            {props.options.map(r => {
                const id = `${input.name}-${r.value}`;
                const field = { ...input, id, checked: String(input.value) === r.value, onChange };
                const radio = { input: field, ...r };
                return <FormInputRadioItem key={id} {...radio} />;
            })}
        </Styled.FormInputRadio>
    );
}
export const radio = FormInputRadio;
export default FormInputRadio;
