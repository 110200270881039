import styled from 'styled-components';
import { positions } from 'utils/styles';

export const FormCustomEditAvatar = styled.label.attrs({
    className: 'form-custom-edit-avatar rippled',
})`
    display: inline-block;
    position: relative;

    input {
        visibility: hidden;
        position: absolute;
    }
`;

export const IconCamera = styled.span.attrs({
    className: 'icon-camera far fa-camera-alt',
})`
    background-color: #fff;
    border-radius: 50%;
    color: #aaa;
    font-size: 1.6rem;
    padding: 0.8rem;
    position: absolute;
    ${positions('auto', 0, 0, 'auto')}
`;

export const UserAvatar = styled.span.attrs({
    className: 'user-avatar',
})``;
