import React from 'react';

import { useConversionTable } from 'hooks';

import { Area } from '../components/area/Area';

import { CXIChallengeStartupFinancialFinances } from './Finances';

import * as Styled from './StyledCXIChallengeStartupFinancial';

export const CXIChallengeStartupFinancial = props => {
    const { startup, t } = props;
    const conv = useConversionTable(['whoInvested']);

    return (
        <Styled.CXIChallengeStartupFinancial style={{ gridColumn: 2 }}>
            {!!startup?.demographics?.financial && (
                <Area className="finances" title={t('startup.finances')}>
                    <CXIChallengeStartupFinancialFinances startup={startup} t={t} />
                </Area>
            )}

            {!!startup?.demographics.financial?.whoInvested?.length && (
                <Area className="invested" title={t('startup.invested')}>
                    {startup?.demographics?.financial?.whoInvested.map(item => (
                        <p key={item}>{conv.whoInvested[item]}</p>
                    ))}
                </Area>
            )}
        </Styled.CXIChallengeStartupFinancial>
    );
};
