import styled from 'styled-components';

export const OiweekCall2Action = styled.div.attrs({
    className: 'oiweek-call2action'
})`
    .component-alert {
        background: transparent;
        color: #fff;
        margin: 1rem 0 0;
        padding: 0;
    }
`;

export const C2AClosed = styled.div.attrs({
    className: 'oiweek-call2action-closed'
})`
    .c-alert-buttn {
        text-align: center;
    }
`;
export const C2AClosedHighlight = styled.div.attrs({
    className: 'oiweek-call2action-closed-highlight'
})`
    background: rgba(0, 0, 0, 0.4) !important;
    color: #fff;
    margin-top: 1rem !important;
    padding: 1.5rem !important;
`;

export const C2AInvite = styled.div.attrs({
    className: 'oiweek-call2action-invite'
})`
    strong {
        display: block;
        margin-top: 1rem;
    }

    .date {
        color: var(--primary);
        display: block;
        font-size: 1.8rem;
        font-weight: 700;
        margin-top: 1rem;
        text-transform: lowercase;
    }

    .c-alert-title {
        font-size: 2.4rem;
        padding: 0 1rem;
    }
`;
export const C2AInviteCounter = styled.ul.attrs({
    className: 'oiweek-call2action-invite-counter'
})`
    display: flex;
    list-style: none;
    justify-content: center;
    margin: 0;
    padding: 0;

    li {
        flex: 0 0 7rem;
    }
    li .num {
        color: #f93e85;
        font-size: 4rem;
        font-weight: 700;
    }
    li .txt {
        color: #ffc2d9;
        display: block;
        font-size: 1.2rem;
        margin-top: -1rem;
    }
`;

export const C2AOpened = styled.div.attrs({
    className: 'oiweek-call2action-opened'
})`
    .date {
        color: ${props => (props.color ? props.color : 'var(--primary)')};
        display: block;
        font-size: 1.8rem;
        font-weight: 700;
        margin-top: 1rem;
        text-transform: lowercase;
    }
`;

export const C2AOngoing = styled.div.attrs({
    className: 'oiweek-call2action-opened'
})`
    .date {
        color: ${props => (props.color ? props.color : 'var(--primary)')};
        display: block;
        font-size: 1.8rem;
        font-weight: 700;
        margin-top: 1rem;
        text-transform: lowercase;
    }
`;
