import React from 'react';

import { RankingStartupContextProvider } from './RankingStartupContext';
import { RankingStartupRoutes } from './RankingStartupRoutes';
import { useRankingStartup } from './useRankingStartup';

export function RankingStartupModule() {
    const hookd = useRankingStartup();
    return !hookd.ready ? null : <RankingStartupRoutes t={hookd.t} useRankingStartup={hookd} />;
}

export function RankingStartup() {
    return (
        <RankingStartupContextProvider>
            <RankingStartupModule />
        </RankingStartupContextProvider>
    );
}
export default RankingStartup;
