import React from 'react';

import { iframeLink } from 'core/iframe';
import { CorporateIndexView } from './page';
import * as Styled from './styles';

export function CorporateIndex(props) {
    const { vars } = props.useRankingCorporate;
    const { application } = vars;

    // Create the steps for this index
    const steps = [
        {
            step: 1,
            href: '/ranking/latam/corporate/step1',
            text: props.t('CorporateIndex.step1'),
            isBlocked: false,
            isCompleted: application?.steps?.step1 || false,
            isVisible: true
        },
        {
            step: 2,
            href: '/ranking/latam/corporate/step2',
            text: props.t('CorporateIndex.step2'),
            isBlocked: !application?.steps?.step1,
            isCompleted: application?.steps?.step2 || false,
            isVisible: true
        },
        {
            step: 3,
            href: '/ranking/latam/corporate/step3',
            text: props.t('CorporateIndex.step3'),
            isBlocked: !application?.steps?.step2,
            isCompleted: application?.steps?.step3 || false,
            isVisible: true
        },
        {
            step: 4,
            href: '/ranking/latam/corporate/step4',
            text: props.t('CorporateIndex.step4'),
            isBlocked: !application?.steps?.step3,
            isCompleted: application?.steps?.step4 || false,
            isVisible: true
        },
        {
            step: 5,
            href: '/ranking/latam/corporate/step5',
            text: props.t('CorporateIndex.step5'),
            isBlocked: !application?.steps?.step4,
            isCompleted: application?.steps?.step5 || false,
            isVisible: true
        }
    ];

    // Create a list for the support links
    const actions = [
        {
            id: 1,
            href: iframeLink('https://100os.net/ranking-sobre'),
            text: props.t('CorporateIndex.RankingAbout')
        },
        {
            id: 2,
            href: '/support',
            text: props.t('CorporateIndex.HelpAndSupport')
        }
    ];

    const propsView = { ...props, actions, steps };

    return (
        <Styled.CorporateIndex>
            <CorporateIndexView {...propsView} />
        </Styled.CorporateIndex>
    );
}
